.error__subtitle {
  font-weight: 600;
  font-size: rem(13px);
  line-height: 1.4;
  letter-spacing: -0.01em;
  color: $gray-4;
  &:last-of-type {
    margin-bottom: rem(36px);
  }
}
