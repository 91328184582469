.breadcrumb__item {
  display: flex;
  align-items: center;
  &:first-child .breadcrumb__link {
    padding-left: 0;
  }
  & + &::before {
    content: "";
    width: rem(14px);
    height: rem(14px);
    background-color: $gray-3;
    mask-size: auto;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-image: url("/assets/image/svg/chevron/chevron-right-14x14.svg");
  }
  &#{&}--is-active {
    .breadcrumb__link {
      color: $black-0;
    }
  }
  &:hover {
    .breadcrumb__input:not(:focus) {
      border-bottom-color: $gray-2;
    }
  }
}
