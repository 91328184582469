.main-menu__label {
  display: flex;
  flex-direction: column;
  color: $gray-5;
  text-align: center;
  font-size: rem(10px);
  font-weight: 600;
  line-height: 1.5;
  &#{&}--is-active {
    color: $purple-2;
  }
}
