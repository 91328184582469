.history-list-item {
  display: flex;
  padding-left: rem(33px);
  height: 100%;
  padding-bottom: rem(16px);
  margin-left: rem(8px);
  position: relative;
  &#{&}--have-line {
    border-left: 1px solid $gray-2;
  }
}

@import "__action-title/history-list-item__action-title";
@import "__action-date/history-list-item__action-date";
@import "__circle/history-list-item__circle";
