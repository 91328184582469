.main-menu__nav {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  margin: 0 rem(-10px);
  overflow-y: overlay;
  @include scrollbar;
  &:not(:last-child) {
    margin-bottom: rem(15px);
  }
}
