.templates-categories {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px 4px 0px 0px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  position: relative;
  overflow: hidden;
  &#{&}--chatroom {
    border-right: 1px solid $gray-2;
    border-radius: 8px 0 0 8px;
  }
}

@import "__input/templates-categories__input";
@import "__list/templates-categories__list";
