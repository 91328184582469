.client-panel__body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  &#{&}--is-empty {
    border-top: 1px solid $gray-2;
    justify-content: center;
    align-items: center;
  }
}
