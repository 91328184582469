.error__message {
  font-weight: 400;
  font-size: rem(16px);
  line-height: 1.4;
  color: $black-0;
  user-select: none;
  text-align: center;
  &:not(:last-child) {
    margin-bottom: rem(8px);
  }
  &#{&}--is-polling {
    position: relative;
    &:after {
      position: absolute;
      display: inline-block;
      animation: poll steps(1, end) 2s infinite;
      content: "";
    }
  }
}
