.channels-types-dropdown__item {
  @at-root {
    a#{&},
    button#{&},
    label#{&} {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      font-size: rem(14px);
      line-height: 1.5;
      letter-spacing: -0.02em;
      color: $gray-5;
      border-radius: 6px;
      padding: rem(6.5px) rem(10px);
      background-color: $white-0;
      user-select: none;
      cursor: pointer;
      transition: color 100ms, background-color 100ms;
      &:hover {
        color: $black-0;
        background-color: $gray-1;
      }
    }
  }
}
