.channel-connect-modal__subtitle {
  font-weight: 400;
  font-size: rem(12px);
  line-height: 1.3;
  color: $gray-4;
  & > b {
    font-size: rem(12px);
    line-height: 1.3;
    color: $black-0;
  }
}
