.ai-setup-step {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: rem(35px);
  @include scrollbar;
}

@import "__description-container/ai-setup-step__description-container";
@import "__title/ai-setup-step__title";
@import "__description/ai-setup-step__description";
