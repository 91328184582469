.templates__list-container {
  border-radius: rem(20px) rem(20px) 0 0;
  padding: rem(40px) rem(40px) 0 rem(40px);
  background-color: $gray-0;
  &#{&}--chatroom {
    padding: rem(30px);
    background-color: $white-0;
    border-radius: 0 8px 8px 0;
  }
}
