.dashboard__body {
  height: 100%;
  padding: 0 rem(16px) 0 rem(16px);
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  &#{&}--is-error {
    justify-content: center;
    & > .error {
      min-height: auto;
    }
  }
  @include scrollbar();
}
