.bot-save-status {
  height: rem(36px);
  padding: rem(7.5px) rem(14px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: rem(14px);
  line-height: 1.5;
  color: $gray-5;
  opacity: 1;
  transition: opacity 0.1s;
  &#{&}--is-saving,
  &#{&}--is-error {
    border: 1px dashed $black-0;
    border-radius: 40px;
  }
  &#{&}--is-hidden {
    opacity: 0;
  }
}
