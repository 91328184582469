@charset "UTF-8";

.button {
  $this: &;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: auto;
  appearance: none;
  user-select: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: em(6px, 14px);
  box-shadow: none;
  font-weight: 500;
  font-size: rem(14px);
  line-height: 1.5;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 100ms;
  span:not([class]) {
    @extend %collapsable-text;
  }
  .icon,
  .image,
  .avatar {
    width: em(21px, 14px);
    height: em(21px, 14px);
  }
  &#{&}--is-disabled,
  &:disabled {
    cursor: not-allowed;
  }
  &#{&}--is-unclickable {
    cursor: default;
  }
  &#{&}--is-fullwidth {
    display: flex;
    width: 100%;
    span:not([class]) {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
  &#{&}--h-36 {
    height: em(36px, 14px);
    padding-top: em(((36px - 21px) * 0.5) - 1px, 14px);
    padding-right: em(14px - 1px, 14px);
    padding-bottom: em(((36px - 21px) * 0.5) - 1px, 14px);
    padding-left: em(14px - 1px, 14px);
    .icon {
      &:first-child:last-child {
        margin-left: em(calc((21px - 14px) / 2) * -1, 14px);
        margin-right: em(calc((21px - 14px) / 2) * -1, 14px);
      }
      &:first-child:not(:last-child) {
        margin-left: em(calc((21px - 14px) / 2) * -1, 14px);
        margin-right: em(12px - calc((21px - 14px) / 2), 14px);
      }
      &:last-child:not(:first-child) {
        margin-left: em(12px - calc((21px - 14px) / 2), 14px);
        margin-right: em(calc((21px - 14px) / 2) * -1, 14px);
      }
    }
    .image,
    .avatar {
      &:first-child:last-child {
        margin-left: 0;
        margin-right: 0;
      }
      &:first-child:not(:last-child) {
        margin-right: em(12px, 14px);
      }
      &:last-child:not(:first-child) {
        margin-left: em(12px, 14px);
      }
    }
    &#{$this}--is-square {
      width: em(36px, 14px);
    }
  }
  &#{&}--h-48 {
    height: em(48px, 14px);
    padding-top: em(calc((48px - 21px) / 2) - 1px, 14px);
    padding-right: em(16px - 1px, 14px);
    padding-bottom: em(calc((48px - 21px) / 2) - 1px, 14px);
    padding-left: em(16px - 1px, 14px);
    .icon {
      &:first-child:last-child {
        margin-left: em(calc((21px - 14px) / 2) * -1, 14px);
        margin-right: em(calc((21px - 14px) / 2) * -1, 14px);
      }
      &:first-child:not(:last-child) {
        margin-left: em(calc((21px - 14px) / 2) * -1, 14px);
        margin-right: em(14px - calc((21px - 14px) / 2), 14px);
      }
      &:last-child:not(:first-child) {
        margin-left: em(14px - calc((21px - 14px) / 2), 14px);
        margin-right: em(calc((21px - 14px) / 2) * -1, 14px);
      }
    }
    .image,
    .avatar {
      &:first-child:last-child {
        margin-left: 0;
        margin-right: 0;
      }
      &:first-child:not(:last-child) {
        margin-right: em(14px, 14px);
      }
      &:last-child:not(:first-child) {
        margin-left: em(14px, 14px);
      }
    }
    &#{$this}--is-square {
      width: em(48px, 14px);
    }
  }
  &#{&}--fill-gray-0 {
    background-color: $gray-0;
    color: $gray-5;
    &:not(:disabled):not(#{$this}--is-disabled):not(
        #{$this}--no-interactivity
      ) {
      &:hover {
        border-color: $black-0;
      }
      &:active {
        background-color: $purple-2;
        color: $white-0;
      }
    }
  }
  &#{&}--fill-red-0 {
    background-color: $red-0;
    color: $white-0;
    &:not(:disabled):not(#{$this}--is-disabled):not(
        #{$this}--no-interactivity
      ) {
      &:hover {
        background-color: $red-2;
      }
      &:active {
        background-color: rgba($red-0, 0.75);
        color: $gray-1;
      }
    }
  }
  &#{&}--fill-gray-1 {
    background-color: $gray-1;
    color: $gray-5;
    &:not(:disabled):not(#{$this}--is-disabled):not(
        #{$this}--no-interactivity
      ) {
      &:hover {
        border-color: $black-0;
      }
      &:active {
        background-color: $purple-2;
        color: $white-0;
      }
    }
  }
  &#{&}--fill-black-0 {
    color: $white-0;
    background-color: $black-0;
    &:disabled {
      background-color: rgba($black-0, 0.4);
    }
    &:not(:disabled):not(#{$this}--is-disabled):not(
        #{$this}--no-interactivity
      ) {
      &:hover {
        background-color: $gray-5;
      }
      &:focus:not(:active) {
        box-shadow: 0 0 0 em(2.5px, 14px) $purple-3;
      }
      &:active {
        background-color: $purple-3;
      }
    }
  }
  &#{&}--fill-purple-3 {
    color: $white-0;
    background-color: $purple-3;
    &:disabled {
      background-color: $purple-1;
    }
    &:not(:disabled):not(#{$this}--is-disabled):not(
        #{$this}--no-interactivity
      ) {
      &:hover {
        background-color: $purple-2;
      }
      &:focus:not(:active) {
        box-shadow: 0 0 0 em(2.5px, 14px) $purple-1;
      }
      &:active {
        background-color: $purple-3;
      }
    }
  }
  &#{&}--stroke-gray-2 {
    color: $black-0;
    background-color: transparent;
    border-color: $gray-2;
    &:disabled {
      color: $gray-3;
      opacity: 0.9;
    }
    &:not(:disabled):not(#{$this}--is-disabled):not(
        #{$this}--no-interactivity
      ) {
      &#{$this}--is-active {
        background-color: $white-0;
        border-color: $gray-5;
      }
      &:hover {
        border-color: $gray-5;
      }
      &:focus:not(:active) {
        box-shadow: 0 0 0 em(2.5px, 14px) $purple-3;
        border-color: transparent;
      }
      &:active {
        border-color: $gray-4;
      }
    }
  }
  &#{&}--has-bg-white-0 {
    background-color: $white-0;
  }
  &#{&}--stroke-gray-5 {
    color: $gray-5;
    background-color: transparent;
    border-color: $gray-5;
    &:disabled {
      color: $gray-3;
      border-color: $gray-3;
      .button__divider {
        background-color: $gray-3;
      }
    }
    &:not(:disabled):not(#{$this}--is-disabled):not(
        #{$this}--no-interactivity
      ) {
      .button__divider {
        background-color: $gray-5;
      }
      &:hover {
        color: $white-0;
        background-color: $black-0;
      }
      &:focus:not(:active) {
        box-shadow: 0 0 0 em(2.5px, 14px) $purple-3;
        border-color: transparent;
      }
      &:active {
        color: $white-0;
        background-color: $gray-5;
        .button__divider {
          background-color: $gray-4;
        }
      }
    }
  }
  &#{&}--stroke-purple-3 {
    color: $purple-3;
    background-color: transparent;
    border-color: $purple-3;
    &:disabled {
      color: $purple-1;
      opacity: 0.9;
    }
    &:not(:disabled):not(#{$this}--is-disabled):not(
        #{$this}--no-interactivity
      ) {
      &:hover {
        border-color: $purple-3;
      }
      &:focus:not(:active) {
        box-shadow: 0 0 0 em(2.5px, 14px) $purple-3;
        border-color: transparent;
      }
      &:active {
        color: $white-0;
        background-color: $purple-3;
        border-color: transparent;
      }
    }
  }
  &#{&}--is-loading {
    pointer-events: none;
    & > * {
      visibility: hidden;
    }
    &::after {
      content: "";
      position: absolute;
      left: calc(50% - (1rem * 0.5));
      top: calc(50% - (1rem * 0.5));
      animation: spinAround 500ms infinite linear;
      border: 2px solid currentColor;
      border-radius: 9999px;
      border-right-color: transparent;
      border-top-color: transparent;
      display: block;
      height: 1rem;
      width: 1rem;
    }
  }
}

@import "__divider/button__divider";
