.comparison-bars {
  background-color: $gray-0;
  border-radius: rem(16px);
  padding: rem(24px);
  flex-grow: 1;
  &#{&}--no-padding {
    padding: 0;
  }
}

@import "__title/comparison-bars__title";
@import "__list/comparisons-bars__list";
@import "__no-data/comparison-bars__no-data";
