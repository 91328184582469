.node-tabs__item {
  display: block;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  border-radius: 9px;
  overflow: hidden;
  &#{&}--is-active {
    .node-tabs__link {
      color: $black-0;
      background-color: $white-0;
    }
  }
}
