.message-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding-top: rem(8px);
  padding-bottom: rem(8px);
  &:last-child {
    padding-bottom: rem(8px);
  }
}
