.analytics-dropdown-wrapper {
  & > .react-datepicker__input-container {
    & > input {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 150%;
      letter-spacing: -0.02em;
      color: #333333;
      padding: 0.40625rem 0.625rem;
      border: none;
      cursor: pointer;
      background-color: #ffffff;
      border-radius: 6px;
      transition: color 100ms, background-color 100ms;
      &::placeholder {
        color: #333333;
      }
      &:focus-visible,
      &:focus {
        border: none;
        outline: none;
      }
      &:hover {
        color: #222222;
        background-color: #f3f3f5;
      }
    }
  }
}
