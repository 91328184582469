@charset "UTF-8";

.client-card-settings {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  padding: rem(31px) rem(82px) rem(31px);
  overflow: hidden;
  @include between($widescreen, $ultrahd) {
    padding: rem(31px) rem(40px) rem(31px);
  }
}

@import "__header/client-card-settings__header";
@import "__body/client-card-settings__body";
