.node-drag-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: $gray-2;
  border-radius: 0px;
  box-shadow: none;
  font-weight: 600;
  font-size: rem(12px);
  line-height: em(24px, 12px);
  color: $gray-5;
  background-color: transparent;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
  height: em(48px, 12px);
  padding-top: em(12px - 1px, 12px);
  padding-right: em(12px - 1px, 12px);
  padding-bottom: em(12px - 1px, 12px);
  padding-left: em(16px - 1px, 12px);
  input {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    padding: 0;
    border: none;
    outline: none;
    &::placeholder {
      color: $gray-5;
    }
  }
  &#{&}--is-dragging {
    opacity: 0;
  }
}
