.client-dropdown {
  position: relative;
  &.dropdown--is-active {
    background-color: $white-0;
  }
}

@import "__status/client-dropdown__status";
@import "__item/client-dropdown__item";
@import "__content/client-dropdown__content";
@import "__menu/client-dropdown__menu";
