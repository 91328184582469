.templates {
  display: grid;
  grid-template-columns: 1fr;
  flex-grow: 1;
}

@import "__container/templates__container";
@import "__title/templates__title";
@import "__body/templates__body";
@import "__list-container/templates__list-container";
@import "__close-button/templates__close-button";
