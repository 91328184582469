.bot-type__info-block-title {
  color: $gray-5;
  text-align: center;
  font-size: rem(13px);
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: -0.13px;
  margin-top: rem(19px);
  white-space: pre;
}
