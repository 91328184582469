.templates-category {
  $this: &;
  width: 100%;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  outline: none;
  position: relative;
  vertical-align: top;
  user-select: none;
  height: 100%;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  overflow: hidden;
  font-weight: 500;
  font-size: rem(14px);
  line-height: 1.5;
  letter-spacing: -0.02em;
  color: $gray-5;
  border-radius: rem(6px);
  .icon:first-child:not(:last-child) {
    margin-right: rem(12px);
  }
  .icon:last-child:not(:first-child) {
    margin-left: 0.25em;
  }
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: rem(14px);
    line-height: 1.5;
    letter-spacing: -0.02em;
    color: $gray-5;
    &:first-child:not(:last-child) {
      margin-right: 0.25em;
    }
    &:last-child:not(:first-child) {
      margin-left: 0.25em;
    }
  }
  &:not(#{$this}--is-selected) {
    background-color: white;
    &:hover:not(:has(.dropdown__item:hover, .dropdown__content:hover)) {
      background-color: #f3f3f5;
    }
    &:active {
      background-color: #f3f3f5;
    }
  }
  &--is-selected {
    background-color: $gray-1;
  }
  &#{&}--chatroom {
    border-radius: 0;
    border-bottom: 1px solid $gray-2;
  }
  &#{&}--is-content-space-between {
    justify-content: space-between;
    & > .icon {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
    }
  }
}
