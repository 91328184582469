.giphy-trademark {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  right: 0;
  bottom: 0;
  margin-left: auto;
  opacity: 0.7;
  pointer-events: none;
  font-weight: 500;
  font-size: rem(8px);
  line-height: 1;
  color: $white-0;
  width: max-content;
  img {
    max-width: rem(46px);
    height: auto;
    margin-left: rem(4px);
    image-rendering: pixelated;
  }
}
