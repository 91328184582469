.sms-hints {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  bottom: calc(-25px + -8px);
}

@import "__limit/sms-hints__limit";
@import "__left/sms-hints__left";
@import "__right/sms-hints__right";
@import "__count/sms-hints__count";
@import "__warning/sms-hints__warning";
