.pagination__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: em(6px, 14px);
  box-shadow: none;
  font-weight: 500;
  font-size: rem(14px);
  line-height: em(17px, 14px);
  color: $gray-4;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  height: em(36px, 14px);
  min-width: em(36px, 14px);
  padding-top: em(10px, 14px);
  padding-right: em(14px - 1px, 14px);
  padding-bottom: em(10px, 14px);
  padding-left: em(14px - 1px, 14px);
  margin-left: rem(4px);
  margin-right: rem(4px);
  cursor: pointer;
  &:hover {
    color: $gray-5;
    background-color: $gray-1;
  }
  .icon {
    width: em(24px, 14px);
    height: em(24px, 14px);
    margin-left: em(-8px, 14px);
    margin-right: em(-8px, 14px);
  }
  &--is-active {
    pointer-events: none;
    cursor: default;
    border-color: $gray-2;
  }
}
