@charset "UTF-8";

.node-settings {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  background-color: $white-0;
  margin-left: calc(var(--node-panel-body-px) * -1);
  margin-right: calc(var(--node-panel-body-px) * -1);
  overflow: hidden;
}

@import "__button/node-settings__button";
@import "__textfield/node-settings__textfield";
@import "__label/node-settings__label";
@import "__image/node-settings__image";
@import "__dropzone/node-settings__dropzone";
@import "__header/node-settings__header";
@import "__body/node-settings__body";
