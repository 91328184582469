.message__left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  &:not(:last-child) {
    margin-right: rem(4px);
  }
}
