.dashboard-blocks {
  --dashboard-blocks-offset-x: #{rem(8px)};
  --dashboard-blocks-offset-y: #{rem(8px)};
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--dashboard-blocks-offset-y) * -1)
    calc(var(--dashboard-blocks-offset-x) * -1);
  &#{&}--is-centered {
    justify-content: center;
  }
  @for $i from 0 through 20 {
    &#{&}--has-mt-#{$i} {
      margin-top: #{$i * 0.125rem};
    }
  }
}

@import "__item/dashboard-blocks__item";
