@charset "UTF-8";

.action-dropdown {
  $this: &;
  --action-dropdown-button-active-bc: #{$gray-2};
  --action-dropdown-item-gap: #{0};
  @for $i from 0 through 20 {
    &#{$this}--has-gap-#{$i} {
      --action-dropdown-item-gap: #{$i * 0.125rem};
    }
  }
  position: relative;
  &.dropdown--is-active {
    z-index: $default-z * 2;
  }
}

@import "__button/action-dropdown__button";
@import "__item/action-dropdown__item";
@import "__content/action-dropdown__content";
@import "__menu/action-dropdown__menu";
