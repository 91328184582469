.bot-type__info-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: rem(128px);
  height: rem(85px);
  padding-bottom: rem(4px);
  &:not(:last-child) {
    margin-right: rem(8px);
  }
}
