.switch {
  display: block;
  width: rem(64px);
  height: rem(32px);
  cursor: pointer;
  position: relative;
}

@import "__input/switch__input";
@import "__slider/switch__slider";
@import "__labels/switch__labels";
