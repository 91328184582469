.third-party-textarea {
  color: $gray-4;
  font-size: rem(13px);
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: -0.13px;
  width: 100%;
  min-height: rem(153px);
  padding: rem(14px) rem(17px) rem(36px) rem(14px);
  border: 1px solid $gray-2;
  border-radius: 8px;
  background-color: $white-0;
  resize: none;
  outline: none;
  &#{&}--is-error {
    border-color: $red-0;
  }
  textarea {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    resize: none;
    background-color: transparent;
    &::placeholder {
      color: $gray-4;
    }
  }
}
