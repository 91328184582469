/* Title/Title 1 Bold */
%title-lg-w-bold {
  font-weight: 700;
  font-size: rem(18px);
  line-height: 1.4;
  letter-spacing: -0.01em;
}
/* Title/Title 1 Semi */
%title-lg-w-semi-bold {
  font-weight: 600;
  font-size: rem(18px);
  line-height: 1.4;
  letter-spacing: -0.01em;
}
/* Title/Title 1 Medium */
%title-lg-w-medium {
  font-weight: 500;
  font-size: rem(18px);
  line-height: 1.4;
  letter-spacing: -0.01em;
}
/* Title/Title Small 1 Bold */
%title-md-w-bold {
  font-weight: 700;
  font-size: rem(15px);
  line-height: 1.2;
  letter-spacing: -0.01em;
}
/* Title/Title Small 1 Semi */
%title-md-w-semi-bold {
  font-weight: 600;
  font-size: rem(15px);
  line-height: 1.2;
  letter-spacing: -0.01em;
}
/* Title/Title Small 1 Medium */
%title-md-w-medium {
  font-weight: 500;
  font-size: rem(15px);
  line-height: 1.2;
  letter-spacing: -0.01em;
}
/* Title/Title Small 2 Bold */
%title-sm-w-bold {
  font-weight: 700;
  font-size: rem(14px);
  line-height: 1.4;
  letter-spacing: -0.01em;
}
/* Title/Title Small 2 Semi */
%title-sm-w-semi-bold {
  font-weight: 600;
  font-size: rem(14px);
  line-height: 1.4;
  letter-spacing: -0.01em;
}
/* Title/Title Small 2 Medium */
%title-sm-w-medium {
  font-weight: 500;
  font-size: rem(14px);
  line-height: 1.4;
  letter-spacing: -0.01em;
}
