.loader {
  --loader-size: #{rem(48px)};
  --loader-color: #{$gray-3};
  display: block;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  position: relative;
  color: transparent;
  pointer-events: none;
  text-align: center;
  user-select: none;
  pointer-events: none;
  &::after {
    content: "";
    display: block;
    height: var(--loader-size);
    width: var(--loader-size);
    position: absolute;
    left: calc(50% - (var(--loader-size) * 0.5));
    top: calc(50% - (var(--loader-size) * 0.5));
    animation: spinAround 500ms linear infinite;
    border: 2px solid var(--loader-color);
    border-radius: 9999px;
    border-right-color: transparent;
    border-top-color: transparent;
  }
  &#{&}--is-fixed {
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    z-index: $default-z * 2;
  }
  &#{&}--is-fullheight {
    min-height: 100vh;
  }
  &#{&}--has-size-xs {
    --loader-size: #{rem(10px)};
  }
  &#{&}--has-size-sm {
    --loader-size: #{rem(16px)};
  }
  &#{&}--has-size-md {
    --loader-size: #{rem(32px)};
  }
  &#{&}--has-size-lg {
    --loader-size: #{rem(48px)};
  }
  &#{&}--has-size-xl {
    --loader-size: #{rem(64px)};
  }
  &#{&}--is-gray-0 {
    --loader-color: #{$gray-0};
  }
  &#{&}--is-gray-1 {
    --loader-color: #{$gray-1};
  }
  &#{&}--is-gray-2 {
    --loader-color: #{$gray-2};
  }
  &#{&}--is-gray-3 {
    --loader-color: #{$gray-3};
  }
  &#{&}--is-gray-4 {
    --loader-color: #{$gray-4};
  }
  &#{&}--is-gray-5 {
    --loader-color: #{$gray-5};
  }
  &#{&}--is-purple-3 {
    --loader-color: #{$purple-3};
  }
}
