@charset "UTF-8";

.delete-modal {
  position: relative;
}

@import "__title/channel-connect-modal__title";
@import "__subtitle/channel-connect-modal__subtitle";
@import "__body/channel-connect-modal__body";
@import "__footer/channel-connect-modal__footer";
@import "__bot-connection/channel-connect-modal__bot-connection";
