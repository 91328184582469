.message__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  &:not(:first-child) {
    margin-left: rem(4px);
  }
}
