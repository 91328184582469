.templates-categories__input {
  display: flex;
  padding: rem(14px) 0;
  margin-top: rem(12px);
  & > .button {
    margin-left: rem(9px);
  }
  &#{&}--chatroom {
    padding: rem(16px);
    margin-top: 0;
    border-bottom: 1px solid $gray-2;
  }
}
