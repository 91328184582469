@charset "UTF-8";

.settings-menu {
  display: flex;
  flex-direction: column;
  float: left;
  width: rem(312px);
  min-height: 100vh;
  border-right: 1px solid $gray-2;
  padding: rem(31px) rem(26px);
  background-color: $white-0;
  font-size: rem(16px);
  @include between($widescreen, $ultrahd) {
    width: rem(300px);
  }
}

@import "__label/settings-menu__label";
@import "__link/settings-menu__link";
@import "__footer/settings-menu__footer";
@import "__list/settings-menu__list";
@import "__nav/settings-menu__nav";
