@charset "UTF-8";

.messages {
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  padding-left: rem(20px);
  padding-right: rem(20px);
  overflow-y: auto;
  @include scrollbar;
}

@import "__anchor/messages__anchor";
@import "__waypoint/messages__waypoint";
@import "__container/messages__container";
