.main-menu__list {
  li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  li:not(:last-child) {
    margin-bottom: rem(5px);
    &:first-child {
      margin-bottom: rem(18px);
    }
  }
  &:not(:last-child) {
    margin-bottom: rem(10px);
  }
}
