.table__header {
  font-weight: 500;
  font-size: rem(16px);
  line-height: 1;
  color: $black-0;
  margin-left: 0;
  margin-right: 0;
  text-transform: initial;
  &:first-of-type {
    margin-left: 0;
    margin-right: 0;
    padding-left: rem(20px);
  }
  &:last-of-type {
    padding-right: rem(20px);
  }
  &#{&}--is-clickable {
    cursor: pointer;
  }
}
