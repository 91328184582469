@charset "UTF-8";

.chat-card {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: rem(36px + 28px);
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: rem(72px);
  padding: rem(16px) rem(16px) rem(16px - 1px) rem(13px);
  border-bottom: 1px solid $gray-2;
  background-color: $white-0;
  color: $gray-3;
  user-select: none;
  outline: none;
  cursor: pointer;
  transition: background-color 100ms;
  &:hover:not(#{&}--is-selected) {
    background-color: $gray-0;
  }
  &#{&}--is-selected {
    background-color: $gray-1;
  }
  &#{&}--is-instagram {
    color: $social-insta;
  }
  &#{&}--is-messenger {
    color: $social-f_vk;
  }
  &#{&}--is-telegram {
    color: $social-teleg;
  }
  &#{&}--is-vk {
    color: $social-f_vk;
  }
  &#{&}--is-whatsapp {
    color: $social-whats;
  }
  &#{&}--is-email {
    color: $social-email;
  }
  &#{&}--is-sms {
    color: $social-sms;
  }
}

@import "__notification-badge/chat-card__notification-badge";
@import "__time-badge/chat-card__time-badge";
@import "__message/chat-card__message";
@import "__name/chat-card__name";
@import "__selector/chat-card__selector";
@import "__info/chat-card__info";
@import "__content/chat-card__content";
@import "__left/chat-card__left";
@import "__right/chat-card__right";
