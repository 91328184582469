.sms-hints__count {
  border-radius: 8px;
  background: $gray-0;
  margin-left: rem(4px);
  padding: rem(4px) rem(8px);
  color: $gray-4;
  font-size: rem(11px);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  &#{&}--is-not-valid {
    color: $red-0;
    background-color: rgba(255, 87, 87, 0.1);
  }
}
