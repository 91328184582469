.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  height: rem(24px);
  width: rem(24px);
  transition: color 100ms;
  &#{&}--is-relative {
    position: relative;
  }
  &#{&}--is-hidden {
    display: none;
  }
  &#{&}--is-clickable {
    cursor: pointer;
    pointer-events: all;
  }
  &#{&}--is-draggable {
    cursor: grab;
    pointer-events: all;
  }
  &#{&}--is-social-instagram {
    color: $social-insta;
  }
  &#{&}--is-social-messenger,
  &#{&}--is-social-vk {
    color: $social-f_vk;
  }
  &#{&}--is-social-whatsapp {
    color: $social-whats;
  }
  &#{&}--is-social-telegram {
    color: $social-teleg;
  }
  &#{&}--is-social-sms {
    color: $social-sms;
  }
  &#{&}--is-social-email {
    color: $social-email;
  }
  &#{&}--is-purple-1 {
    color: $purple-1;
  }
  &#{&}--is-purple-2 {
    color: $purple-2;
  }
  &#{&}--is-purple-3 {
    color: $purple-3;
  }
  &#{&}--is-sky-0 {
    color: $sky-0;
  }
  &#{&}--is-green-0 {
    color: $green-0;
  }
  &#{&}--is-gray-2 {
    color: $gray-2;
  }
  &#{&}--is-gray-3 {
    color: $gray-3;
  }
  &#{&}--is-gray-4 {
    color: $gray-4;
  }
  &#{&}--is-gray-5 {
    color: $gray-5;
  }
  &#{&}--is-red-0 {
    color: $red-0;
  }
  &#{&}--is-black-0 {
    color: $black-0;
  }
  &#{&}--hover-black-0 {
    &:hover {
      color: $black-0;
    }
  }
  &#{&}--hover-red-0 {
    &:hover {
      color: $red-0;
    }
  }
  &#{&}--hover-gray-3 {
    &:hover {
      color: $gray-3;
    }
  }
  &#{&}--has-default-size {
    width: auto;
    height: auto;
  }

  &#{&}--has-default-height {
    height: auto;
  }

  &#{&}--has-no-margin {
    margin: 0 !important;
  }

  &#{&}--is-11x11 {
    width: rem(11px);
    height: rem(11px);
  }
  &#{&}--is-12x12 {
    width: rem(12px);
    height: rem(12px);
  }
  &#{&}--is-13x13 {
    width: rem(13px);
    height: rem(13px);
  }
  &#{&}--is-14x14 {
    width: rem(14px);
    height: rem(14px);
  }
  &#{&}--is-15x15 {
    width: rem(15px);
    height: rem(15px);
  }
  &#{&}--is-16x16 {
    width: rem(16px);
    height: rem(16px);
  }
  &#{&}--is-17x17 {
    width: rem(17px);
    height: rem(17px);
  }
  &#{&}--is-18x18 {
    width: rem(18px);
    height: rem(18px);
  }
  &#{&}--is-19x19 {
    width: rem(19px);
    height: rem(19px);
  }
  &#{&}--is-20x20 {
    width: rem(20px);
    height: rem(20px);
  }
  &#{&}--is-21x21 {
    width: rem(21px);
    height: rem(21px);
  }
  &#{&}--is-22x22 {
    width: rem(22px);
    height: rem(22px);
  }
  &#{&}--is-24x24 {
    width: rem(24px);
    height: rem(24px);
  }
  &#{&}--is-27x27 {
    width: rem(27px);
    height: rem(27px);
  }
  &#{&}--is-28x28 {
    width: rem(28px);
    height: rem(28px);
  }
  &#{&}--is-30x30 {
    width: rem(30px);
    height: rem(30px);
  }
  &#{&}--is-33x33 {
    width: rem(33px);
    height: rem(33px);
  }
  &#{&}--is-36x36 {
    width: rem(36px);
    height: rem(36px);
  }
  &#{&}--is-38x38 {
    width: rem(38px);
    height: rem(38px);
  }
  &#{&}--is-40x40 {
    width: rem(40px);
    height: rem(40px);
  }
  &#{&}--is-58x58 {
    width: rem(58px);
    height: rem(58px);
  }
  @at-root {
    div#{&} {
      display: flex;
    }
  }
}
