.channel-connect-modal__body {
  @extend %body-text-md-w-regular;
  margin-top: calc(var(--modal-padding) * -1);
  margin-right: calc(var(--modal-padding) * -1);
  margin-left: calc(var(--modal-padding) * -1);
  padding-top: rem(40px);
  padding-right: rem(40px);
  padding-bottom: rem(20px);
  padding-left: rem(40px);
  color: $gray-5;
  b {
    font-weight: 600;
    color: $black-0;
  }
  & > .block:last-child {
    overflow-y: auto;
    @include scrollbar;
  }
}
