.calendar-event-content__actions {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
  padding: rem(9px) rem(9px) 0;
  margin-bottom: rem(8px);
  .buttons {
    margin-right: 29.5px;
  }
}
