@charset "UTF-8";

.user-dropdown {
  --button-border-width: 4px;
  padding: calc(10px - var(--button-border-width));
}

@import "__subtitle/user-dropdown__subtitle";
@import "__title/user-dropdown__title";
@import "__avatar-wrapper/user-dropdown__avatar-wrapper";
@import "__button/user-dropdown__button";
@import "__info/user-dropdown__info";
@import "__item/user-dropdown__item";
@import "__content/user-dropdown__content";
@import "__menu/user-dropdown__menu";
