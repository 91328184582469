.buttons {
  $this: &;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &#{&}--is-centered {
    justify-content: center;
  }
  &#{&}--is-column {
    flex-direction: column;
    .button {
      --buttons-gap: 0;
      &:not(:last-child) {
        margin-bottom: rem(8px);
      }
    }
  }
  &#{&}--has-addons {
    flex-wrap: nowrap;
    max-width: max-content;
    border-radius: em(6px);
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    overflow: hidden;
    .button {
      border-radius: 0px;
      border-width: 0px;
      &:focus,
      &:focus:not(:active) {
        box-shadow: none;
      }
      &:not(:last-child) {
        border-right-width: 1px;
        border-right-style: solid;
        border-right-color: rgba($white-0, 0.2);
        padding-right: em(12px, 14px);
      }
      &:not(:first-child) {
        padding-left: em(12px, 14px);
      }

      &:hover {
        z-index: 2;
      }

      &:focus {
        z-index: 3;
      }

      &:focus:hover,
      &:active:hover {
        z-index: 4;
      }
    }
  }
  &:not(&--has-addons) {
    --buttons-gap: #{rem(8px)};
    .button {
      &:not(:last-child) {
        margin-right: var(--buttons-gap);
      }
    }
    &#{$this}--is-wrappable {
      flex-wrap: wrap;
      margin-bottom: calc(var(--buttons-gap) * -1);
      .button {
        margin-bottom: var(--buttons-gap);
      }
    }
    @for $i from 0 through 10 {
      &#{$this}--has-gap-#{$i} {
        --buttons-gap: #{$i * 0.25rem};
      }
    }
  }
}
