.client-panel__text {
  @extend %collapsable-text;
  display: block;
  font-weight: 600;
  font-size: rem(13px);
  line-height: 1.6;
  letter-spacing: 0.04em;
  color: $gray-4;
  &:not(:last-child) {
    margin-bottom: rem(3px);
  }
  &#{&}--is-empty {
    white-space: normal;
  }
}
