.error__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(16px);
  &#{&}--is-teams {
    padding-bottom: rem(130px);
  }
  &#{&}--is-bots {
    padding-bottom: rem(135px);
  }
}
