@charset "UTF-8";

.main-menu {
  display: flex;
  flex-direction: column;
  float: left;
  width: rem(80px);
  min-height: 100vh;
  padding: rem(10px);
  background-color: $gray-0;
  font-size: rem(16px);
}

@import "__tooltip/main-menu__tooltip";
@import "__link/main-menu__link";
@import "__list/main-menu__list";
@import "__nav/main-menu__nav";
@import "__notification-badge/main-menu__notification-badge";
@import "__label/main-menu__label";
