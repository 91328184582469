.field__help {
  @extend %body-text-sm-w-regular;
  @extend %collapsable-text;
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  margin-left: rem(16px);
  margin-right: rem(16px);
  margin-top: rem(8px);
  user-select: none;
  &#{&}--is-gray-5 {
    color: $gray-5;
  }
  &#{&}--is-red-0 {
    color: $red-0;
  }
}
