@charset "UTF-8";

.node-tabs {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: space-between;
  white-space: nowrap;
  flex-shrink: 0;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  overflow-x: overlay;
  @include scrollbar;
}

@import "__link/node-tabs__link";
@import "__item/node-tabs__item";
@import "__list/node-tabs__list";
