.calendar-event-content__action-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  user-select: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: em(8px, 14px);
  background-color: transparent;
  box-shadow: none;
  font-weight: 500;
  font-size: rem(14px);
  line-height: 1.5;
  color: $gray-3;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 100ms;
  width: em(36px, 14px);
  height: em(36px, 14px);
  padding-top: em(((36px - 16px) * 0.5) - 1px, 14px);
  padding-right: em(10px - 1px, 14px);
  padding-bottom: em(((36px - 16px) * 0.5) - 1px, 14px);
  padding-left: em(10px - 1px, 14px);
  .icon {
    width: em(16px, 14px);
    height: em(16px, 14px);
  }
  &:hover {
    color: $gray-5;
    background-color: $gray-0;
  }
}
