@charset "UTF-8";

.flow-testing-panel {
  --widget-width: 317px;
  --widget-height: 652px;
  --widget-outer-radius: 47px;
  --widget-inner-radius: 40.5px;
  --notch-width: 120px;
  --notch-height: 24px;
  --notch-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  width: rem(508px);
  height: 100%;
  padding-top: rem(64px);
  padding-right: rem(80px);
  padding-bottom: rem(40px);
  padding-left: rem(80px);
  background-color: $white-0;
  box-shadow: $shadow-3;
  position: absolute;
  right: 0;
  top: 0;
  z-index: $default-z;
  @media screen and (min-height: 1080px) {
    --widget-width: 348px;
    --widget-height: 716.73px;
    padding-top: rem(82px);
    padding-bottom: rem(82px);
  }
}

@import "__close-button/flow-testing-panel__close-button";
@import "__title/flow-testing-panel__title";
@import "__header/flow-testing-panel__header";
@import "__body/flow-testing-panel__body";
@import "__footer/flow-testing-panel__footer";
