.node-tabs__list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 0;
  border-radius: 9px;
  border-width: 2px;
  border-style: solid;
  border-color: $gray-1;
  background-color: $gray-1;
}
