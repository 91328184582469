@charset "UTF-8";

.calendar-event-participant-dropdown {
  width: 100%;
  position: relative;
}

@import "__info/calendar-event-participant-dropdown__info";
@import "__input/calendar-event-participant-dropdown__input";
@import "__control/calendar-event-participant-dropdown__control";
@import "__item/calendar-event-participant-dropdown__item";
@import "__content/calendar-event-participant-dropdown__content";
@import "__menu/calendar-event-participant-dropdown__menu";
