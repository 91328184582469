@charset "UTF-8";

.bot-validation-dropdown {
  position: relative;
  &.dropdown--is-active {
    z-index: $default-z * 2;
  }
}

@import "__title/bot-validation-dropdown__title";
@import "__header/bot-validation-dropdown__header";
@import "__body/bot-validation-dropdown__body";
@import "__item/bot-validation-dropdown__item";
@import "__button/bot-validation-dropdown__button";
@import "__content/bot-validation-dropdown__content";
@import "__menu/bot-validation-dropdown__menu";
