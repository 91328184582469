.emoji-bar__container {
  display: none;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  &--is-active {
    display: flex;
    flex-direction: column;
  }
}
