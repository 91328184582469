.bot-validation-dropdown__button {
  color: $red-0;
  background-color: $red-1;
  &:hover {
    border-color: rgba($red-0, 0.5);
  }
  &:focus:not(:active),
  &:active {
    background-color: rgba($red-0, 0.2);
    border-color: $red-0;
  }
}
