.ai-bot-creation__badge {
  border-radius: 8px;
  background: rgba(148, 123, 253, 0.1);
  padding: rem(4px) rem(12px) rem(4px) rem(4px);
  color: $purple-2;
  font-size: rem(11px);
  font-weight: 600;
  line-height: 1.5;
  width: fit-content;
  &#{&}--is-channels {
    margin-left: rem(12px);
    border-radius: 8px;
    background: $gray-0;
    padding: rem(12px);
    display: flex;
    align-items: center;
    justify-content: center;
    & > .icon {
      &:not(:last-child) {
        margin-right: rem(8px);
      }
    }
  }
}
