.node-scenario-block__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: rem(30px);
  height: rem(30px);
  border-radius: 9999px;
  padding: rem(8px);
  color: $white-0;
  background-color: transparent;
  transition: color 100ms, background-color 100ms;
  position: relative;
  &::after {
    content: "";
    width: rem(38px);
    height: rem(38px);
    border-radius: 9999px;
    transform: scale(0);
    background-color: rgba($white-0, 0.3);
    position: absolute;
    top: -4px;
    left: -4px;
    z-index: -1;
    transition: transform 100ms;
  }
  &:hover {
    color: $gray-5;
    background-color: $white-0;
    &::after {
      transform: scale(1);
    }
  }
}
