.edge-buttons {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  max-width: max-content;
  border-radius: 8px;
  overflow: hidden;
  padding: rem(2px);
  margin: auto;
  background-color: $white-0;
  pointer-events: none;
  .edge-button {
    pointer-events: auto;
  }
  &#{&}--has-addons {
    .edge-button {
      color: $gray-4;
      &:hover {
        background-color: $gray-1;
      }
    }
  }
  &:not(&--has-addons) {
    &:hover {
      border-radius: 9999px;
    }
  }
}
