@charset "UTF-8";

.custom-fields {
  --pl: 0px;
  --pr: 0px;
  --mw: #{rem(348px)};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: calc(var(--mw) + var(--pl) + var(--pr));
  position: relative;
  padding-left: var(--pl);
  padding-right: var(--pr);
  &#{&}--has-px {
    --pl: 30px;
    --pr: 27px;
  }
}
