@charset "UTF-8";

.checkbox {
  $indicator: ".checkbox__indicator";
  position: relative;
  cursor: pointer;
  input {
    display: none;
    &:checked ~ #{$indicator} {
      background-color: $purple-3;
      border-color: $purple-3;
      &::after {
        display: block;
      }
    }
  }
}

@import "__indicator/checkbox__indicator";
