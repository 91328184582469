.flow-testing-widget__notch {
  position: absolute;
  width: 98px;
  height: 20px;
  top: 0;
  left: calc((var(--widget-width) - var(--notch-width)) / 2);
  z-index: 4;
  background-color: $gray-2;
  border-bottom-left-radius: var(--notch-radius);
  border-bottom-right-radius: var(--notch-radius);
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 7px;
    height: 7px;
  }
  &::before {
    background: radial-gradient(
      circle at bottom left,
      transparent 0,
      transparent 70%,
      $gray-2 70%,
      $gray-2 100%
    );
    left: -7px;
  }
  &::after {
    background-image: radial-gradient(
      circle at bottom right,
      transparent 0,
      transparent 70%,
      $gray-2 70%,
      $gray-2 100%
    );
    right: -7px;
  }
}
