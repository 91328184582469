.clients-statistic {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding-top: rem(31px);
  padding-bottom: rem(36px);
}
@import "__header/clients-statistic__header";
@import "__title/clients-statistic__title";
@import "__body/clients-statistic__body";
