.operator-card__no-teams {
  @extend %collapsable-text;
  background-color: $gray-1;
  border-radius: rem(4px);
  padding: rem(4px) rem(8px);
  font-size: rem(12px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: 1.4;
  color: $gray-5;
}
