.edge-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  border: none;
  border-radius: 6px;
  color: $gray-4;
  box-shadow: none;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  padding: 0;
  width: rem(26px);
  height: rem(26px);
  background-color: $white-0;
  cursor: pointer;
  .icon {
    width: 100%;
    height: 100%;
  }
}
