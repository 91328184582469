.client-card__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  border-width: 0px;
  border-style: solid;
  border-color: transparent;
  border-radius: em(20px, 11px);
  box-shadow: none;
  font-weight: 500;
  font-size: rem(11px);
  line-height: em(24px, 11px);
  color: $white-0;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  height: em(30px, 11px);
  padding-top: em(3px, 11px);
  padding-right: em(8px, 11px);
  padding-bottom: em(3px, 11px);
  padding-left: em(8px, 11px);
  cursor: pointer;
  span:not([class]) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: em(16px, 11px);
    &:not(:last-child) {
      margin-right: em(8px, 11px);
    }
  }
  .icon {
    width: em(24px, 11px);
    height: em(24px, 11px);
    pointer-events: none;
  }
  .icon.icon--is-24x24 {
    &:first-child:last-child {
      margin-left: em(-5px, 11px);
      margin-right: em(-5px, 11px);
    }
    &:first-child:not(:last-child) {
      margin-left: em(-5px, 11px);
      margin-right: em(3px, 11px);
    }
    &:last-child:not(:first-child) {
      margin-left: em(3px, 11px);
      margin-right: em(-5px, 11px);
    }
  }
  .icon.icon--is-8x8 {
    visibility: hidden;
    opacity: 0;
    margin-left: em(-8px * 2, 11px);
    margin-right: em(-8px * 2, 11px);
    transition: all 200ms;
  }
  &#{&}--is-active {
    .icon.icon--is-8x8 {
      transform: rotate(-180deg);
    }
  }
  &#{&}--is-telegram {
    background-color: $social-teleg;
    &:hover {
      background-color: $social-teleg-hover;
    }
  }
  &#{&}--is-whatsapp {
    background-color: $social-whats;
    &:hover {
      background-color: $social-whats-hover;
    }
  }
  &#{&}--is-facebook_messenger {
    background-color: $social-f_vk;
    &:hover {
      background-color: $social-f_vk-hover;
    }
  }
  &#{&}--is-instagram {
    background-color: $social-insta;
    &:hover {
      background-color: $social-insta-hover;
    }
  }
  &#{&}--is-vk {
    background-color: $social-f_vk;
    &:hover {
      background-color: $social-f_vk-hover;
    }
  }
  &#{&}--is-sms {
    background-color: $social-sms;
    &:hover {
      background-color: $social-sms-hover;
    }
  }
  &#{&}--is-email {
    background-color: $social-email;
    &:hover {
      background-color: $social-email-hover;
    }
  }
  @at-root {
    .client-card:hover &,
    &#{&}--is-active {
      .icon.icon--is-8x8 {
        visibility: visible;
        opacity: 1;
        margin-left: em(-8px, 11px);
        margin-right: em(-8px, 11px);
      }
    }
  }
}
