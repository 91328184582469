@charset "UTF-8";

.node {
  $this: &;
  $reactflow-node: ".react-flow__node";
  --node-width: #{rem(200px)};
  --node-border-radius: 8px;
  --node-border-width: 0px;
  --node-border-style: solid;
  --node-border-color: transparent;
  --node-bc: #{$white-0};
  --node-p: #{rem(16px)};
  --node-color: #{$black-0};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: var(--node-width);
  border-radius: var(--node-border-radius);
  border-width: var(--node-border-width);
  border-style: var(--node-border-style);
  border-color: var(--node-border-color);
  background-color: var(--node-bc);
  color: var(--node-color);
  padding: var(--node-p);
  position: relative;
  cursor: pointer;
  &#{&}--type-start {
    --node-width: #{rem(240px)};
    --node-bc: #{$purple-2};
    --node-p: #{rem(14px) rem(27px)};
    --node-color: #{$white-0};
    background-image: linear-gradient(
      90deg,
      rgba(119, 87, 254, 0.39) 0%,
      rgba(148, 123, 253, 0) 73.89%
    );
    box-shadow: 0px 3px 4px rgba(2, 3, 3, 0.03), 0px 3px 3px rgba(2, 3, 3, 0.02),
      0px 1px 8px rgba(2, 3, 3, 0.04);
    cursor: default;
  }
  &#{&}--type-message {
    --node-width: #{rem(320px)};
    --node-border-width: 0px 1.5px 1.5px 1.5px;
    --node-p: #{rem(20px) rem(20px - 1.5px) rem(20px - 1.5px) rem(20px - 1.5px)};
    box-shadow: 0px -4px 0px rgba(148, 123, 253, 0.5),
      0px 2px 2px rgba(2, 3, 3, 0.03), 0px 3px 1px rgba(2, 3, 3, 0.02),
      0px 1px 5px rgba(2, 3, 3, 0.04);
    transition: box-shadow 200ms;
    &:hover {
      box-shadow: 0px -4px 0px rgba(148, 123, 253, 0.9),
        0px 3px 4px rgba(2, 3, 3, 0.03), 0px 3px 3px rgba(2, 3, 3, 0.02),
        0px 1px 8px rgba(2, 3, 3, 0.04);
    }
    &#{$this}--has-error {
      &::after {
        width: calc(100% + 2px + 12px);
        height: calc(100% + 1px + 12px);
        top: calc((6px + (1px / 2)) * -1);
        left: calc((6px + 1px) * -1);
      }
    }
    @at-root {
      #{$reactflow-node}.selected & {
        --node-border-color: #{$gray-4};
        box-shadow: 0px -4px 0px rgba(148, 123, 253, 0.9);
      }
    }
  }
  &#{&}--type-integration {
    --node-width: #{rem(320px)};
    --node-border-width: 0px 1.5px 1.5px 1.5px;
    --node-p: #{rem(20px) rem(20px - 1.5px) rem(20px - 1.5px) rem(20px - 1.5px)};
    box-shadow: 0px -4px 0px rgba(148, 123, 253, 0.5),
      0px 2px 2px rgba(2, 3, 3, 0.03), 0px 3px 1px rgba(2, 3, 3, 0.02),
      0px 1px 5px rgba(2, 3, 3, 0.04);
    transition: box-shadow 200ms;
    &:hover {
      box-shadow: 0px -4px 0px rgba(148, 123, 253, 0.9),
        0px 3px 4px rgba(2, 3, 3, 0.03), 0px 3px 3px rgba(2, 3, 3, 0.02),
        0px 1px 8px rgba(2, 3, 3, 0.04);
    }
    &#{$this}--has-error {
      &::after {
        width: calc(100% + 2px + 12px);
        height: calc(100% + 1px + 12px);
        top: calc((6px + (1px / 2)) * -1);
        left: calc((6px + 1px) * -1);
      }
    }
    @at-root {
      #{$reactflow-node}.selected & {
        --node-border-color: #{$gray-4};
        box-shadow: 0px -4px 0px rgba(148, 123, 253, 0.9);
      }
    }
  }
  &#{&}--type-ask {
    --node-width: #{rem(320px)};
    --node-border-width: 0px 1.5px 1.5px 1.5px;
    --node-p: #{rem(20px) rem(20px - 1.5px) rem(20px - 1.5px) rem(20px - 1.5px)};
    box-shadow: 0px -4px 0px rgba(140, 200, 255, 0.4),
      0px 2px 2px rgba(2, 3, 3, 0.03), 0px 3px 1px rgba(2, 3, 3, 0.02),
      0px 1px 5px rgba(2, 3, 3, 0.04);
    transition: box-shadow 200ms;
    &:hover {
      box-shadow: 0px -4px 0px rgba(140, 200, 255, 0.95),
        0px 3px 4px rgba(2, 3, 3, 0.03), 0px 3px 3px rgba(2, 3, 3, 0.02),
        0px 1px 8px rgba(2, 3, 3, 0.04);
    }
    &#{$this}--has-error {
      &::after {
        width: calc(100% + 2px + 12px);
        height: calc(100% + 1px + 12px);
        top: calc((6px + (1px / 2)) * -1);
        left: calc((6px + 1px) * -1);
      }
    }
    @at-root {
      #{$reactflow-node}.selected & {
        --node-border-color: #{$gray-4};
        box-shadow: 0px -4px 0px rgba(140, 200, 255, 0.95);
      }
    }
  }
  &#{&}--type-scenario {
    --node-width: #{rem(320px)};
    --node-border-radius: 8px;
    --node-border-width: 1.5px;
    --node-p: #{rem(8px)};
    box-shadow: 0px 2px 2px rgba(2, 3, 3, 0.03), 0px 3px 1px rgba(2, 3, 3, 0.02),
      0px 1px 5px rgba(2, 3, 3, 0.04);
    &#{$this}--has-error {
      &::after {
        width: calc(100% + 2px + 12px);
        height: calc(100% + 1px + 12px);
        top: calc((6px + 1px) * -1);
        left: calc((6px + 1px) * -1);
      }
    }
    @at-root {
      #{$reactflow-node}.selected & {
        --node-border-color: #{$gray-4};
      }
    }
  }
  &#{&}--type-end {
    --node-width: #{rem(320px)};
    --node-border-width: 0px 1.5px 1.5px 1.5px;
    --node-p: #{rem(20px) rem(20px - 1.5px) rem(20px - 1.5px) rem(20px - 1.5px)};
    box-shadow: 0px -4px 0px rgba(57, 197, 83, 0.2),
      0px 2px 2px rgba(2, 3, 3, 0.03), 0px 3px 1px rgba(2, 3, 3, 0.02),
      0px 1px 5px rgba(2, 3, 3, 0.04);
    transition: box-shadow 200ms;
    &#{$this}--has-error {
      &::after {
        width: calc(100% + 2px + 12px);
        height: calc(100% + 1px + 12px);
        top: calc((6px + (1px / 2)) * -1);
        left: calc((6px + 1px) * -1);
      }
    }
    &:hover {
      box-shadow: 0px -4px 0px rgba(57, 197, 83, 0.5),
        0px 3px 4px rgba(2, 3, 3, 0.03), 0px 3px 3px rgba(2, 3, 3, 0.02),
        0px 1px 8px rgba(2, 3, 3, 0.04);
    }
    @at-root {
      #{$reactflow-node}.selected & {
        --node-border-color: #{$gray-4};
        box-shadow: 0px -4px 0px rgba(57, 197, 83, 0.5);
      }
    }
  }
  &#{&}--has-error {
    &::after {
      content: "";
      background-color: transparent;
      border-radius: 12px;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      top: -6px;
      left: -6px;
      z-index: -1;
      transition: background-color 100ms;
    }
    @at-root {
      #{$reactflow-node}.selected & {
        --node-border-color: transparent;
        box-shadow: none;
        &::after {
          background-color: rgba($red-0, 0.3);
        }
      }
    }
  }
}

@import "__text/node__text";
@import "__title/node__title";
@import "__image/node__image";
