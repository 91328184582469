.templates-item__controls {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: rem(8px);
  &#{&}--is-hidden {
    display: none;
  }
  & > button {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
  }
}
