.flow-testing-panel__close-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  border: none;
  box-shadow: none;
  vertical-align: top;
  white-space: nowrap;
  background-color: transparent;
  color: $gray-7;
  position: absolute;
  top: calc(40px - ((24px - 15px) / 2));
  right: calc(40px - ((24px - 15px) / 2));
  width: rem(24px);
  height: rem(24px);
  padding: 0;
  z-index: 1;
  cursor: pointer;
  .icon {
    width: rem(15px);
    height: rem(15px);
  }
}
