.node-attachment {
  --background-color: transparent;
  --gap: 0;
  display: flex;
  background-color: var(--background-color);
  padding: var(--gap);
  border-radius: 8px;
  position: relative;
  &#{&}--has-background-gray-0 {
    --background-color: #{$gray-0};
  }
  @for $i from 0 through 10 {
    &#{&}--has-gap-#{$i} {
      --gap: #{$i * 0.25rem};
    }
  }
}
