.teams-dropdown__item {
  background-color: $white-0;
  border-radius: 6px;
  padding: rem(6.5px) rem(10px);
  cursor: pointer;
  &:hover {
    background-color: $gray-1;
  }
  & > a {
    color: $black-0;
    font-weight: 500;
    font-size: rem(14px);
    line-height: 1.5;
    letter-spacing: -0.02em;
  }
  &:not(:last-child) {
    margin-bottom: rem(4px);
  }
}
