.user-dropdown__item {
  @at-root {
    a#{&},
    button#{&} {
      font-weight: 500;
      font-size: rem(14px);
      line-height: 1.5;
      letter-spacing: -0.02em;
      color: $gray-4;
      padding: rem(18px) rem(21px) rem(17px) rem(20px);
      background-color: $white-0;
      transition: color 100ms, background-color 100ms;
      &:hover {
        color: $gray-5;
        background-color: $gray-0;
      }
    }
  }
}
