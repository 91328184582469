.bot-preset__title {
  color: $black-0;
  padding: rem(16px);
  border-bottom: 1px solid $gray-0;
  width: 100%;
  @extend %title-lg-w-semi-bold;
  &#{&}--is-empty {
    color: rgba(153, 153, 153, 0.5);
    text-align: center;
    font-size: rem(13px);
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: -0.13px;
    padding: 0;
    margin-top: rem(22px);
  }
}
