@charset "UTF-8";

.card-tooltip {
  @extend %tooltip-dark-theme;
  --tooltip-content-py: 16px;
  --tooltip-content-px: 24px;
  line-height: 1.8;
  b {
    font-weight: 500;
  }
}

@import "__row/card-tooltip__row";
@import "__container/card-tooltip__container";
