.messages-date-time__badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: rem(30px);
  font-weight: 500;
  font-size: rem(11px);
  line-height: 1.5;
  color: $black-0;
  background-color: $gray-1;
  padding: rem(8px) rem(16px);
}
