.upload-files__button {
  @at-root {
    a#{&},
    button#{&},
    label#{&} {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      appearance: none;
      border-width: 1px;
      border-style: dashed;
      border-color: $gray-3;
      border-radius: em(6px);
      box-shadow: none;
      font-size: rem(16px);
      position: relative;
      vertical-align: top;
      white-space: nowrap;
      width: 100%;
      height: rem(52px);
      padding-top: em(8px - 1px);
      padding-right: em(10px - 1px);
      padding-bottom: em(8px - 1px);
      padding-left: em(10px - 1px);
      background-color: transparent;
      cursor: pointer;
      .attachment {
        pointer-events: none;
      }
      .attachment__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gray-5;
        background-color: $gray-1;
        padding: rem(11px);
      }
      &:not(:last-child) {
        margin-right: rem(10px);
      }
      &:hover,
      &#{&}--is-active {
        border-color: $gray-4;
      }
    }
    label#{&} {
      input {
        display: none;
      }
    }
  }
}
