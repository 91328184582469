.node-integration {
  width: 100%;
  padding: rem(17px);
  display: flex;
  align-items: center;
  background-color: $gray-0;
  border-radius: rem(8px);
  cursor: pointer;
  &#{&}--is-node-type {
    padding: rem(11px) rem(21px);
  }
  &#{&}--is-hoverable {
    &:hover {
      background-color: $gray-1;
    }
  }
}

@import "__title/node-integration__title";
@import "__container/node-integration__container";
