.client-card-settings__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  overflow-y: auto;
  position: relative;
  @include scrollbar;
}
