.templates-item-wrapper {
  display: flex;
  align-items: center;
  max-width: 50%;
  position: relative;
  min-width: -webkit-fill-available;
  &:hover {
    & > .templates-item__controls {
      display: flex !important;
      & > .dropdown {
        & > .dropdown__trigger {
          & > .templates-dropdown__trigger {
            display: flex;
          }
        }
      }
      & > button {
        & > span {
          display: flex;
        }
      }
    }
  }
}
