.history-list-item__circle {
  border-radius: 999px;
  position: absolute;
  left: 0;
  &#{&}--is-filled {
    width: 8px;
    height: 8px;
    background-color: $purple-2;
    left: -4.5px;
  }
  &#{&}--is-empty {
    width: 8px;
    height: 8px;
    background-color: $white-0;
    border: 2px solid $purple-2;
    left: -4.5px;
  }
}
