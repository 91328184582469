.comparison-bars__no-data {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: rem(11px);
  line-height: 1.5;
  color: $gray-3;
  & > span:last-child {
    margin-top: rem(20px);
    text-align: center;
  }
}
