.react-flow {
}

.react-flow__panel {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  margin: 16px;
  &#{&}--is-fullheight {
    height: 100%;
  }
}

.react-flow__node {
  &.dragging {
    z-index: 1000 !important;
  }
}

.react-flow__container {
  z-index: 1 !important;
}

.react-flow__edge-path {
  $this: &;
  stroke: $gray-3;
  stroke-width: 3;
  @at-root {
    .react-flow__edge.selected,
    .react-flow__edge:hover,
    .react-flow__edge:focus,
    .react-flow__edge:focus-visible {
      #{$this} {
        stroke: $purple-2;
      }
    }
  }
}

.react-flow__handle {
  &.connectable {
    cursor: pointer;
  }
}
