.calendar-event-participant-dropdown__info {
  @extend %collapsable-text;
  display: block;
  padding: rem(4px) rem(8px);
  border-radius: 4px;
  background-color: $purple-0;
  font-weight: 400;
  font-size: rem(12px);
  line-height: 1.4;
  color: $flowkit-purple;
  overflow: hidden;
}
