.channel-panel__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  overflow-y: auto;
  @include scrollbar;
  @at-root {
    .channel-panel.channel-panel--is-update &,
    .channel-panel.channel-panel--is-reconnect &,
    .channel-panel.channel-panel--is-delete & {
      padding: rem(40px) rem(16px) rem(20px);
    }
    .channel-panel.channel-panel--is-create & {
      padding: rem(20px) rem(16px) rem(20px);
    }
  }
}
