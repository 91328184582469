// 3beep variables
$white-0: #ffffff;
$black-0: #222222;
$gray-0: #f8f8f8;
$gray-1: #f3f3f5;
$gray-2: #e8e8e8;
$gray-3: #999999;
$gray-4: #626262;
$gray-5: #333333;
$gray-6: #545454;
$gray-7: #545465;
$purple-0: #f6f5ff;
$purple-1: #b4a7eb;
$purple-2: #947bfd;
$purple-3: #7757fe;
$purple-4: #e2dbff;
$flowkit-purple: #7b61ff;
$sky-0: #77aeff;
$green-0: #74c182;
$yellow-0: #ffe665;
$red-0: #ff5757;
$red-1: #ffedf1;
$red-2: #d73131;
$socials-default-message: #ebedfb;
$social-teleg: #6db7dc;
$social-teleg-hover: #54abd6;
$social-f_vk: #6da0dc;
$social-f_vk-hover: #528ccf;
$social-whats: #74c182;
$social-sms: #85c891;
$social-sms-hover: rgba(133, 200, 145, 0.75);
$social-email: #947bfd;
$social-email-hover: rgba(148, 123, 253, 0.75);
$social-whats-hover: #5ca96a;
$social-insta: #e788a0;
$social-insta-hover: #d4778f;
$default-z: 30;
$extensions: aep, apk, avi, css, dll, doc, eml, exe, gif, html, ico, iso, jar,
  jpg, jpeg, log, mov, mp3, mp4, otf, pdf, php, png, ppt, psd, rar, raw, rtf,
  sql, svg, tif, txt, xls, xml, zip;
$shadow-0: 0px 10px 28px rgba(0, 0, 0, 0.07);
$shadow-1: 0px 1px 1px rgba(2, 3, 3, 0.03), 0px 2px 1px rgba(2, 3, 3, 0.02),
  0px 1px 3px rgba(2, 3, 3, 0.04);
$shadow-2: 0px -4px 10px rgba(2, 3, 3, 0.03), 0px 3px 14px rgba(2, 3, 3, 0.02),
  0px 5px 5px rgba(2, 3, 3, 0.04);
$shadow-3: 0px 2px 2px rgba(2, 3, 3, 0.03), 0px 3px 1px rgba(2, 3, 3, 0.03),
  0px 1px 5px rgba(2, 3, 3, 0.04);
$shadow-4: 0px 3px 4px rgba(2, 3, 3, 0.03), 0px 3px 3px rgba(2, 3, 3, 0.02),
  0px 1px 8px rgba(2, 3, 3, 0.04);
$tablet: 769px;
$desktop: 1024px;
$widescreen: 1216px;
$fullhd: 1440px;
$ultrahd: 1920px;

:root {
  // Navbar variables
  --navbar-height: #{rem(70px)};
  --navbar-bc: #{$gray-0};
  --navbar-active-bc: #{$white-0};
  --navbar-item-gap: #{rem(6px)};

  // Toastify variables
  --toastify-color-light: #{$white-0};
  --toastify-color-dark: #{$black-0};
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba($white-0, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: auto;
  --toastify-toast-background: #{$white-0};
  --toastify-toast-min-height: 58px;
  --toastify-toast-max-height: 58px;
  --toastify-font-family: "Inter";
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #{$gray-1};

  //Used only for colored theme
  --toastify-text-color-info: #{$white-0};
  --toastify-text-color-success: #{$white-0};
  --toastify-text-color-warning: #{$white-0};
  --toastify-text-color-error: #{$white-0};

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

$out-of-range-color: #969696;
$out-of-range-bg-color: $gray-0;

$calendar-border: $gray-2;
$cell-border: $gray-2;

$current-time-color: white;

$rbc-css-prefix: rbc-i;

$today-highlight-bg: white;
