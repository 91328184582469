.operator-card__item {
  @extend %card-item;
  &#{&}--is-basis-auto {
    flex-basis: auto;
  }
  @for $i from 0 through 5 {
    &#{&}--is-flex-shrink-#{$i} {
      --card-item-flex-shrink: #{$i};
    }
    &#{&}--is-flex-grow-#{$i} {
      --card-item-flex-grow: #{$i};
    }
  }
  &#{&}--is-no-grow-widescreen {
    @include between($widescreen, $ultrahd) {
      --card-item-flex-grow: 0;
    }
  }
  &#{&}--is-basis-auto-widescreen {
    @include between($widescreen, $ultrahd) {
      flex-basis: auto;
    }
  }
  &#{&}--is-column-widescreen {
    @include between($widescreen, $ultrahd) {
      flex-direction: column;
    }
  }
  &#{&}--is-start-widescreen {
    @include between($widescreen, $ultrahd) {
      align-items: flex-start;
    }
  }
  @for $i from 0 through 10 {
    &#{&}--has-ml-#{$i}-widescreen {
      @include between($widescreen, $ultrahd) {
        margin-left: #{$i * 0.25rem};
      }
    }
  }
  @for $i from 0 through 10 {
    &#{&}--has-mr-#{$i}-widescreen {
      @include between($widescreen, $ultrahd) {
        margin-right: calc(#{$i * 0.25rem} - 5px) !important;
      }
    }
  }
  @for $i from 0 through 5 {
    @include from($ultrahd) {
      &#{&}--is-flex-grow-#{$i}-ultrahd {
        --card-item-flex-grow: #{$i};
      }
    }
  }
}
