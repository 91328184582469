.password-change-modal__header {
  @extend %title-lg-w-semi-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $black-0;
  margin-top: calc(var(--modal-padding) * -1);
  padding-top: rem(52px);
  padding-bottom: rem(51px);
}
