.operator-panel__title {
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 220px;
  font-weight: 500;
  font-size: rem(16px);
  line-height: 1.5;
  color: #2b2a35;
  margin-top: rem(10px);
}
