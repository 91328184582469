.calendar-event-content__description {
  font-size: rem(12px);
  font-weight: 400;
  line-height: 1.4;
  color: $gray-4;
  padding-left: rem(12px);
  .icon {
    margin-top: rem(6px);
  }
}
