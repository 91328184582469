@charset "UTF-8";

.toast-content {
  @extend %body-text-md-w-regular;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $white-0;
  b {
    font-weight: 600;
  }
}

@import "__button/toast-content__button";
