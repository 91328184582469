.bot-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(40px);
  height: rem(40px);
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  color: $gray-4;
  background-color: $gray-1;
}
