@charset "UTF-8";

.message {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  align-self: flex-end;
  padding: rem(12px) rem(16px);
  background-color: $socials-default-message;
  border-radius: 8px 8px 0px 8px;
  position: relative;
  & > .icon {
    position: absolute;
    left: -10px;
    top: -8px;
  }
  &#{&}--is-from-bot,
  &#{&}--is-from-client {
    align-self: flex-start;
    border-radius: 8px 8px 8px 0px;
  }
  &#{&}--is-from-bot {
    background-color: #e5fae9;
  }
  &#{&}--is-from-client {
    background-color: $gray-0;
    & > .icon {
      position: absolute;
      left: initial;
      right: -10px;
      top: -8px;
    }
  }
}

@import "__text/message__text";
@import "__time/message__time";
@import "__left/message__left";
@import "__right/message__right";
