.calendar {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  overflow: hidden;
  padding-top: rem(31px);
  padding-right: rem(50px);
  padding-bottom: rem(50px);
  padding-left: rem(50px);
}
