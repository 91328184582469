.messages-search__counter {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 400;
  font-size: rem(11px);
  line-height: 1.5;
  color: $gray-5;
  padding-left: rem(16px);
  padding-right: rem(16px - 1px);
  pointer-events: none;
  &#{&}--not-found {
    color: $gray-3;
  }
}
