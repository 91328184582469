.team-panel {
  padding: rem(50px) rem(16px) 0 rem(16px);
  background-color: $gray-0;
  display: flex;
  flex-direction: column;
}

@import "__header/team-panel__header";
@import "__title/team-panel__title";
@import "__body/team-panel__body";
@import "__footer/team-panel__footer";
