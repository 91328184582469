@charset "UTF-8";

.channel-panel {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  background-color: $gray-0;
  position: relative;
  overflow: hidden;
}

@import "__type-selector-tooltip/channel-panel__type-selector-tooltip";
@import "__type-selector/channel-panel__type-selector";
@import "__subtitle/channel-panel__subtitle";
@import "__title/channel-panel__title";
@import "__footer/channel-panel__footer";
@import "__header/channel-panel__header";
@import "__body/channel-panel__body";
@import "__avatar-wrapper/client-panel__avatar-wrapper";
