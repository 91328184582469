.progress {
  &#{&}--is-whatsapp {
    accent-color: #a4e0af;
    background-color: #a4e0af;
  }
  &#{&}--is-telegram {
    accent-color: #a1c4ee;
    background-color: #a1c4ee;
  }
  &#{&}--is-facebook {
    accent-color: #c3b5ff;
    background-color: #c3b5ff;
  }
  &#{&}--is-instagram {
    accent-color: #efaebe;
    background-color: #efaebe;
  }
  &#{&}--is-vk {
    accent-color: #6da0dc;
    background-color: #6da0dc;
  }
  &#{&}--is-email {
    accent-color: #947bfd;
    background-color: #947bfd;
  }
  &#{&}--is-sms {
    accent-color: #74c182;
    background-color: #74c182;
  }
  &#{&}--is-not-colored {
    accent-color: #a4e0af;
    background-color: #a4e0af;
  }
}
