.react-datepicker-popper {
  z-index: $default-z * 2;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: $purple-0;
}

.react-datepicker__navigation-icon--previous::before,
.react-datepicker__navigation-icon--next::before {
  border-color: white;
}
.react-datepicker__header {
  padding: rem(16px);
  background-color: white;
  color: #333333;
  border-bottom: 1px solid $gray-2;
}
.react-datepicker__current-month {
  font-family: "Inter";
  text-transform: capitalize;
  color: #333333;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.4;
}
.react-datepicker__day-name {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: -0.01em;
  color: #999999;
}
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-range {
  border-radius: rem(9999px);
}

.react-datepicker__day {
  display: flex;
  justify-content: center;
  align-items: center;
  width: rem(48px);
  height: rem(48px);
  border-radius: rem(9999px);
  &:hover {
    background-color: $gray-1;
    border-radius: rem(9999px);
  }
}

.react-datepicker__week {
  display: flex;
}

.react-datepicker__month-container {
  padding: rem(16px);
}

.react-datepicker {
  box-shadow: 0px 10px 28px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  border: none;
}

.react-datepicker__day-names {
  margin-top: rem(20px);
  display: flex;
  justify-content: space-between;
}

.react-datepicker__navigation {
  top: rem(27px);
}

.react-datepicker__navigation-icon {
  &::before {
    border-color: $gray-3;
  }
}

.react-datepicker__navigation {
  &:hover {
    .react-datepicker__navigation-icon {
      &::before {
        border-color: $gray-4;
      }
    }
  }
}

.datepicker__selected-day {
  font-weight: 600;
  font-size: rem(14px);
  line-height: 1.4;
  letter-spacing: -0.01em;
  color: $black-0;
}

.react-datepicker__day--range-start {
  .datepicker__selected-day {
    color: $white-0;
  }
  &:hover {
    background-color: $gray-5;
  }
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day--weekend {
  .datepicker__selected-day {
    color: $red-0;
  }
}

.react-datepicker__day--selecting-range-start,
.react-datepicker__day--range-start {
  border-radius: 9999px 0 0 9999px;
  background-color: #333333 !important;
  &:hover {
    border-radius: 9999px 0 0 9999px !important;
    background-color: #333333;
  }
  .datepicker__selected-day {
    color: $white-0;
  }
}

.react-datepicker__day--selecting-range-end,
.react-datepicker__day--range-end {
  border-radius: 0 9999px 9999px 0 !important;
  background-color: #333333 !important;
  &:hover {
    border-radius: 0 9999px 9999px 0 !important;
    background-color: #333333 !important;
  }
  .datepicker__selected-day {
    color: $white-0;
  }
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  &:not(.react-datepicker__day--selecting-range-start) {
    &:not(.react-datepicker__day--selecting-range-end) {
      &:not(.react-datepicker__day--range-end) {
        &:not(.react-datepicker__day--range-start) {
          border-radius: 0;
          margin-right: 0;
          margin-left: 0;
          background: #f3f3f5 !important;
        }
      }
    }
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  &:not(.react-datepicker__day--selecting-range-start) {
    &:not(.react-datepicker__day--selecting-range-end) {
      &:not(.react-datepicker__day--range-end) {
        &:not(.react-datepicker__day--range-start) {
          &:not(.react-datepicker__day--in-range) {
            background-color: #333333;

            .datepicker__selected-day {
              color: $white-0;
            }
          }
        }
      }
    }
  }
}

.react-datepicker__day {
  margin: 0;
  transition: all 0.1s;
}

.react-datepicker__day--disabled {
  background-color: $gray-0;
  .datepicker__selected-day {
    color: $gray-2 !important;
  }
  cursor: not-allowed;
  &:hover {
    background-color: $gray-0;
    .datepicker__selected-day {
      color: $gray-2 !important;
    }
  }
}

.react-datepicker__day--range-start.react-datepicker__day--range-end {
  border-radius: 9999px !important;
}
