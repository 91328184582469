.radio__indicator {
  width: rem(14px);
  height: rem(14px);
  background-color: $white-0;
  border: 1px solid $gray-3;
  border-radius: 9999px;
  cursor: pointer;
  padding: rem(3px);
  &:hover,
  &:active {
    border-color: $gray-5;
  }
  &::after {
    content: "";
    display: none;
    width: rem(6px);
    height: rem(6px);
    background-color: $gray-5;
    border-radius: 9999px;
  }
}
