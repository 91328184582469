.tabs__link {
  --tabs-link-pt: 0;
  --tabs-link-pb: #{rem(16px)};
  --tabs-link-px: #{rem(16px)};
  @extend %body-text-md-w-semi-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
  vertical-align: top;
  padding: var(--tabs-link-pt) var(--tabs-link-px) var(--tabs-link-pb);
  margin-bottom: calc(var(--tabs-border-bottom-width) * -1);
  border-bottom-width: var(--tabs-border-bottom-width);
  border-bottom-style: solid;
  border-bottom-color: var(--tabs-border-bottom-color);
  color: var(--tabs-link-color);
  &:hover {
    border-bottom-color: var(--tabs-link-active-border-bottom-color);
    color: var(--tabs-link-hover-color);
  }
  &#{&}--is-body-sm {
    @extend %body-text-sm-w-semi-bold;
  }
  &#{&}--is-body-md {
    @extend %body-text-md-w-semi-bold;
  }
  &#{&}--is-body-lg {
    @extend %body-text-lg-w-semi-bold;
  }
  @for $i from 0 through 30 {
    &#{&}--has-pt-#{$i} {
      --tabs-link-pt: #{$i * 0.125rem};
    }
    &#{&}--has-pb-#{$i} {
      --tabs-link-pb: #{$i * 0.125rem};
    }
    &#{&}--has-px-#{$i} {
      --tabs-link-px: #{$i * 0.125rem};
    }
  }
}
