.client-comments__textarea {
  font-weight: 400;
  font-size: rem(13px);
  line-height: 1.5;
  color: #2b2a35;
  width: 100%;
  resize: none;
  padding: 1rem;
  border: none;
  outline: none;
  border-radius: 8px;
  @include scrollbar;
}
