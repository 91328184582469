.node-team {
  @extend %body-text-lg-w-semi-bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: rem(8px) rem(20px);
  border-radius: 6px;
  color: $white-0;
  background-color: $social-whats-hover;
  &:not(:last-child) {
    margin-right: rem(8px);
  }
}
