.team-panel__title {
  font-weight: 500;
  font-size: rem(16px);
  line-height: 1.5;
  color: $black-0;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 220px;
  &:not(:only-child) {
    margin-bottom: rem(20px);
  }
}
