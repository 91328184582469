.multistep-select__tabs {
  margin-bottom: rem(20px);
  &#{&}--is-second-tab {
    margin-bottom: rem(10px);
  }
  & > .tabs__list {
    .tabs__item:not(.tabs__item--is-active) {
      a {
        color: $gray-4;
        &:hover {
          color: $black-0;
        }
      }
    }
  }
}
