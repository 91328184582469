.rbc-month-view {
  border-radius: 8px;
}

.rbc-time-content {
  border-top-width: 1px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.rbc-header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: rem(13px);
  font-weight: 700;
  line-height: normal;
  color: $gray-5;
  text-align: center;
  text-transform: uppercase;
  padding: rem(12px);
}

.rbc-row-segment {
  padding-left: rem(12px);
  padding-right: rem(12px);
  padding-bottom: rem(4px);
  @at-root {
    .rbc-time-view .rbc-row:first-child & {
      padding-top: rem(4px);
    }
  }
}

.rbc-date-cell {
  text-align: left;
  padding-left: rem(12px);
  padding-right: rem(12px);
  padding-top: rem(12px);
  padding-bottom: rem(12px);
}

.rbc-timeslot-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rbc-time-slot {
  flex-grow: 0;
  flex-shrink: 0;
}

.rbc-label {
  font-size: rem(13px);
  font-weight: 700;
  line-height: normal;
  color: $gray-5;
  padding-left: rem(12px);
  padding-right: rem(12px);
}

.rbc-current-time-indicator {
  display: none;
}

.rbc-button-link {
  position: relative;
  &::after {
    display: none;
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    background-color: rgba($purple-2, 0.3);
    width: 100%;
    height: 2px;
    border-radius: 4px;
  }
  $this: &;
  @at-root {
    .rbc-header {
      #{$this} {
        font-size: rem(13px);
        font-weight: 700;
        line-height: normal;
        color: $gray-5;
        text-align: center;
        text-transform: uppercase;
      }
      &.rbc-today {
        #{$this} {
          color: $purple-2;
        }
      }
    }
    .rbc-date-cell {
      #{$this} {
        font-size: rem(15px);
        font-weight: 500;
        line-height: normal;
        color: #969696;
      }
      &.rbc-now {
        #{$this} {
          color: $purple-2;
          &::after {
            display: block;
          }
        }
      }
    }
  }
}

.rbc-show-more {
  font-size: rem(12px);
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: -0.01em;
  color: $purple-2;
  &:hover,
  &:focus {
    color: $purple-3;
  }
}

.rbc-event {
  $this: &;
  border: none;
  border-radius: 4px;
  padding: rem(5px) rem(9px);
  font-size: rem(12px);
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: -0.01em;
  color: $purple-2;
  background-color: $purple-0;
  &.rbc-selected {
    background-color: $purple-0;
  }
  &:hover,
  &:focus {
    color: $purple-0;
    background-color: $purple-2;
    outline: none;
    &.rbc-selected {
      background-color: $purple-2;
    }
  }

  @at-root {
    .rbc-day-slot {
      #{$this} {
        margin-top: -1px;
        min-height: 41px;
        border-width: 1px;
        border-style: solid;
        border-color: rgba($purple-2, 0.2);
        #{$this}.rbc-selected {
          background-color: $purple-0;
        }

        #{$this}:hover,
        #{$this}:focus {
          border-color: rgba($white-0, 0.5);
          #{$this}.rbc-selected {
            background-color: $purple-2;
          }
        }
      }
    }
  }
}

.rbc-event-label {
  $this: &;
  font-size: rem(12px);
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: -0.01em;
  color: $purple-3;
  @at-root {
    .rbc-day-slot {
      #{$this} {
        padding-right: rem(4px);
      }
    }
    .rbc-event {
      &.rbc-selected {
        #{$this} {
          color: $purple-3;
        }
      }
      &:hover,
      &:focus {
        #{$this} {
          color: $white-0;
        }
        &.rbc-selected {
          #{$this} {
            color: $white-0;
          }
        }
      }
    }
  }
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:first-child {
  top: -4px;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:last-child {
  bottom: -4px;
}

.rbc-addons-dnd
  .rbc-addons-dnd-resize-ns-anchor
  .rbc-addons-dnd-resize-ns-icon {
  display: block;
  width: 100%;
  border-top: 4px solid transparent;
}

.rbc-addons-dnd
  .rbc-addons-dnd-resize-ew-anchor
  .rbc-addons-dnd-resize-ew-icon {
  display: none !important;
}
