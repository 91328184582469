.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100vh;
  z-index: $default-z;
  &--has-transparency-0 {
    background-color: rgba($gray-6, 0);
  }
  &--has-transparency-10 {
    background-color: rgba($gray-6, 0.1);
  }
  &--has-transparency-30 {
    background-color: rgba($gray-6, 0.3);
  }
}
