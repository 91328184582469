@charset "UTF-8";

.messages-search {
  display: block;
  border-radius: 8px;
  box-shadow: $shadow-2;
  background-color: $white-0;
  border: 1px solid $gray-3;
  .control {
    &:not(:last-child) {
      border-right: 1px solid $gray-2;
    }
  }
  .icon {
    transition: background-color 100ms;
    border-radius: 9999px;
    color: $gray-5;
    outline: none;
    &.icon--is-disabled {
      color: $gray-3;
      cursor: default;
    }
    &:not(.icon--is-disabled) {
      &:hover {
        background-color: $gray-1;
      }
    }
  }
}

@import "__counter/messages-search__counter";
@import "__input/messages-search__input";
@import "__control/messages-search__control";
