.tabs__list {
  --tabs-list-px: 0;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 0;
  border-bottom-style: solid;
  border-bottom-width: var(--tabs-border-bottom-width);
  border-bottom-color: var(--tabs-border-bottom-color);
  padding-left: var(--tabs-list-px);
  padding-right: var(--tabs-list-px);
  &#{&}--is-centered {
    justify-content: center;
  }
  @for $i from 0 through 30 {
    &#{&}--has-px-#{$i} {
      --tabs-list-px: #{$i * 0.125rem};
    }
  }
}
