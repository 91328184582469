.node__text {
  @extend %body-text-lg-w-regular;
  @extend %collapsable-text;
  display: block;
  padding: rem(16px);
  color: $black-0;
  background-color: $gray-0;
  border-radius: 6px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  &#{&}--is-gray-4 {
    color: $gray-4;
  }
  &#{&}--is-integration {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white-0;
    border-radius: rem(8px);
    padding: rem(17px) rem(40px) rem(18px) rem(40px);
    font-weight: 500;
    font-size: rem(12px);
    line-height: 1.4;
    color: $gray-4;
    &#{&}-action {
      justify-content: flex-start;
      background-color: $white-0;
      border: 1px solid $gray-2;
      padding: rem(17px) rem(22px) rem(18px) rem(22px);
      &#{&}-error {
        .icon-text {
          width: 100%;
        }
        border-color: $red-0;
      }
    }
  }
}
