.files-upload-modal__header {
  @extend %title-lg-w-semi-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $black-0;
  margin-top: calc(var(--modal-padding) * -1);
  margin-right: calc(var(--modal-padding) * -1);
  margin-left: calc(var(--modal-padding) * -1);
  padding-top: rem(56px);
  padding-right: rem(55px);
  padding-bottom: rem(40px);
  padding-left: rem(55px);
}
