@charset "UTF-8";

.language-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  vertical-align: top;
  overflow: hidden;
  padding: rem(27.5px);
  font-weight: 400;
  font-size: rem(12px);
  line-height: 1.4;
  color: $black-0;
  .language-selector__button {
    flex-grow: 0;
    flex-shrink: 0;
    &:not(:last-child) {
      margin-right: rem(8px);
    }
    &:not(:first-child) {
      margin-left: rem(8px);
    }
  }
  span:not([class]) {
    @extend %collapsable-text;
  }
}

@import "__button/language-selector__button";
