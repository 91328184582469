.icon-text {
  $this: &;
  --icons-gap: #{rem(8px)};
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  color: inherit;
  flex-wrap: nowrap;
  vertical-align: top;
  overflow: hidden;
  span:not([class]) {
    @extend %collapsable-text;
  }
  &#{&}--is-column {
    flex-direction: column;
  }
  &#{&}--is-visible {
    overflow: visible;
  }
  &#{&}--spaced-between {
    justify-content: space-between;
  }
  &#{&}--is-centered {
    justify-content: center;
  }
  &#{&}--is-vstart {
    align-items: start;
  }
  &:not(&--is-gapless) {
    .icon {
      flex-grow: 0;
      flex-shrink: 0;
      &:not(:last-child) {
        margin-right: var(--icons-gap);
      }
      &:not(:first-child) {
        margin-left: var(--icons-gap);
      }
    }
    &#{$this}--has-space-around {
      &:not(:last-child) {
        margin-right: var(--icons-gap);
      }
      &:not(:first-child) {
        margin-left: var(--icons-gap);
      }
    }
    @for $i from 0 through 20 {
      &#{$this}--has-gap-#{$i} {
        --icons-gap: #{$i * 0.125rem};
      }
    }
  }
  &#{&}--is-gapless {
    --icons-gap: 0;
  }
  @at-root {
    div#{&} {
      display: flex;
    }
  }
}
