.bot-card__details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  span:not([class]) {
    @extend %collapsable-text;
    display: block;
    font-weight: 300;
    font-size: rem(11px);
    line-height: 1.5;
    color: $gray-4;
    max-width: 100%;
  }
  .icon-text:not(:last-child) {
    margin-bottom: rem(4px);
  }
}
