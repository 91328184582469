.tabs__item {
  $this: &;
  display: flex;
  &#{&}--is-active {
    .tabs__link {
      border-bottom-color: var(--tabs-link-active-border-bottom-color);
      color: var(--tabs-link-active-color);
    }
  }
  &#{&}--is-disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
  @at-root {
    .tabs__list.tabs__list--is-fullwidth {
      #{$this}#{$this}--is-scalable {
        flex-grow: 1;
        flex-shrink: 0;
      }
    }
  }
}
