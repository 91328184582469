.avatar-group {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  .avatar {
    border-width: 2px;
    border-style: solid;
    border-color: $white-0;
    &:not(:first-child) {
      margin-inline-start: -8px;
    }
  }
  &#{&}--has-badge {
    &::after {
      content: "+" attr(data-count);
      font-weight: 600;
      font-size: rem(12px);
      line-height: 1.4;
      color: $gray-5;
      margin-left: rem(10px);
    }
  }
}
