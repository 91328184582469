.client-panel {
  display: flex;
  flex-direction: column;
  border-left: 1px solid $gray-2;
  margin-left: -1px;
  background-color: $gray-0;
}

@import "__text/client-panel__text";
@import "__title/client-panel__title";
@import "__tab-panel/client-panel__tab-panel";
@import "__tabs/client-panel__tabs";
@import "__header/client-panel__header";
@import "__body/client-panel__body";
