.field__label {
  --opacity: 1;
  font-weight: 600;
  font-size: rem(13px);
  line-height: 1.5;
  color: rgba($black-0, var(--opacity));
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  text-align: left;
  user-select: none;
  &#{&}--is-gray-5 {
    color: rgba($gray-5, var(--opacity));
  }
  @for $i from 0 through 10 {
    &#{&}--has-opacity-#{$i} {
      --opacity: #{$i * 0.1};
    }
  }
  @at-root {
    .field.field--is-direction-row & {
      &:first-child {
        margin-right: rem(16px);
      }
      &:last-child {
        margin-left: rem(16px);
      }
    }
    .field.field--is-direction-column & {
      margin-left: rem(16px);
      margin-right: rem(16px);
      margin-bottom: rem(2px);
    }
  }
}
