.client-dropdown__status {
  display: block;
  border-radius: 20px;
  padding: rem(2px) rem(10px);
  font-weight: 400;
  font-size: rem(11px);
  line-height: 1.5;
  color: $black-0;
  &#{&}--is-completed {
    background-color: $gray-1;
  }
  &#{&}--is-non-accepted,
  &#{&}--is-accepted {
    background-color: #e5fae9;
  }
}
