.clients-container {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: 1fr rem(380px);
  grid-auto-columns: auto;
  grid-auto-rows: auto;
  gap: rem(48px);
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;

  @include between($widescreen, $ultrahd) {
    grid-template-columns: 1fr rem(360px);
    gap: rem(40px);
  }
}
