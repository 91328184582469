.user-dropdown__menu {
  min-width: rem(260px);
  padding-top: calc(10px - var(--button-border-width));
  padding-bottom: calc(10px - var(--button-border-width));
  &#{&}--is-expanded {
    min-width: rem(380px);
    min-height: 100vh;
    padding-bottom: 0;
    padding-top: 0;
  }
}
