.node-teams {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  border: 1px solid $gray-2;
  padding: rem(8px - 1px);
  overflow: hidden;
  overflow-x: overlay;
  @include scrollbar;
  &#{&}--is-empty {
    @extend %body-text-md-w-regular;
    color: $gray-4;
    padding: rem(17px - 1px) rem(16px - 1px);
  }
}
