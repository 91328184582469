.dashboard {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
@import "__header/dashboard__header";
@import "__body/dashboard__body";
@import "__title-container/dashboard__title-container";
@import "__title/dashboard__title";
@import "__bar/dashboard__bar";
@import "__graph/dashboard__graph";
@import "__container/dashboard__container";
@import "__controls/dashboard__controls";
@import "__refresh-button/dashboard__refresh-button";
