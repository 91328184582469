@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes poll {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
  100% {
    content: "";
  }
}

.fade {
  &-enter {
    opacity: 0;
  }
  &-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  &-exit {
    opacity: 1;
  }
  &-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
}

.slide-from-top {
  &-enter {
    transform: translateY(-100%);
  }
  &-enter-active {
    transform: translateY(0%);
    transition: transform 200ms;
  }
  &-exit {
    transform: translateY(0%);
  }
  &-exit-active {
    transform: translateY(-100%);
    transition: transform 200ms;
  }
}

.slide-from-right {
  &-enter {
    transform: translateX(100%);
  }
  &-enter-active {
    transform: translateX(0%);
    transition: transform 200ms;
  }
  &-exit {
    transform: translateX(0%);
  }
  &-exit-active {
    transform: translateX(100%);
    transition: transform 200ms;
  }
}

.slide-from-bottom {
  &-enter {
    transform: translateY(100%);
  }
  &-enter-active {
    transform: translateY(0%);
    transition: transform 200ms;
  }
  &-exit {
    transform: translateY(0%);
  }
  &-exit-active {
    transform: translateY(100%);
    transition: transform 200ms;
  }
}

.slide-from-left {
  &-enter {
    transform: translateX(-100%);
  }
  &-enter-active {
    transform: translateX(0%);
    transition: transform 200ms;
  }
  &-exit {
    transform: translateX(0%);
  }
  &-exit-active {
    transform: translateX(-100%);
    transition: transform 200ms;
  }
}

.fade-and-slide-from-top {
  &-enter {
    opacity: 0;
    transform: translateY(-100%);
  }
  &-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 200ms, transform 200ms;
  }
  &-exit {
    opacity: 1;
    transform: translateY(0%);
  }
  &-exit-active {
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity 200ms, transform 200ms;
  }
}

.fade-and-slide-from-right {
  &-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  &-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 200ms, transform 200ms;
  }
  &-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  &-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 200ms, transform 200ms;
  }
}

.fade-and-slide-from-bottom {
  &-enter {
    opacity: 0;
    transform: translateY(100%);
  }
  &-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 200ms, transform 200ms;
  }
  &-exit {
    opacity: 1;
    transform: translateY(0%);
  }
  &-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 200ms, transform 200ms;
  }
}

.fade-and-slide-from-left {
  &-enter {
    opacity: 0;
    transform: translateX(-100%);
  }
  &-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 200ms, transform 200ms;
  }
  &-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  &-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 200ms, transform 200ms;
  }
}

.scale {
  &-enter {
    transform: scale(0);
  }
  &-enter-active {
    transform: scale(1);
    transition: transform 200ms;
  }
  &-exit {
    transform: scale(1);
  }
  &-exit-active {
    transform: scale(0);
    transition: transform 200ms;
  }
}
