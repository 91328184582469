.flow-tooltip__badge {
  @extend %body-text-md-w-regular;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: rem(10px - 2px);
  background-color: $gray-5;
  border: 2px solid $gray-5;
  border-radius: 6px;
  &:first-child:last-child {
    margin-left: rem(-8px);
    margin-right: rem(-8px);
  }
  &:first-child:not(:last-child) {
    margin-left: rem(-8px);
    margin-right: rem(8px);
  }
  &:last-child:not(:first-child) {
    margin-left: rem(8px);
    margin-right: rem(-8px);
  }
}
