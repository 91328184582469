@charset "UTF-8";

.bot-control {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  padding-top: rem(25px);
  padding-right: var(--bot-control--px);
  padding-left: var(--bot-control--px);
}

@import "__title/bot-control__title";
@import "__header/bot-control__header";
@import "__body/bot-control__body";
