.channel-panel__header {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @at-root {
    .channel-panel.channel-panel--is-update &,
    .channel-panel.channel-panel--is-reconnect &,
    .channel-panel.channel-panel--is-delete & {
      border-bottom: 1px solid $gray-2;
    }
    .channel-panel.channel-panel--is-update &,
    .channel-panel.channel-panel--is-delete & {
      padding: rem(39px) rem(16px) rem(50px - 1px) rem(16px);
    }
    .channel-panel.channel-panel--is-reconnect & {
      padding: rem(39px) rem(16px) rem(45px - 1px) rem(16px);
    }
    .channel-panel.channel-panel--is-create & {
      padding: rem(39px) rem(16px) rem(40px) rem(16px);
    }
  }
}
