.table__title {
  @extend %title-md-w-semi-bold;
  color: $black-0;
  margin-bottom: rem(8px);
  &#{&}--is-empty {
    color: $gray-3;
  }
  &#{&}--is-error {
    color: $red-0;
  }
}
