@charset "UTF-8";

.bot-card {
  @extend %card;
  --card-border-bottom-width: 0px;
  --card-py: #{rem(30px - 1px)};
  --card-px: #{rem(40px - 1px)};
  --card-h: #{rem(120px)};
  --card-background-color: #{$white-0};
  border: 1px solid $gray-2;
  border-radius: 8px;

  &#{&}--is-focused,
  &:hover {
    border-color: $gray-0;
    background-color: $gray-0;
  }
}

@import "__tooltip/bot-card__tooltip";
@import "__status/bot-card__status";
@import "__details/bot-card__details";
@import "__relative-date/bot-card__relative-date";
@import "__name/bot-card__name";
@import "__info/bot-card__info";
@import "__item/bot-card__item";
@import "__left/bot-card__left";
@import "__right/bot-card__right";
@import "__sessions-count/bot-card__sessions-count";
@import "__status-container/bot-card__status-container";
@import "__delete-description/bot-card__delete-description";
@import "__connect-button/bot-card__connect-button";
