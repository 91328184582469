.calendar-event-participant-dropdown__item {
  $this: &;
  @at-root {
    a#{&} {
      @extend %body-text-md-w-semi-bold;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: transparent;
      padding-top: rem(12px - 1px);
      padding-right: rem(12px);
      padding-bottom: rem(12px - 1px);
      padding-left: rem(12px);
      background-color: $white-0;
      color: $gray-5;
      &:not(:last-child) {
        border-bottom-color: $gray-1;
      }
      &:hover,
      &#{$this}--is-selected {
        background-color: $gray-1;
      }
      &#{$this}--is-selected {
        cursor: default;
        pointer-events: none;
      }
      span:not([class]) {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
