.third-party-create__button {
  border-radius: 999px;
  background-color: white;
  width: rem(60px);
  height: rem(60px);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
