.operator-card {
  @extend %card;
  &:hover {
    background-color: $gray-0;
  }
  .operator-card__item:not(:last-child) {
    margin-right: rem(46px);
  }
  @include between($widescreen, $ultrahd) {
    .operator-card__item:not(:last-child) {
      margin-right: 0;
    }
  }
  &#{&}--is-selected {
    background-color: $gray-1;
  }
}

@import "__right/operator-card__right";
@import "__left/operator-card__left";
@import "__item/operator-card__item";
@import "__name/operator-card__name";
@import "__email/operator-card__email";
@import "__teams/operators-card__teams";
@import "__team/operator-card__team";
@import "__no-teams/operator-card__no-teams";
@import "__badge/operator-card__badge";
