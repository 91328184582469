.table__sort-icons {
  display: flex;
  flex-direction: column;
  & > div {
    cursor: pointer;
    &:first-child {
      transform: rotate(180deg);
    }
  }

  & > .table__sort-icon--is-active {
    color: $gray-5;
  }
}
