.main-menu__notification-badge {
  width: rem(6px);
  height: rem(6px);
  border-radius: 9999px;
  background-color: $purple-3;
  position: absolute;
  right: rem(8px);
  top: rem(10px);
  box-shadow: 0px 0px 0px 0px rgba(119, 87, 254, 0.3);
  animation: shadow-shrink 1s infinite;
}

@keyframes shadow-shrink {
  50% {
    box-shadow: 0px 0px 0px 4px rgba(119, 87, 254, 0.3);
  }
}
