.settings-menu__label {
  @extend %title-lg-w-semi-bold;
  color: $black-0;
  &:not(:last-child) {
    margin-bottom: rem(30px);
  }
  &:not(:first-child) {
    margin-top: rem(30px);
  }
}
