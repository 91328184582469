.input__email-mask {
  color: $gray-3;
  font-size: rem(12px);
  background-color: white;
  height: 100%;
  border-radius: em(8px, 14px);
  display: flex;
  align-items: center;
  padding-right: rem(16px);
}
