@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin until($device) {
  @media screen and (max-width: $device - 1px) {
    @content;
  }
}

@mixin between($from, $until) {
  @media screen and (min-width: $from) and (max-width: $until - 1px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet), print {
    @content;
  }
}

@mixin touch {
  @media screen and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin until-widescreen {
  @media screen and (max-width: $widescreen - 1px) {
    @content;
  }
}

@mixin widescreen {
  @media screen and (min-width: $widescreen) {
    @content;
  }
}

@mixin until-widescreen {
  @media screen and (max-width: $fullhd - 1px) {
    @content;
  }
}

@mixin fullhd {
  @media screen and (min-width: $fullhd) {
    @content;
  }
}
