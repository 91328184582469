.channel-panel__type-selector {
  $this: &;
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(40px);
  height: rem(40px);
  padding: rem(11px);
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  background-color: $white-0;
  color: $gray-4;
  cursor: pointer;
  &#{&}--is-selected {
    &#{$this}--is-instagram {
      color: $white-0;
      background-color: $social-insta;
    }
    &#{$this}--is-messenger {
      color: $white-0;
      background-color: $social-f_vk;
    }
    &#{$this}--is-telegram {
      color: $white-0;
      background-color: $social-teleg;
    }
    &#{$this}--is-vk {
      color: $white-0;
      background-color: $social-f_vk;
    }
    &#{$this}--is-whatsapp {
      color: $white-0;
      background-color: $social-whats;
    }
    &#{$this}--is-sms {
      color: $white-0;
      background-color: $social-sms;
    }
    &#{$this}--is-email {
      color: $white-0;
      background-color: $social-email;
    }
  }
}
