.bot-builder__body {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  overflow: hidden;
  transition: padding 200ms;
  &--has-sidebar-left {
    padding-left: var(--flows-panel-width);
  }
}
