.dashboard-blocks__item {
  display: block;
  flex-grow: 1;
  flex-shrink: 0;
  padding: var(--dashboard-blocks-offset-y) var(--dashboard-blocks-offset-x);
  @include scrollbar();
  &#{&}--is-eighth {
    width: 12.5%;
  }
  &#{&}--no-padding-y {
    padding-bottom: 0;
    padding-top: 0;
  }
  &#{&}--is-flex {
    display: flex;
  }
  &#{&}--is-one-fifth {
    width: 20%;
  }
  &#{&}--is-one-half {
    width: 50%;
  }
  &#{&}--is-w-comparison-bars {
    width: 42%;
  }
  &#{&}--is-w-chats-line-chart {
    width: 57%;
  }
  &#{&}--is-h-full {
    height: 100%;
  }
  &#{&}--is-w-full {
    width: 100%;
  }
  &#{&}--is-narrow {
    flex-basis: 0;
    flex-grow: 0;
  }
  &#{&}--no-grow {
    flex-grow: 0;
  }
  &#{&}--is-overflow-x-auto {
    overflow-x: auto;
    overflow-y: hidden;
  }
  &#{&}--is-one-half-widescreen {
    @include between($widescreen, $ultrahd) {
      width: 100%;
    }
  }
}
