.channel-connect-modal__bot-connection {
  font-weight: 500;
  font-size: rem(11px);
  line-height: 1.5;
  color: $purple-3;
  text-transform: lowercase;
  user-select: none;
  margin-left: auto;
  .icon {
    color: $purple-3;
  }
}
