@charset "UTF-8";

.saving-dropdown {
  width: 100%;
  position: relative;
}

@import "__item/saving-dropdown__item";
@import "__content/saving-dropdown__content";
@import "__menu/saving-dropdown__menu";
