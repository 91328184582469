.clients-statistic-container {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: rem(635px) 1fr;
  grid-auto-columns: auto;
  grid-auto-rows: auto;
  gap: rem(63px);
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  padding-left: rem(48px);
  @include between($widescreen, $ultrahd) {
    gap: rem(40px);
    grid-template-columns: rem(259px) 1fr;
    padding-left: rem(40px);
  }
}
