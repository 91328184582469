/* Body/Body 14 Bold */
%body-text-lg-w-bold {
  font-weight: 700;
  font-size: rem(14px);
  line-height: 1.4;
  letter-spacing: -0.01em;
}
/* Body/Body 14 Semi */
%body-text-lg-w-semi-bold {
  font-weight: 600;
  font-size: rem(14px);
  line-height: 1.4;
  letter-spacing: -0.01em;
}
/* Body/Body 14 Medium */
%body-text-lg-w-medium {
  font-weight: 500;
  font-size: rem(14px);
  line-height: 1.4;
  letter-spacing: -0.01em;
}
/* Body/Body 14 Regular */
%body-text-lg-w-regular {
  font-weight: 400;
  font-size: rem(14px);
  line-height: 1.4;
  letter-spacing: -0.01em;
}
/* Body/Body 13 Bold */
%body-text-md-w-bold {
  font-weight: 700;
  font-size: rem(13px);
  line-height: 1.4;
  letter-spacing: -0.01em;
}
/* Body/Body 13 Semi */
%body-text-md-w-semi-bold {
  font-weight: 600;
  font-size: rem(13px);
  line-height: 1.4;
  letter-spacing: -0.01em;
}
/* Body/Body 13 Medium */
%body-text-md-w-medium {
  font-weight: 500;
  font-size: rem(13px);
  line-height: 1.4;
  letter-spacing: -0.01em;
}
/* Body/Body 13 Regular */
%body-text-md-w-regular {
  font-weight: 400;
  font-size: rem(13px);
  line-height: 1.4;
  letter-spacing: -0.01em;
}
/* Body/Body 12 Bold */
%body-text-sm-w-bold {
  font-weight: 700;
  font-size: rem(12px);
  line-height: 1.3;
  letter-spacing: -0.01em;
}
/* Body/Body 12 Semi */
%body-text-sm-w-semi-bold {
  font-weight: 600;
  font-size: rem(12px);
  line-height: 1.3;
  letter-spacing: -0.01em;
}
/* Body/Body 12 Medium */
%body-text-sm-w-medium {
  font-weight: 500;
  font-size: rem(12px);
  line-height: 1.3;
  letter-spacing: -0.01em;
}
/* Body/Body 12 Regular */
%body-text-sm-w-regular {
  font-weight: 400;
  font-size: rem(12px);
  line-height: 1.3;
  letter-spacing: -0.01em;
}
