.chat-room-input__button {
  @at-root {
    a#{&},
    button#{&},
    label#{&} {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      appearance: none;
      border: none;
      border-radius: em(8px);
      box-shadow: none;
      font-size: rem(16px);
      color: $gray-3;
      position: relative;
      vertical-align: top;
      white-space: nowrap;
      width: rem(30px);
      height: rem(30px);
      background-color: transparent;
      transition: color 100ms;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: rem(10px);
      }
      &:hover,
      &#{&}--is-active {
        color: $black-0;
      }
    }
    label#{&} {
      input {
        display: none;
      }
    }
  }
  &#{&}--is-active {
    color: $black-0;
  }
}
