.switch__input {
  $slider: ".switch__slider";
  $labels: ".switch__labels";
  cursor: pointer;
  display: none;

  &:checked ~ #{$slider} {
    background-color: $purple-2;
    &::before {
      transform: translateX(32px);
    }
  }
  &:checked ~ #{$labels} {
    &::after {
      opacity: 0;
    }
    &::before {
      opacity: 1;
    }
  }
}
