@charset "UTF-8";

.account-settings {
  display: flex;
  flex-direction: column;
  background-color: $white-0;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  border-right: 1px solid $gray-2;
  margin-right: -1px;
}

@import "__title/account-settings__title";
@import "__close-button/account-settings__close-button";
@import "__fields/account-settings__info-wrapper";
@import "__header/account-settings__header";
@import "__body/account-settings__body";
@import "__footer/account-settings__footer";
