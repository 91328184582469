@charset "UTF-8";

.chats-assignee {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $black-0;
  border-radius: 6px;
  background-color: $white-0;
  user-select: none;
  cursor: pointer;
  transition: background-color 100ms;
  &:hover {
    background-color: $gray-1;
  }
  &#{&}--is-team {
    @extend %body-text-md-w-semi-bold;
    padding: rem(8px) rem(10px);
    &:not(:last-child) {
      margin-bottom: rem(8px);
    }
  }
  &#{&}--is-user {
    @extend %body-text-md-w-regular;
    padding: rem(8px) rem(10px) rem(8px) rem(20px);
    &:not(:last-child) {
      margin-bottom: rem(4px);
    }

    & + & {
      margin-bottom: rem(10px);
    }
  }
}
