.calendar-event-content__close-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  border: none;
  box-shadow: none;
  vertical-align: top;
  white-space: nowrap;
  background-color: transparent;
  color: $gray-7;
  width: rem(36px);
  height: rem(36px);
  padding: 0;
  cursor: pointer;
  .icon {
    width: rem(15px);
    height: rem(15px);
  }
}
