.card-tooltip__row {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(2, auto);
  grid-auto-columns: auto;
  grid-auto-rows: auto;
  gap: rem(16px);
  align-items: center;
  justify-content: space-between;
}
