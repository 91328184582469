@charset "UTF-8";

.channel-card {
  $this: &;
  @extend %card;
  .channel-card__item:not(:last-child) {
    margin-right: rem(16px);
  }
  &:hover:not(#{&}--is-selected) {
    background-color: $gray-0;
  }
  &#{&}--is-selected {
    background-color: $gray-1;
  }
  &#{&}--is-instagram {
    color: $social-insta;
    &#{$this}--is-deleting {
      color: rgba($social-insta, 0.5);
    }
  }
  &#{&}--is-messenger {
    color: $social-f_vk;
    &#{$this}--is-deleting {
      color: rgba($social-f_vk, 0.5);
    }
  }
  &#{&}--is-telegram {
    color: $social-teleg;
    &#{$this}--is-deleting {
      color: rgba($social-teleg, 0.5);
    }
  }
  &#{&}--is-vk {
    color: $social-f_vk;
    &#{$this}--is-deleting {
      color: rgba($social-f_vk, 0.5);
    }
  }
  &#{&}--is-whatsapp {
    color: $social-whats;
    &#{$this}--is-deleting {
      color: rgba($social-whats, 0.5);
    }
  }
  &#{&}--is-sms {
    color: $social-sms;
    &#{$this}--is-deleting {
      color: rgba($social-sms, 0.5);
    }
  }
  &#{&}--is-email {
    color: $social-email;
    &#{$this}--is-deleting {
      color: rgba($social-email, 0.5);
    }
  }
}

@import "__bot/channel-card__bot";
@import "__status/channel-card__status";
@import "__name/channel-card__name";
@import "__link/channel-card__link";
@import "__info/channel-card__info";
@import "__item/channel-card__item";
@import "__left/channel-card__left";
@import "__right/channel-card__right";
