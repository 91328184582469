.flow-testing-panel__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  overflow: hidden;
  &:not(:last-child) {
    margin-bottom: 40px;
  }
}
