.message-type-change-modal__footer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  text-align: right;
  margin-right: calc(var(--modal-padding) * -1);
  margin-bottom: calc(var(--modal-padding) * -1);
  margin-left: calc(var(--modal-padding) * -1);
  padding-top: rem(20px);
  padding-right: rem(40px);
  padding-bottom: rem(40px);
  padding-left: rem(40px);
}
