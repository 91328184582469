.chats-tabs__badge {
  padding: rem(1px) rem(5px);
  background-color: $purple-0;
  color: $purple-2;
  border-radius: 22px;
  position: absolute;
  top: -50%;
  margin-left: rem(1.5px);
  font-size: rem(10px);
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
}
