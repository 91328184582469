.node-settings__image {
  display: inline-flex;
  border-radius: 8px;
  width: auto;
  height: rem(200px);
  position: relative;
  overflow: hidden;
  .icon-text,
  &::before {
    position: absolute;
    opacity: 0;
    z-index: 1;
    transition: opacity 200ms;
    visibility: hidden;
  }
  .icon-text {
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .icon {
    width: rem(40px);
    height: rem(40px);
    border-radius: 9999px;
    background-color: $white-0;
  }
  &::before {
    content: "";
    border-radius: inherit;
    background-image: linear-gradient(
      0deg,
      rgba(51, 51, 51, 0.3),
      rgba(51, 51, 51, 0.3)
    );
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: opacity 200ms;
  }
  &:hover {
    &::before,
    .icon-text {
      opacity: 1;
      visibility: visible;
    }
  }
}
