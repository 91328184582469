.chat-card__notification-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(6px);
  height: rem(6px);
  margin: 0 3px auto;
  border-radius: 9999px;
  background-color: $purple-3;
}
