.modal__close-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  border: none;
  box-shadow: none;
  vertical-align: top;
  white-space: nowrap;
  background-color: transparent;
  color: $gray-7;
  position: absolute;
  top: calc(
    var(--close-button-offset) -
      ((var(--close-button-size) - var(--icon-size)) / 2)
  );
  right: calc(
    var(--close-button-offset) -
      ((var(--close-button-size) - var(--icon-size)) / 2)
  );
  width: var(--close-button-size);
  height: var(--close-button-size);
  padding: 0;
  z-index: 1;
  cursor: pointer;
  .icon {
    width: var(--icon-size);
    height: var(--icon-size);
  }
}
