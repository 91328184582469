@charset "UTF-8";

.channels-settings {
  display: flex;
  flex-direction: column;
  padding: rem(25px) rem(82px) 0;
  @include between($widescreen, $ultrahd) {
    padding: rem(25px) rem(40px) 0;
  }
}

@import "__header/channels-settings__header";
@import "__body/channels-settings__body";
