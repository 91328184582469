.channel-deleting-badge {
  background: $red-1;
  width: fit-content;
  border-radius: 6px;
  font-weight: 500;
  font-size: rem(11px);
  line-height: 1.5;
  display: flex;
  align-items: center;
  text-align: center;
  color: $red-0;
  padding: rem(4px) rem(8px);
  white-space: nowrap;
  &#{&}--is-h-centered {
    margin: 0 auto;
  }
}
