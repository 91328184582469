.templates-dropdown__item {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  color: $gray-4;
  font-size: rem(14px);
  line-height: 1.5;
  padding: rem(6px) rem(16px);
  position: relative;
  border-radius: 6px;
  .icon:first-child:not(:last-child) {
    margin-right: rem(8px);
  }
  .icon:last-child:not(:first-child) {
    margin-left: rem(8px);
  }
  &:hover {
    background: $gray-1;
    color: #111111;
  }
}
