.client-comment {
  --comment-icon-offset: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: rem(20px) calc(20px - var(--comment-icon-offset)) rem(20px - 1px)
    rem(32px);
  border-bottom: 1px solid $gray-2;
  background-color: $gray-0;
  &:hover {
    background-color: $white-0;
    & > .client-comment__right {
      & > .icon {
        opacity: 1;
      }
    }
  }
}
@import "__left/client-comment__left";
@import "__right/client-comment__right";
@import "__content/client-comment__content";
@import "__text/client-comment__text";
@import "__author/client-comment__author";
@import "__date/client-comment__date";
