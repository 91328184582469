.channels-dropdown__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
  &:not(:last-child) {
    margin-bottom: rem(40px);
  }
}
