.ReactModal__Content {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 200ms, transform 200ms;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Content--before-close {
  opacity: 0;
  transform: translateY(-20px);
}

.ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $default-z * 2 !important;
  width: 100%;
  height: 100vh;
  padding: 2.875rem;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 200ms;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
