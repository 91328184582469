%tab-panel {
  display: none;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  position: relative;
  &--is-active {
    display: flex;
  }
}
