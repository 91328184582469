.level__left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  &#{&}--is-clipped {
    overflow: hidden;
  }
  .level__group,
  .level__item {
    justify-content: flex-start;
  }
}
