.ai-setup-block {
  width: 65%;
  & > .field {
    width: fit-content;
  }
}

@import "__title/ai-setup-block__title";
@import "__description/ai-setup-block__description";
@import "__checkbox/ai-setup-block__checkbox";
@import "__textarea/ai-setup-block__textarea";
@import "__textarea-help/ai-setup-block__textarea-help";
