.dashboard-info-block {
  --info-block-padding-x: #{rem(38px)};
  --info-block-padding-y: #{rem(40px)};
  background: $gray-0;
  border-radius: 16px;
  padding: var(--info-block-padding-y) var(--info-block-padding-x);
  height: auto;
  &#{&}--is-flex {
    display: flex;
  }
  &#{&}--p-one-and-half {
    --info-block-padding-x: #{rem(24px)};
    --info-block-padding-y: #{rem(24px)};
  }
  &#{&}--is-bg-white {
    background-color: $white-0;
  }
  &#{&}--is-h-full {
    height: 100%;
  }
  &#{&}--pie {
    display: flex;
    justify-content: center;
    padding: rem(51px) rem(65px) rem(55px) rem(60px);
  }
  &#{&}--no-padding {
    --info-block-padding-x: 0;
    --info-block-padding-y: 0;
  }
  &#{&}--is-error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
  &#{&}--is-pie-error {
    height: 368px;
    padding: rem(51px) rem(17px) rem(55px) rem(12px);
  }
  &#{&}--is-heatmap-error {
    padding: rem(50px);
  }
}

@import "__title/dashboard-info-block__title";
@import "__subtitle/dashboard-info-block__subtitle";
@import "__note/dashboard-info-block__note";
@import "__error-title/dashboard-info-block__error-title";
@import "__error-subtitle/dashboard-info-block__error-subtitle";
