@charset "UTF-8";

.team-card {
  @extend %card;
  .team-card__item:not(:last-child) {
    margin-right: rem(16px);
  }
  &:hover:not(#{&}--is-selected) {
    background-color: $gray-0;
  }
  &#{&}--is-selected {
    background-color: $gray-1;
  }
}

@import "__tag/team-card__tag";
@import "__name/team-card__name";
@import "__item/team-card__item";
@import "__left/team-card__left";
@import "__right/team-card__right";
