.client-card {
  @extend %card;
  --card-border-bottom-color: transparent;
  --card-py: calc(25px - 1px);
  --card-px: calc(20px - 1px);
  flex-direction: row;
  background-color: $white-0;
  cursor: pointer;
  transition: background-color 100ms;
  border-bottom-color: #f8f8f8;
  .client-card__item:not(:last-child) {
    //margin-right: rem(46px);
  }
  &:hover {
    background-color: $gray-1;
  }
  &#{&}--is-selected {
    background-color: $gray-1;
  }
}

@import "__name/client-card__name";
@import "__button/client-card__button";
@import "__detail/client-card__detail";
@import "__details/client-card__details";
@import "__item/client-card__item";
@import "__left/client-card__left";
@import "__right/client-card__right";
@import "__datetime/client-card__datetime";
@import "__time/client-card__time";
