.chat-card__message {
  @extend %body-text-sm-w-regular;
  @extend %collapsable-text;
  color: $gray-5;
  .emoji {
    display: inline-block;
    width: rem(14px);
    &:not(:last-child) {
      margin-right: rem(4px);
    }
  }
}
