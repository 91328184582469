.ai-setup-block__title {
  color: $black-0;
  font-size: rem(18px);
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: -0.18px;
  margin-bottom: rem(4px);
  & > span {
    color: $red-0;
  }
}
