.node__title {
  $node: ".node";
  @extend %collapsable-text;
  @at-root {
    #{$node}--type-start & {
      @extend %body-text-lg-w-semi-bold;
      color: $white-0;
    }
    #{$node}--type-message & {
      @extend %body-text-md-w-semi-bold;
      color: $black-0;
    }
    #{$node}--type-ask & {
      @extend %body-text-md-w-semi-bold;
      color: $black-0;
    }
    #{$node}--type-scenario & {
      @extend %title-md-w-semi-bold;
      color: $white-0;
    }
    #{$node}--type-end & {
      @extend %body-text-md-w-semi-bold;
      color: $black-0;
    }
    #{$node}--type-integration & {
      @extend %body-text-md-w-semi-bold;
      color: $black-0;
    }
  }
}
