@charset "UTF-8";

.channels-dropdown {
  position: relative;
  &#{&}--is-active {
    z-index: $default-z * 2;
  }
}

@import "__item/channels-dropdown__item";
@import "__description/channels-dropdown__description";
@import "__title/channels-dropdown__title";
@import "__header/channels-dropdown__header";
@import "__body/channels-dropdown__body";
@import "__footer/channels-dropdown__footer";
@import "__content/channels-dropdown__content";
@import "__menu/channels-dropdown__menu";
