.dashed-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  border-width: 1px;
  border-style: dashed;
  border-color: transparent;
  box-shadow: none;
  font-weight: 600;
  font-size: rem(12px);
  line-height: em(24px, 12px);
  color: $black-0;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  cursor: pointer;
  width: 100%;
  transition: border 200ms ease-in-out;
  span:not([class]) {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
  }
  .icon {
    width: em(24px, 12px);
    height: em(24px, 12px);
  }
  &#{&}--h-36 {
    height: em(36px, 12px);
    border-radius: em(6px, 12px);
    padding-top: em(calc((36px - 24px) / 2) - 1px, 12px);
    padding-right: em(15px - 1px, 12px);
    padding-bottom: em(calc((36px - 24px) / 2) - 1px, 12px);
    padding-left: em(15px - 1px, 12px);
    .icon {
      &:first-child:last-child {
        margin-left: em(((24px - 14px) * 0.5) * -1, 12px);
        margin-right: em(((24px - 14px) * 0.5) * -1, 12px);
      }
      &:first-child:not(:last-child) {
        margin-left: em(((24px - 14px) * 0.5) * -1, 12px);
        margin-right: em(16px - ((24px - 14px) * 0.5), 12px);
      }
      &:last-child:not(:first-child) {
        margin-left: em(16px - ((24px - 14px) * 0.5), 12px);
        margin-right: em(((24px - 14px) * 0.5) * -1, 12px);
      }
    }
  }
  &#{&}--h-48 {
    height: em(48px, 12px);
    border-radius: em(8px, 12px);
    padding-top: em(((48px - 24px) * 0.5) - 1px, 12px);
    padding-right: em(13px - 1px, 12px);
    padding-bottom: em(((48px - 24px) * 0.5) - 1px, 12px);
    padding-left: em(13px - 1px, 12px);
    .icon {
      &:first-child:last-child {
        margin-left: em(((24px - 18px) * 0.5) * -1, 12px);
        margin-right: em(((24px - 18px) * 0.5) * -1, 12px);
      }
      &:first-child:not(:last-child) {
        margin-left: em(((24px - 18px) * 0.5) * -1, 12px);
        margin-right: em(12px - ((24px - 18px) * 0.5), 12px);
      }
      &:last-child:not(:first-child) {
        margin-left: em(12px - ((24px - 18px) * 0.5), 12px);
        margin-right: em(((24px - 18px) * 0.5) * -1, 12px);
      }
    }
  }
  &#{&}--dashed-purple-3 {
    background-color: transparent;
    border-color: $purple-3;
    color: $purple-3;
    &:hover {
      border-style: solid;
    }
  }
}
