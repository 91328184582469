.channels-dropdown__body {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  overflow-y: auto;
  @include scrollbar;
  &:not(:last-child) {
    margin-bottom: rem(40px);
  }
}
