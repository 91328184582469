.emoji-bar {
  height: 100%;
  background-color: $gray-0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & > div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

@import "__tabs/emoji-bar__tabs";
@import "__container/emoji-bar__container";
@import "__body/emoji-bar__body";
@import "__search/emoji-bar__search";
