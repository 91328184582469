.switch__labels {
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-weight: 700;
  font-size: rem(10px);
  line-height: 1.5;
  color: $white-0;
  &::after {
    content: attr(data-off);
    position: absolute;
    right: 10px;
    opacity: 1;
    transition: opacity 200ms;
  }
  &::before {
    content: attr(data-on);
    position: absolute;
    left: 10px;
    opacity: 0;
    transition: opacity 200ms;
  }
}
