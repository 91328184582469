@charset "UTF-8";

.account-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  position: relative;
}

@import "__label/account-avatar__label";
@import "__help/account-avatar__help";
@import "__preview/account-avatar__preview";
