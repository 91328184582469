.client-comment__author {
  white-space: pre-wrap;
  user-select: text;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 500;
  font-size: rem(12px);
  line-height: 1.3;
  color: $black-0;
}
