.bot-validation-dropdown__item {
  $this: &;
  @at-root {
    a#{&},
    button#{&} {
      @extend %body-text-sm-w-medium;
      color: $black-0;
      padding: rem(16px) rem(20px) rem(16px);
      background-color: $gray-0;
      border-radius: 4px;
      transition: color 100ms, background-color 100ms;
      span:not([class]) {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
      }
      .icon[data-warning] {
        color: rgba($red-0, 0.5);
      }
      &:not(:last-child) {
        margin-bottom: rem(8px);
      }
      &:hover {
        color: $black-0;
        background-color: $gray-1;
        .icon[data-warning] {
          color: $red-0;
        }
      }
    }
  }
}
