.account-avatar__preview {
  display: block;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 9999px;
  &::after,
  &::before {
    content: "";
    position: absolute;
    opacity: 0;
    z-index: 1;
    transition: opacity 200ms;
  }
  &::before {
    border-radius: inherit;
    background-image: linear-gradient(
      0deg,
      rgba(51, 51, 51, 0.3),
      rgba(51, 51, 51, 0.3)
    );
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &::after {
    background-color: $white-0;
    background-image: url("/assets/image/svg/arrow/arrow-rotate-left-16x16.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    border-radius: 9999px;
    width: 40px;
    height: 40px;
    top: calc(50% - 40px / 2);
    left: calc(50% - 40px / 2);
  }
  &:hover {
    &::before,
    &::after {
      opacity: 1;
    }
  }
}
