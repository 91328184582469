.dashboard-info-block__note {
  font-weight: 400;
  font-size: rem(11px);
  line-height: 1.5;
  color: $gray-3;
  text-align: center;
  &#{&}--is-title-red {
    color: $red-0;
  }
}
