.custom-field-modal__header {
  align-items: center;
  text-align: center;
  &:not(:last-child) {
    margin-bottom: rem(40px);
  }
  &#{&}--is-delete {
    align-items: flex-start;
    &:not(:last-child) {
      margin-bottom: rem(20px);
    }
  }
}
