.message-gallery__controls {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(50% - 24px / 2);
  width: 100%;
  height: rem(24px);
  z-index: 1;
  color: $white-0;
  & > span:first-child {
    margin-right: rem(8px);
  }
}
