.chat-room__send-button {
  padding: rem(8px) rem(16px) rem(8px) rem(19px);
  background-color: $purple-0;
  border-radius: rem(50px);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: rem(30px);
  &:hover {
    background-color: $purple-2;
    & > .icon {
      color: $white-0;
    }
  }
}
