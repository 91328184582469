.bot-card__sessions-count {
  display: flex;
  flex-direction: column;
  margin-bottom: rem(4px);
  font-weight: 400;
  font-size: rem(14px);
  line-height: 1.4;
  color: $gray-5;
  text-align: center;
  & > span:last-child {
    font-weight: 300;
    font-size: rem(11px);
    line-height: 1.5;
    color: $gray-4;
  }
}
