.templates-item {
  max-width: 50%;
  display: inline-block;
  border-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-radius: 16px;
  cursor: pointer;
  align-items: center;
  outline: none;
  position: relative;
  vertical-align: top;
  user-select: none;
  font-weight: 400;
  font-size: rem(14px);
  line-height: 1;
  padding-bottom: calc(1em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(1em - 1px);
  overflow: hidden;
  color: #2b2a35;
  background-color: #ebedfb;
  white-space: pre-wrap;
  word-break: break-all;
  margin-bottom: rem(8px);
  margin-right: rem(10px);
  transition: all 0.3s;
  &:active {
    background-color: #cad1ff;
  }
  &:hover {
    background-color: #d7dcfe;
  }
  &:focus:not(:active) {
    border-color: #b3a2f7;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 4px 6px rgba(0, 0, 0, 0.06),
      0px 6px 12px rgba(33, 52, 69, 0.03);
  }
  .emoji {
    display: inline-block;
    height: rem(14px);
    width: rem(14px);
  }
}

@import "__controls/templates-item__controls";
@import "__favorite-icon/templates-item__favorite-icon";
@import "__ellipsis-icon/templates-item__ellipsis-icon";
