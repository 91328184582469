@charset "UTF-8";

// Normalize.css
@import "~normalize.css/normalize.css";

// Utils
@import "utils/all";

// Base
@import "base/all";

// Tippy
@import "~tippy.js/dist/tippy.css";
@import "~tippy.js/animations/shift-away.css";

// React Toastify
@import "~react-toastify/dist/ReactToastify.css";

// React Datepicker
@import "~react-datepicker/dist/react-datepicker.css";

// Cropper
@import "~cropperjs/src/index";

// React Image Lightbox
@import "~react-image-lightbox/style.css";

// React Loading Skeleton
@import "~react-loading-skeleton/dist/skeleton.css";

// React Big Calendar
@import "react-big-calendar/lib/sass/styles";
@import "react-big-calendar/lib/addons/dragAndDrop/styles";

// ReactFlow
@import "~reactflow/dist/style.css";

// Aos
@import "~aos/dist/aos.css";

// Placeholders
@import "placeholders/all";

// Lib
@import "lib/all";

// Common
@import "common/all";

// Layout
@import "layout/all";
