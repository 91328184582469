@charset "UTF-8";

.chat-room-history-modal {
  position: relative;
  padding: rem(56px) rem(90px) rem(56px) rem(90px);
}

@import "__title/chat-room-history-modal__title";
@import "__list/chat-room-history-modal__list";
@import "__scroller/chat-room-history-modal__scroller";
@import "__body/chat-room-history-modal__body";
