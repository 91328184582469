.node-toolbar {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  background-color: transparent;
  &#{&}--is-active {
    z-index: unset;
  }
  &:hover:not(#{&}--is-active) {
    .react-flow__handle {
      &::before {
        width: 100%;
        height: 100%;
      }
      &::after {
        background-color: rgba(148, 123, 253, 0.2);
      }
    }
    & ~ .node--type-start {
      background-image: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0) 39.79%,
        rgba(255, 255, 255, 0) 39.79%
      );
      box-shadow: 0px 1px 1px rgba(2, 3, 3, 0.03),
        0px 2px 1px rgba(2, 3, 3, 0.02), 0px 1px 3px rgba(2, 3, 3, 0.04);
    }
  }
}
