@charset "UTF-8";

.upload-files {
  --upload-files-offset-x: #{rem(31px)};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding-left: var(--upload-files-offset-x);
  padding-right: var(--upload-files-offset-x);
  overflow: hidden;
}

@import "__button/upload-files__button";
@import "__input/upload-files__input";
@import "__item/upload-files__item";
@import "__list/upload-files__list";
