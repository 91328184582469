.comparison-bar__value {
  display: flex;
  align-items: center;
  height: rem(3px);
  & > progress {
    --percentage: "0px";
    --right: "0px";
    margin-top: rem(9.5px);
    border-radius: 8px;
    position: relative;
    &::-webkit-progress-bar {
      height: 3px;
      border-radius: 8px;
      background-color: transparent;
    }
    &::-webkit-progress-value {
      height: 3px;
      border-radius: 8px;
      accent-color: #a4e0af;
      background-color: #a4e0af;
    }
  }
  & > span:last-child {
    margin-left: rem(8px);
    line-height: 1.5;
    color: $gray-4;
    font-weight: 500;
    font-size: rem(11px);
  }
  & > span:first-child {
    border-radius: 9999px;
    height: 3px;
  }
}
