@charset "UTF-8";

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  &#{&}--is-fullheight {
    min-height: 100vh;
  }
  &#{&}--is-fullwidth {
    min-width: 100%;
  }
  &#{&}--is-flex-grow-1 {
    flex-grow: 1;
  }
  &#{&}--is-start {
    justify-content: flex-start;
  }
}

@import "__content/error__content";
@import "__message/error__message";
@import "__code/error__code";
@import "__title/error__title";
@import "__subtitle/error__subtitle";
