.apexcharts-tooltip {
  color: $white-0;
  background: $gray-5 !important;
  padding: rem(10px) rem(16px);
  overflow: inherit !important;
}
.apexcharts-tooltip-title {
  display: none;
}

.apexcharts-legend-series {
  white-space: nowrap;
  display: flex !important;
  align-items: center;
}

.apexchartshorizontal {
  & > svg {
    border-radius: rem(16px);
  }
  .apexcharts-bar-series,
  .apexcharts-plot-series {
    transform: scaleX(1.5);
  }
}

.apexcharts-svg {
  border-radius: rem(16px);
}

.apexcharts-legend-marker {
  margin-right: rem(8px) !important;
}

.apexcharts-legend-series[seriesname="Email"] {
  .apexcharts-legend-marker {
    height: 2px !important;
    border-width: 2px !important;
    border-color: rgb(148, 123, 253) !important;
    border-style: dashed !important;
    background-color: transparent !important;
  }
}

.apexcharts-legend-series[seriesname="SMS"] {
  .apexcharts-legend-marker {
    height: 2px !important;
    border-width: 2px !important;
    border-color: rgb(116, 193, 130) !important;
    border-style: dashed !important;
    background-color: transparent !important;
  }
}

.apexchartssocials-pie {
  .apexcharts-legend {
    margin-top: rem(16px);
  }
}
.apexcharts-chats-pie-tooltip {
  & > span:first-child {
    font-weight: 400;
    font-size: rem(11px);
    line-height: 1.5;
  }
  & > span:nth-child(2) {
    font-weight: 600;
    font-size: rem(13px);
    line-height: 18px;
  }
  &__arrow {
    position: absolute;
    display: inline-flex;
    align-items: center;
    width: 13px;
    height: 13px;
    bottom: -9px;
    left: calc(50% - 5px);
    color: #333;
    &::before {
      left: 4px;
      top: 0;
      border-width: 5px 5px 5px 0;
      content: "";
      position: absolute;
      border-color: $gray-5;
      border-style: solid;
      border-top-color: transparent;
      border-bottom-color: transparent;
      rotate: 270deg;
    }
  }
}

.apexchartshorizontal {
  .apexcharts-legend {
    margin-top: rem(26px);
  }
}
