.bots-dropdown__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: $gray-2;
  border-radius: em(8px, 12px);
  box-shadow: none;
  font-weight: 600;
  font-size: rem(12px);
  line-height: 1.5;
  color: $gray-4;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
  height: em(48px, 12px);
  padding-top: em(((48px - 18px) * 0.5) - 1px, 12px);
  padding-right: em(16px - 1px, 12px);
  padding-bottom: em(((48px - 18px) * 0.5) - 1px, 12px);
  padding-left: em(16px - 1px, 12px);
  background-color: $white-0;
  cursor: pointer;
  transition: background-color 100ms;
  span:not([class]) {
    @extend %collapsable-text;
  }
  b {
    font-weight: inherit;
    color: $gray-4;
  }
  .icon {
    width: em(18px, 12px);
    height: em(18px, 12px);
    &:first-child:last-child {
      margin-left: em(calc((18px - 16px) / 2) * -1, 12px);
      margin-right: em(calc((18px - 16px) / 2) * -1, 12px);
    }
    &:first-child:not(:last-child) {
      margin-left: em(calc((18px - 16px) / 2) * -1, 12px);
      margin-right: em(12px - calc((18px - 16px) / 2), 12px);
    }
    &:last-child:not(:first-child) {
      margin-left: em(12px - calc((18px - 16px) / 2), 12px);
      margin-right: em(calc((18px - 16px) / 2) * -1, 12px);
    }
  }
  &:hover,
  &#{&}--is-active {
    border-color: $gray-4;
  }
  &#{&}--is-connected {
    &:not(:disabled) {
      color: $purple-3;
    }
    &:disabled {
      color: rgba(119, 87, 254, 0.5);
    }
  }
  &#{&}--is-disabled {
    pointer-events: none;
    cursor: not-allowed;
    b {
      color: rgba(98, 98, 98, 0.5);
    }
    &:not(.bots-dropdown__button--is-connected) {
      span {
        color: rgba(153, 153, 153, 0.75);
      }
    }
  }
}
