.client-panel__tab-panel {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  display: none;
  overflow: hidden;
  position: relative;
  &--is-selected {
    display: flex;
  }
}
