.node-settings__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  padding-top: rem(20px);
  padding-left: rem(47px);
  padding-bottom: rem(20px);
  padding-right: rem(47px);
  overflow-y: auto;
  @include scrollbar;
  &#{&}--not-scrollable {
    flex-basis: auto;
  }
  &#{&}--is-integration {
    padding-left: rem(8px);
    padding-right: rem(8px);
  }
}
