.operator-card__name {
  @extend %collapsable-text;
  font-weight: 600;
  font-size: rem(13px);
  line-height: 1.4;
  letter-spacing: -0.01em;
  color: $black-0;
  margin-left: rem(16px);
  &--is-generated {
    color: $gray-3;
  }
  &#{&}--is-ultrahd {
    display: none;
    @include from($ultrahd) {
      display: block;
    }
  }
  &#{&}--is-widescreen {
    display: none;
    @include between($widescreen, $ultrahd) {
      display: block;
      margin: 0;
    }
  }
}
