.account-settings__close-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  border: none;
  box-shadow: none;
  vertical-align: top;
  white-space: nowrap;
  background-color: transparent;
  color: $gray-5;
  position: absolute;
  right: calc(20px - ((24px - 17px) / 2));
  top: calc(20px - ((24px - 17px) / 2));
  width: rem(24px);
  height: rem(24px);
  padding: 0;
  cursor: pointer;
  .icon {
    width: rem(17px);
    height: rem(17px);
  }
}
