.node-integration__container {
  display: flex;
  align-items: center;
  position: relative;
  .icon {
    position: absolute;
    right: 0;
    &:hover {
      color: $gray-4;
    }
  }
}
