.templates-dropdown {
  display: flex;
  flex-direction: column;
  padding: rem(10px);
  position: fixed;
  z-index: $default-z;
  min-width: rem(260px);
  background: $white-0;
  box-shadow: 0px 10px 28px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
}

@import "__item/templates-dropdown__item";
@import "__trigger/templates-dropdown__trigger";
