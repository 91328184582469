.control {
  --control-px: 0;
  --control-py: 0;
  box-sizing: border-box;
  clear: both;
  font-size: rem(14px);
  position: relative;
  text-align: inherit;
  padding: var(--control-py) var(--control-px);
  &#{&}--has-email-mask {
    display: flex;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-color: $gray-2;
    border-radius: em(8px, 14px);
    background-color: $white-0;
  }
  &#{&}--has-icon-left,
  &#{&}--has-icon-right {
    .icon {
      height: em(48px, 14px);
      position: absolute;
      top: 0;
      width: em(48px, 14px);
      z-index: 1;
    }
    .input {
      z-index: 0;
    }
  }
  &#{&}--has-icon-left {
    .icon[data-left]:not([data-right]) {
      left: 0;
    }
    .input {
      padding-left: em(48px, 14px);
    }
  }
  &#{&}--has-icon-right {
    .icon[data-right]:not([data-left]) {
      right: 0;
    }
    .input {
      padding-right: em(48px, 14px);
    }
  }
  @for $i from 0 through 20 {
    &#{&}--has-px-#{$i} {
      --control-px: #{$i * 0.125rem};
    }
    &#{&}--has-py-#{$i} {
      --control-py: #{$i * 0.125rem};
    }
  }
}
