@charset "UTF-8";

.chats-assigning-dropdown {
  width: 100%;
  position: relative;
}

@import "__item/chats-assigning-dropdown__item";
@import "__header/chats-assigning-dropdown__header";
@import "__body/chats-assigning-dropdown__body";
@import "__footer/chats-assigning-dropdown__footer";
@import "__content/chats-assigning-dropdown__content";
@import "__menu/chats-assigning-dropdown__menu";
