.node-dropdown__item {
  @at-root {
    a#{&},
    button#{&} {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 500;
      font-size: rem(14px);
      line-height: 1.5;
      letter-spacing: -0.02em;
      color: $gray-2;
      border-radius: 6px;
      padding: rem(9px) rem(12px);
      background-color: transparent;
      user-select: none;
      cursor: pointer;
      transition: background-color 100ms;
      &:hover {
        background-color: $gray-5;
      }
    }
  }
}
