.client-dropdown__item {
  @extend %title-md-w-semi-bold;
  color: $black-0;
  padding: rem(11px) rem(16px);
  &:not(:last-child) {
    margin-bottom: rem(10px);
  }
  @at-root {
    a#{&},
    button#{&} {
      @extend %body-text-md-w-semi-bold;
      color: $gray-5;
      background-color: $white-0;
      border-radius: 6px;
      padding-top: rem(10.5px);
      padding-bottom: rem(10.5px);
      transition: background-color 100ms;
      span:not([class]) {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &:hover {
        background-color: $gray-1;
      }
    }
  }
}
