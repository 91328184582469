.saving-dropdown__item {
  $this: &;
  @at-root {
    a#{&},
    button#{&} {
      font-weight: 600;
      font-size: rem(12px);
      line-height: 1.4;
      color: $gray-4;
      border-radius: 6px;
      padding: rem(15.5px) rem(20px);
      background-color: $white-0;
      user-select: none;
      cursor: pointer;
      transition: background-color 100ms;
      &:hover {
        background-color: $gray-0;
      }
      &:not(:last-child) {
        margin-bottom: rem(4px);
      }
      &#{$this}--is-active {
        background-color: $gray-1;
        cursor: default;
      }
    }
  }
}
