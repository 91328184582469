.templates__body {
  display: grid;
  grid-template-columns: 1fr rem(380px);
  gap: rem(80px);
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  @include between($widescreen, $ultrahd) {
    gap: 0;
    grid-template-columns: 1fr;
  }
}
