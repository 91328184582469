@charset "UTF-8";

.flow-card {
  @extend %body-text-md-w-regular;
  $this: &;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: rem(64px);
  padding: rem(17px) rem(17px) rem(17px) rem(17px - 2px);
  border-radius: 8px;
  border-width: 0px;
  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: transparent;
  background-color: $gray-0;
  color: $gray-4;
  user-select: none;
  cursor: pointer;
  transition: color 100ms, border-color 100ms, background-color 100ms;
  &#{&}--is-selected {
    font-weight: 600;
    color: $black-0;
    .flow-card__icon {
      color: $purple-3;
    }
  }
  @at-root {
    a#{&} {
      .action-dropdown {
        --action-dropdown-button-active-bc: #{$white-0};
      }
      .action-dropdown__button {
        opacity: 0;
        pointer-events: none;
        transition: opacity 200ms;
      }
      &:hover,
      &#{$this}--is-focused {
        .action-dropdown__button {
          opacity: 1;
          pointer-events: initial;
        }
      }
      &#{$this}--is-selected {
        border-left-color: $purple-2;
      }
    }
    button#{&} {
      &:hover {
        background-color: $gray-1;
      }
      &#{$this}--is-selected {
        border-left-color: $purple-3;
        .flow-card__icon {
          color: $purple-3;
          background-color: rgba(103, 69, 255, 0.1);
        }
      }
    }
  }
}

@import "__icon/flow-card__icon";
@import "__left/flow-card__left";
@import "__right/flow-card__right";
