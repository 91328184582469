.message-audio {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: rem(40px);
  width: rem(241px);
  max-width: 100%;
}

@import "__wave/message-audio__wave";
@import "__toggle/message-audio__toggle";
