.bots-dropdown__item-help {
  font-weight: 500;
  font-size: rem(11px);
  line-height: 1.5;
  color: $gray-5;
  text-transform: lowercase;
  user-select: none;
  opacity: 0;
  transition: opacity 200ms;
  .icon {
    color: transparent;
  }
  @at-root {
    .bots-dropdown__item.bots-dropdown__item--is-connected & {
      color: $purple-3;
    }
  }
}
