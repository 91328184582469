.calendar-event-modal {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 16px;
  background-color: $white-0;
  max-height: 100%;
  padding: 0;
  overflow: visible;
  form {
    display: block;
  }
}
