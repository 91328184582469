@charset "UTF-8";

.messages-date-time {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: rem(32px);
  padding-bottom: rem(32px);
  user-select: none;
  &:first-child {
    padding-top: rem(8px);
  }
}

@import "__badge/messages-date-time__badge";
