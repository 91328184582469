.user-dropdown__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  border-width: var(--button-border-width);
  border-style: solid;
  border-color: transparent;
  border-radius: 9999px;
  box-shadow: none;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  padding: 0;
  &--is-active {
    border-color: $white-0;
  }
}
