.calendar-event-content__textfield {
  width: 100%;
  height: rem(96px);
  padding: rem(15px) rem(16px);
  border: 1px solid $gray-2;
  border-radius: 8px;
  background-color: $gray-0;
  font-weight: 400;
  font-size: rem(12px);
  line-height: 1.4;
  color: $gray-5;
  &#{&}--has-errors {
    border-color: $red-0;
  }
  textarea {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    resize: none;
    background-color: transparent;
    &::placeholder {
      color: $gray-3;
    }
  }
}
