.third-party-create {
  margin-top: rem(40px);
}

@import "__button-container/third-party-create__button-container";
@import "__button/third-party-create__button";
@import "__button-text/third-party-create__button-text";
@import "__block-title/third-party-create__block-title";
@import "__block/third-party-create__block";
@import "__block-footer/third-party-create__block-footer";
@import "__add-tag/third-party-create__add-tag";
