.breadcrumb__link {
  @extend %title-md-w-semi-bold;
  color: $gray-4;
  padding: 0 rem(8px);
  [data-contenteditable] {
    position: relative;
    span:not([class]) {
      opacity: 0;
    }
  }
}
