.flow__addons {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
  max-width: max-content;
  border-radius: em(6px);
  background-color: $white-0;
  box-shadow: $shadow-3;
  &:not(:last-child) {
    margin-right: rem(16px);
  }
  .flow__addon {
    box-shadow: none;
    &:not(:last-child) {
      border-right-color: $gray-2;
    }
    &:first-child:not(:only-child) {
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
    }
    &:not(:first-child):not(:last-child) {
      border-radius: 0px;
    }
    &:last-child:not(:only-child) {
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
    }
  }
}
