.button__divider {
  border: none;
  display: block;
  width: 1px;
  height: inherit;
  background-color: rgba($white-0, 0.2);
  @at-root {
    .button.button--h-36 & {
      margin: em((calc((36px - 21px) / 2) - 1px) * -1, 14px) em(12px, 14px);
    }
    .button.button--h-48 & {
      margin: em((calc((48px - 21px) / 2) - 1px) * -1, 14px) em(14px, 14px);
    }
  }
}
