.messages-search-dropdown__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: em(6px);
  box-shadow: none;
  font-weight: 600;
  font-size: rem(16px);
  line-height: 1.5;
  color: $gray-4;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
  height: em(36px);
  padding-top: em(calc((36px - 24px) / 2) - 1px);
  padding-right: em(calc((36px - 17px) / 2) - 1px);
  padding-bottom: em(calc((36px - 24px) / 2) - 1px);
  padding-left: em(calc((36px - 17px) / 2) - 1px);
  background-color: transparent;
  cursor: pointer;
  transition: background-color 100ms;
  .icon {
    width: em(24px);
    height: em(24px);
    &:first-child:last-child {
      margin-left: em(((24px - 17px) * 0.5) * -1);
      margin-right: em(((24px - 17px) * 0.5) * -1);
    }
    &:first-child:not(:last-child) {
      margin-left: em(((24px - 17px) * 0.5) * -1);
      margin-right: em(12px - ((24px - 17px) * 0.5));
    }
    &:last-child:not(:first-child) {
      margin-left: em(12px - ((24px - 17px) * 0.5));
      margin-right: em(((24px - 17px) * 0.5) * -1);
    }
  }
  &:hover,
  &#{&}--is-active {
    border-color: $gray-1;
    background-color: $gray-1;
  }
}
