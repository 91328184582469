.client-card__datetime {
  display: flex;
  color: $gray-5;
  font-size: rem(11px);
  font-weight: 500;
  line-height: 1.5;
  padding: rem(4px) rem(8px);
  border-radius: 8px;
  background: $gray-0;
  align-items: center;
}
