@charset "UTF-8";

.client-custom-fields {
  display: flex;
  flex-direction: column;
  &:not(:first-child) {
    margin-top: rem(40px);
  }
}

@import "__title/client-custom-fields__title";
@import "__header/client-custom-fields__header";
@import "__body/client-custom-fields__body";
