.calendar-event-participant-dropdown__content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  border-radius: 8px;
  max-height: rem(240px);
  overflow-x: hidden;
  overflow-y: auto;
  @include scrollbar;
}
