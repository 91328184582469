.third-party-create__add-tag {
  padding: rem(9px) rem(20px) rem(10px) rem(16px);
  border: 1px dashed $purple-3;
  background-color: transparent;
  border-radius: 6px;
  font-size: rem(12px);
  font-weight: 600;
  line-height: 1.4;
  color: $purple-3;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    color: $white-0;
    background-color: $purple-3;
  }
}
