.client-details {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  width: 100%;
}

@import "__body/client-details__body";
@import "__footer/client-details__footer";
