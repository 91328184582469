.channel-card__status {
  @extend %body-text-sm-w-medium;
  color: $gray-3;
  overflow: hidden;
  &#{&}--is-active {
    color: $social-whats-hover;
  }
  &#{&}--is-error {
    color: $red-0;
  }
}
