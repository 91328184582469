.sort-icon {
  &#{&}--is-asc {
    .up {
      fill: $gray-5;
    }
  }
  &#{&}--is-desc {
    .down {
      fill: $gray-5;
    }
  }
}
