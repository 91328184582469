.chat-room__hint {
  --chat-room-hint-offset-x: 0;
  --chat-room-hint-offset-y: 0;
  @extend %body-text-sm-w-regular;
  color: $gray-4;
  position: absolute;
  top: calc(100% + var(--chat-room-hint-offset-y));
  &#{&}--is-top {
    top: auto;
    bottom: calc(100% + var(--chat-room-hint-offset-y));
  }
  &#{&}--is-left {
    left: var(--chat-room-hint-offset-x);
    right: auto;
  }
  &#{&}--is-bottom {
    top: calc(100% + var(--chat-room-hint-offset-y));
    bottom: auto;
  }
  &#{&}--is-right {
    left: auto;
    right: var(--chat-room-hint-offset-x);
  }
  @for $i from 0 through 20 {
    &#{&}--has-offset-y-#{$i} {
      --chat-room-hint-offset-y: #{$i * 0.125rem};
    }
    &#{&}--has-offset-x-#{$i} {
      --chat-room-hint-offset-x: #{$i * 0.125rem};
    }
  }
}
