.chat-room__textarea {
  @extend %body-text-lg-w-regular;
  width: 100%;
  resize: none;
  border: none;
  outline: none;
  padding: rem(5px) rem(10px);
  color: $gray-5;
  @include scrollbar;
  &::placeholder {
    color: $gray-4;
  }
}
