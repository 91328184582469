.client-comments__form {
  display: flex;
  align-items: center;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  background: white;
  &:focus-within,
  &:focus,
  &:active {
    border-color: #9d8dff;
    & > button {
      color: $purple-1;
    }
  }
  & > button {
    &:hover {
      color: $purple-3;
    }
  }
  & > button {
    color: #dddfe5;
  }
}
