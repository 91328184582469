.target-handle {
  $this: &;
  $reactflow-handle: ".react-flow__handle";
  min-width: 0px;
  min-height: 0px;
  border-radius: 0;
  transform: none;
  border: none;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: calc(3px * -1);
  &::before {
    content: "";
    background-color: $gray-4;
    position: absolute;
  }
  &#{&}--is-connecting {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &:not(#{this}--is-connecting) {
    width: 0px;
    height: 100%;
  }
  &#{$reactflow-handle}-top::before {
    width: rem(14px);
    height: rem(3px);
    border-radius: 2px 2px 0px 0px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  &#{$reactflow-handle}-right::before {
    width: rem(3px);
    height: rem(14px);
    border-radius: 0px 2px 2px 0px;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
  &#{$reactflow-handle}-bottom::before {
    width: rem(14px);
    height: rem(3px);
    border-radius: 0px 0px 2px 2px;
    bottom: 0;
    top: auto;
    left: 50%;
    transform: translate(-50%, 0);
  }
  &#{$reactflow-handle}-left::before {
    width: rem(3px);
    height: rem(14px);
    border-radius: 2px 0px 0px 2px;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}
