@charset "UTF-8";

.chat-room {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  outline: none;
}

@import "__hint/chat-room__hint";
@import "__title/chat-room__title";
@import "__textarea/chat-room__textarea";
@import "__content/chat-room__content";
@import "__header/chat-room__header";
@import "__body/chat-room__body";
@import "__footer/chat-room__footer";
@import "__send-button/chat-room__send-button";
@import "__header-channel/chat-room__header-channel";
@import "__title-container/chat-room__title-container";
