.dropdown__divider {
  --mb: 0;
  $this: &;
  display: block;
  height: 1px;
  border: none;
  background-color: $gray-2;
  margin-bottom: var(--mb);
  z-index: 0;
  @for $i from 0 through 10 {
    &#{&}--has-mb-#{$i} {
      --mb: #{$i * 0.25rem};
    }
  }
}
