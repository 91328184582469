@charset "UTF-8";

.info-placeholder {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  user-select: none;
}

@import "__description/info-placeholder__description";
@import "__title/info-placeholder__title";
@import "__body/info-placeholder__body";
