.attachment {
  --attachment-bc: transparent;
  --attachment-p: 0;
  --attachment-mb: #{rem(16px)};
  --attachment-icon-size: #{rem(36px)};
  --attachment-icon-m: #{rem(16px)};
  --attachment-content-pl: calc(
    var(--attachment-icon-size) + var(--attachment-icon-m)
  );
  display: flex;
  flex-direction: column-reverse;
  background-color: var(--attachment-bc);
  padding: var(--attachment-p);
  border-radius: 8px;
  position: relative;
  &:not(:last-child) {
    margin-bottom: var(--attachment-mb);
  }
  &#{&}--has-background-gray-0 {
    --attachment-bc: #{$gray-0};
  }
  @for $i from 0 through 10 {
    &#{&}--has-p-#{$i} {
      --attachment-p: #{$i * 0.25rem};
    }
    &#{&}--has-mb-#{i} {
      --attachment-mb: #{$i * 0.25rem};
    }
  }
}

@import "__size/attachment__size";
@import "__name/attachment__name";
@import "__icon/attachment__icon";
@import "__content/attachment__content";
