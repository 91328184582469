@charset "UTF-8";

.field {
  $this: &;
  display: flex;
  &#{&}--is-fullwidth {
    width: 100%;
  }
  &#{&}--is-grouped {
    --control-gap: #{rem(16px)};
    justify-content: flex-start;
    & > .control {
      flex-shrink: 0;
      &:not(:last-child) {
        margin-bottom: 0;
        margin-right: var(--control-gap);
      }
    }
    & > .control.control--is-expanded {
      flex-grow: 1;
      flex-shrink: 1;
    }
    @for $i from 0 through 20 {
      &#{$this}--has-gap-#{$i} {
        --control-gap: #{$i * 0.125rem};
      }
    }
  }
  &#{&}--is-v-centered {
    align-items: center;
  }
  &#{&}--is-direction-row {
    flex-direction: row;
  }
  &#{&}--is-direction-column {
    flex-direction: column;
  }
  &:not(:last-child) {
    margin-bottom: rem(16px);
  }
}

@import "__label/field__label";
@import "__help/field__help";
@import "__required/field__required";
