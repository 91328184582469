.flows__button {
  @extend %body-text-md-w-regular;
  display: inline-flex;
  align-items: stretch;
  justify-content: flex-start;
  appearance: none;
  user-select: none;
  border-width: 1px;
  border-style: dashed;
  border-color: $gray-2;
  border-radius: 8px;
  box-shadow: none;
  color: $gray-5;
  background-color: transparent;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  cursor: pointer;
  height: rem(64px);
  padding-top: rem(17px - 1px);
  padding-right: rem(17px - 1px);
  padding-bottom: rem(17px - 1px);
  padding-left: rem(17px - 1px);
  .icon {
    color: $black-0;
    border-radius: 9999px;
    background-color: $gray-0;
  }
  &:hover {
    border-style: solid;
    border-color: $purple-3;
    .icon {
      color: $purple-3;
      background-color: rgba($purple-3, 0.1);
    }
  }
  &:not(:last-child) {
    margin-bottom: rem(8px);
  }
}
