.select__control {
  $this: ".select__control";
  min-height: 48px;
  background-color: $white-0;
  border: 1px solid $gray-2;
  border-radius: 8px;
  box-shadow: none;
  &:hover,
  &:focus,
  &#{$this}--is-focused {
    border-color: $gray-5;
  }
}
