@charset "UTF-8";

.node-button {
  $this: &;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border: 1px solid $gray-2;
  background-color: $white-0;
  height: rem(48px);
  padding-top: rem(16px - 1px);
  padding-right: rem(48px - 1px);
  padding-bottom: rem(16px - 1px);
  padding-left: rem(20px - 1px);
  position: relative;
  &:not(:first-child) {
    margin-top: -1px;
  }
  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

@import "__name/node-button__name";
@import "__index/node-button__index";
