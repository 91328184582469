.avatar {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
  border-radius: 4px;
  img {
    object-fit: cover;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
  &#{&}--is-rounded {
    border-radius: 9999px;
    img {
      border-radius: 9999px;
    }
  }
  &#{&}--has-status-online,
  &#{&}--has-status-away,
  &#{&}--has-status-offline {
    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: rem(7px);
      height: rem(7px);
      border: 1px solid $white-0;
      border-radius: 9999px;
    }
  }
  &#{&}--has-status {
    &-online {
      &::after {
        background-color: $green-0;
      }
    }
    &-away {
      &::after {
        background-color: $gray-2;
      }
    }
    &-offline {
      &::after {
        background-color: $gray-2;
      }
    }
  }
}
