.template-panel__textarea {
  min-height: 260px;
  background: $white-0;
  border: 1px solid #dddfe5;
  border-radius: 8px;
  margin-top: rem(10px);
  font-weight: 400;
  font-size: 13px;
  color: $gray-5;
  resize: none;
  padding: rem(16px);
  width: 100%;
  &::placeholder {
    color: #939da8;
  }
  @include scrollbar;
}
