%list {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  color: $gray-2;
  overflow-y: auto;
  @include scrollbar;
  &--has-border-top {
    border-top: 1px solid currentColor;
  }
  &--is-loading {
    overflow: hidden;
  }
}
