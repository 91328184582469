.messages__anchor {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(36px);
  height: rem(36px);
  position: absolute;
  bottom: 26px;
  right: 16px;
  cursor: pointer;
  z-index: $default-z;
  background-color: $purple-0;
  border-radius: 9999px;
  transition: background-color 100ms;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    mask-image: url("/assets/image/svg/chevron/chevron-down-14x14.svg");
    mask-size: auto;
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: $purple-3;
  }
  &:hover {
    background-color: $purple-3;
    &::after {
      background-color: $white-0;
    }
  }
}
