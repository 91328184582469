.template-panel__body {
  padding: 0 rem(16px);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 1;
  overflow-y: auto;
  position: relative;
  @include scrollbar;
}
