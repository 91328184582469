.operator-card__team {
  background: $gray-1;
  border-radius: rem(40px);
  font-size: rem(14px);
  line-height: 1.5;
  color: $gray-5;
  padding: rem(8px) rem(20px);
  max-width: rem(80px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
