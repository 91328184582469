.custom-field-modal__body {
  padding-left: rem(62.5px);
  padding-right: rem(62.5px);
  overflow: visible;
  &:not(:last-child) {
    margin-bottom: rem(40px);
  }
  &#{&}--is-delete {
    padding: 0;
  }
}
