@charset "UTF-8";

.dropdown {
  $this: &;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  vertical-align: top;
  z-index: $default-z;
  &#{&}--has-overlay {
    &#{$this}--is-active {
      z-index: $default-z * 2;
    }
  }
}

@import "__divider/dropdown__divider";
@import "__item/dropdown__item";
@import "__trigger/dropdown__trigger";
@import "__content/dropdown__content";
@import "__menu/dropdown__menu";
