@charset "UTF-8";

.bot-publish-dropdown {
  position: relative;
}

@import "__description/bot-publish-dropdown__description";
@import "__info/bot-publish-dropdown__info";
@import "__circle/bot-publish-dropdown__circle";
@import "__item/bot-publish-dropdown__item";
@import "__content/bot-publish-dropdown__content";
@import "__menu/bot-publish-dropdown__menu";
