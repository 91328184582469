.attachment__icon {
  $this: &;
  display: block;
  text-align: center;
  width: var(--attachment-icon-size);
  height: var(--attachment-icon-size);
  padding: rem(12px) rem(4px) 0;
  margin-left: calc(var(--attachment-pl) * -1);
  border-radius: 6px;
  position: absolute;
  left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: rem(11px);
  line-height: 1;
  color: $white-0;
  background-color: $purple-2;
  &#{&}--is-chatroom {
    cursor: pointer;
    & > .icon {
      display: none;
    }
    &:hover {
      & > span:last-child {
        display: none;
      }
      .icon {
        display: block;
      }
    }
  }
  @at-root {
    .attachment--is-uploading & {
      pointer-events: none;
      & > * {
        display: none;
      }
      &::after {
        content: "";
        position: absolute;
        left: calc(50% - (0.75rem * 0.5));
        top: calc(50% - (0.75rem * 0.5));
        animation: spinAround 500ms infinite linear;
        border: 2px solid currentColor;
        border-radius: 9999px;
        border-right-color: transparent;
        border-top-color: transparent;
        display: block;
        height: 0.75rem;
        width: 0.75rem;
      }
    }
  }
}
