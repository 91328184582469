.search-input {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: em(6px, 12px);
  font-weight: 400;
  font-size: rem(12px);
  line-height: 1.4;
  color: $gray-5;
  background-color: $gray-1;
  height: em(36px, 12px);
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
  max-width: 100%;
  padding-top: em(8.5px - 1px, 12px);
  padding-left: em(16px - 1px, 12px);
  padding-bottom: em(8.5px - 1px, 12px);
  padding-right: em(16px - 1px, 12px);
  transition: background-color 100ms;
  outline: none;
  &::-webkit-search-cancel-button {
    appearance: none;
    height: rem(16px);
    width: rem(16px);
    margin: 0;
    background-color: rgba($gray-3, 0.5);
    mask-size: auto;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-image: url("/assets/image/svg/circle-xmark.svg");
    &:hover {
      background-color: $gray-4;
    }
  }
  &::placeholder {
    color: $gray-3;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &:not(:disabled) {
    &:hover,
    &:focus {
      border-color: $gray-4;
      background-color: $white-0;
    }
  }
}
