.emoji-list__item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  padding: rem(12px);
  user-select: none;
  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }
  .emoji {
    display: block;
    width: rem(24px);
    height: rem(24px);
    pointer-events: none;
  }
}
