@charset "UTF-8";

.custom-field-modal {
  padding: rem(40px);
  overflow: visible;
}

@import "__title/custom-field-modal__title";
@import "__subtitle/custom-field-modal__subtitle";
@import "__footer/custom-field-modal__footer";
@import "__header/custom-field-modal__header";
@import "__body/custom-field-modal__body";
