.modal {
  --close-button-size: #{rem(24px)};
  --close-button-offset: #{rem(20px)};
  --icon-size: #{rem(15px)};
  --modal-padding: calc(
    var(--close-button-offset) * 2 + var(--close-button-size)
  );
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 6px;
  background-color: $white-0;
  max-height: 100%;
  overflow: hidden;
  padding: var(--modal-padding);
  form {
    display: none;
  }
}

@import "__close-button/modal__close-button";
@import "__header/modal__header";
@import "__body/modal__body";
@import "__footer/modal__footer";
