@charset "UTF-8";

.bot-builder {
  --flows-panel-width: #{rem(332px)};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100vh;
  background-color: $gray-1;
  position: relative;
  overflow: hidden;
}

@import "__body/bot-builder__body";
