.ai-setup-block__checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  border-radius: 8px;
  background: $gray-0;
  padding: rem(7px);
  width: fit-content;
  & > .ai-setup-block__title {
    color: $gray-5;
    font-size: rem(13px);
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 14.3px */
    letter-spacing: -0.13px;
    margin-left: rem(8px);
    margin-bottom: 0;
  }
}
