.bot-publish-dropdown__item {
  @at-root {
    a#{&},
    button#{&},
    label#{&} {
      @extend %body-text-lg-w-semi-bold;
      color: $black-0;
      border-radius: 8px;
      border-width: 1px;
      border-style: solid;
      border-color: transparent;
      padding: rem(18px - 1px) rem(20px - 1px);
      background-color: $white-0;
      user-select: none;
      cursor: pointer;
      transition: background-color 100ms;
      &:hover {
        border-color: $gray-2;
        .bot-publish-dropdown__circle {
          color: $purple-3;
          background-color: $purple-0;
        }
        .bot-publish-dropdown__description {
          color: currentColor;
        }
      }
      &:not(:last-child) {
        margin-bottom: rem(4px);
      }
    }
  }
}
