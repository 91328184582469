.client-comments {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  display: flex;
  height: 100%;
  flex-direction: column;
}

@import "__body/client-comments__body";
@import "__header/client-comments__header";
@import "__scroller/client-comments__scroller";
@import "__send-button/client-comments__send-button";
@import "__form/client-comments__form";
@import "__textarea/client-comments__textarea";
