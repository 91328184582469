@charset "UTF-8";

.input {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: $gray-2;
  border-radius: em(8px, 14px);
  font-weight: 400;
  font-size: rem(14px);
  line-height: 1.4;
  letter-spacing: -0.01em;
  color: $gray-5;
  background-color: $white-0;
  height: em(48px, 14px);
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
  max-width: 100%;
  padding-top: em(14.5px - 1px, 14px);
  padding-left: em(16px - 1px, 14px);
  padding-bottom: em(14.5px - 1px, 14px);
  padding-right: em(16px - 1px, 14px);
  transition: background-color 100ms;
  &#{&}--has-email-mask {
    border: none;
    outline: none;
  }
  &::placeholder {
    color: $gray-4;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &:not(:disabled) {
    &:hover,
    &:focus {
      border-color: $gray-4;
    }
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@import "__email-mask/input__email-mask";
