.messages-search__input {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  appearance: none;
  border: none;
  outline: none;
  border-radius: 0;
  font-weight: 600;
  font-size: rem(12px);
  line-height: 1.4;
  letter-spacing: -0.01em;
  color: $black-0;
  background-color: transparent;
  height: em(38px, 12px);
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
  max-width: 100%;
  padding-top: em(12px - 1px, 12px);
  padding-left: em(16px - 1px, 12px);
  padding-bottom: em(12px - 1px, 12px);
  padding-right: em(16px - 1px, 12px);
  transition: background-color 100ms;
  &::-webkit-search-cancel-button {
    display: none;
    visibility: hidden;
  }
  &::placeholder {
    font-weight: 400;
    color: $gray-3;
  }
}
