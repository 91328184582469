.dropdown__item {
  display: block;
  position: relative;
  @at-root {
    a#{&},
    button#{&} {
      width: 100%;
      appearance: none;
      vertical-align: top;
      position: relative;
      box-shadow: none;
      border: none;
      white-space: nowrap;
      user-select: none;
      background-color: transparent;
      cursor: pointer;
    }
  }
}
