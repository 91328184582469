.chats-container {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: rem(380px) 1fr;
  grid-auto-columns: auto;
  grid-auto-rows: auto;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  @include between($widescreen, $ultrahd) {
    grid-template-columns: rem(360px) 1fr;
  }
}
