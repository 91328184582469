.client-comment__date {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  font-size: rem(11px);
  line-height: 1.5;
  color: $gray-3;
  margin-bottom: rem(9.5px);
  & > hr {
    height: 17px;
    width: 3px;
    border: none;
    border-right: 1px solid $gray-2;
    margin: 0 rem(2px);
  }
}
