.channel-panel__title {
  $this: &;
  @extend %title-lg-w-semi-bold;
  color: $black-0;
  @at-root {
    .channel-panel.channel-panel--is-update,
    .channel-panel.channel-panel--is-delete {
      #{$this}:not(:last-child) {
        margin-bottom: rem(14px);
      }
    }
    .channel-panel.channel-panel--is-create,
    .channel-panel.channel-panel--is-reconnect {
      #{$this}:not(:last-child) {
        margin-bottom: rem(10px);
      }
    }
    &#{&}--is-deleting {
      color: $gray-3;
    }
  }
}
