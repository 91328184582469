.node-settings__dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: rem(153px);
  border-width: 1px;
  border-style: dashed;
  border-color: $gray-3;
  border-radius: 8px;
  padding: rem(32px) rem(18px);
  background-color: transparent;
  user-select: none;
  cursor: pointer;
  color: $gray-4;
  transition: border 100ms ease-in-out;
  .icon {
    &:not(:last-child) {
      margin-bottom: rem(16px);
    }
  }
  p:not([class]) {
    @extend %body-text-sm-w-regular;
    color: $black-0;
    text-align: center;
    white-space: pre-wrap;
    user-select: text;
    word-wrap: break-word;
    word-break: break-word;
    pointer-events: none;
    i {
      font-style: normal;
      color: $purple-3;
    }
    &:not(:last-child) {
      margin-bottom: rem(4px);
    }
  }
  span:not([class]) {
    font-weight: 400;
    font-size: rem(11px);
    line-height: 1.5;
    color: $gray-4;
    text-align: center;
    pointer-events: none;
  }
  &:hover {
    border-color: $purple-3;
  }
  &#{&}--is-focused {
    border-color: $purple-3;
  }
  &#{&}--is-active {
    border-color: $purple-3;
  }
  &#{&}--is-rejected {
    border-color: $red-0;
  }
}
