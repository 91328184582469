.bot-app-actions {
  padding: rem(30px) rem(39px);
  background: $gray-0;
  border-radius: 8px;
  &#{&}--is-fields {
    padding: 0;
  }
}

@import "__title/bot-app-actions__title";
@import "__body/bot-app-actions__body";
