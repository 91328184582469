.node-thumb {
  display: flex;
  min-width: 0;
  overflow: hidden;
  position: relative;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
