.node-drag-button-wrapper {
  display: block;
  &:not(:first-child) {
    .node-drag-button {
      margin-top: -1px;
    }
  }
  &:first-child {
    .node-drag-button {
      border-top-left-radius: em(6px, 12px);
      border-top-right-radius: em(6px, 12px);
    }
  }
  &:last-child {
    .node-drag-button {
      border-bottom-left-radius: em(6px, 12px);
      border-bottom-right-radius: em(6px, 12px);
    }
  }
}
