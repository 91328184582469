.action-dropdown__item {
  $this: &;
  @at-root {
    a#{&},
    button#{&} {
      font-weight: 500;
      font-size: rem(14px);
      line-height: 150%;
      letter-spacing: -0.02em;
      color: $gray-5;
      padding: rem(6.5px) rem(10px);
      background-color: $white-0;
      border-radius: 6px;
      transition: color 100ms, background-color 100ms;
      &:not(:last-child) {
        margin-bottom: var(--action-dropdown-item-gap);
      }
      &#{$this}--is-disabled {
        opacity: 0.9;
        cursor: not-allowed;
      }
      &:not(#{$this}--is-disabled) {
        &:hover,
        &#{$this}--is-active {
          color: $black-0;
          background-color: $gray-1;
        }
        &[data-to-delete]:hover {
          .icon {
            color: $red-0;
          }
        }
      }
    }
  }
}
