@charset "UTF-8";

.flow-testing-widget {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: var(--widget-width);
  height: var(--widget-height);
  vertical-align: top;
  border-width: 15px;
  border-style: solid;
  border-color: $gray-2;
  border-radius: var(--widget-outer-radius);
  background-color: $white-0;
  position: relative;
  overflow: hidden;
}

@import "__notch/flow-testing-widget__notch";
@import "__send-button/flow-testing-widget__send-button";
@import "__input/flow-testing-widget__input";
@import "__circle/flow-testing-widget__circle";
@import "__title/flow-testing-widget__title";
@import "__header/flow-testing-widget__header";
@import "__body/flow-testing-widget__body";
@import "__footer/flow-testing-panel__footer";
