.client-comment__text {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  font-weight: 400;
  font-size: rem(12px);
  line-height: 1.3;
  color: $black-0;
  display: flex;
  justify-content: space-between;
}
