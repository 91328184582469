.date-time-badge {
  $date-tag: "span:not([class])";
  display: block;
  font-size: rem(11px);
  line-height: 1.5;
  color: $black-0;
  background-color: $white-0;
  border-width: 1px;
  border-style: solid;
  border-color: $gray-2;
  border-radius: 20px;
  user-select: none;
  padding-top: rem(3px - 1px);
  padding-right: rem(7px - 1px);
  padding-bottom: rem(3px - 1px);
  padding-left: rem(7px - 1px);
  overflow: hidden;
  transition: background-color 100ms;
  #{$date-tag} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 rem(2px);
  }
  ul {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    li {
      display: flex;
      align-items: center;
      &:first-child {
        #{$date-tag} {
          padding-left: 0;
        }
      }
      &:last-child {
        #{$date-tag} {
          padding-right: 0;
        }
      }
      & + li::before {
        content: "|";
        color: $gray-2;
      }
    }
  }
  &#{&}--is-hoverable {
    background-color: transparent;
    border-color: transparent;
    &:hover {
      background-color: $white-0;
      border-color: $white-0;
    }
  }
  &#{&}--is-transparent-light {
    background-color: transparent;
    border-color: transparent;
    color: $black-0;
  }
  &#{&}--is-transparent-dark {
    background-color: transparent;
    border-color: transparent;
    color: $white-0;
  }
  &#{&}--is-regular {
    font-weight: 400;
  }
  &#{&}--is-medium {
    font-weight: 500;
  }
}
