.breadcrumb__input {
  @extend %title-md-w-semi-bold;
  display: inline-flex;
  width: 100%;
  max-width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  color: currentColor;
  background-color: transparent;
  outline: none;
  resize: none;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  padding: 0;
  padding-bottom: rem(6px - 1px);
  &:focus {
    border-bottom-color: $black-0;
  }
}
