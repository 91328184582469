.files-upload-modal__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: calc(var(--modal-padding) * -1);
  margin-bottom: calc(var(--modal-padding) * -1);
  margin-left: calc(var(--modal-padding) * -1);
  padding-top: rem(40px);
  padding-right: rem(55px);
  padding-bottom: rem(52px);
  padding-left: rem(55px);
}
