@charset "UTF-8";

.chats-bar {
  display: flex;
  flex-direction: column;
  border-right: 1px solid $gray-2;
  margin-right: -1px;
}

@import "__header/chats-bar__header";
@import "__body/chats-bar__body";
