.chat-room__footer {
  flex-shrink: 0;
  padding-bottom: rem(47px);
  position: relative;

  &#{&}--is-focused {
    z-index: $default-z * 2;
  }
  @include between($widescreen, $ultrahd) {
    padding-left: rem(20px);
    padding-right: rem(20px);
  }
}
