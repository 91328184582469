.dashboard-info-block__subtitle {
  font-weight: 500;
  font-size: rem(14px);
  line-height: 1.4;
  letter-spacing: -0.01em;
  color: $black-0;
  text-align: center;
  margin-top: rem(16px);
  &#{&}--is-title-gray {
    color: $gray-3;
  }
  &#{&}--is-title-red {
    color: $red-0;
  }
}
