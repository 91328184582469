.node-panel__body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  padding: 0 var(--node-panel-body-px);
  position: relative;
}
