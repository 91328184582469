.switch__slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $gray-3;
  transition: background-color 100ms, transform 200ms;
  border-radius: 16px;
  &::before {
    border-radius: 9999px;
    position: absolute;
    content: "";
    height: rem(24px);
    width: rem(24px);
    left: 4px;
    bottom: 4px;
    background-color: $white-0;
    transition: background-color 100ms, transform 200ms;
  }
}
