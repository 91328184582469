.dashboard-info-block__title {
  font-weight: 400;
  font-size: rem(32px);
  line-height: 1.2;
  letter-spacing: -0.01em;
  color: $gray-5;
  text-align: center;
  &#{&}--is-title-green {
    color: #5ca96a;
  }
  &#{&}--is-title-red {
    color: $red-0;
  }
  &#{&}--is-title-gray {
    color: $gray-3;
  }
}
