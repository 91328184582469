.copy-link {
  font-weight: 600;
  font-size: rem(13px);
  line-height: 1.85;
  letter-spacing: 0.04em;
  color: $gray-4;
  cursor: pointer;
  user-select: none;

  &#{&}--is-copied {
    color: $purple-3;
  }
}
