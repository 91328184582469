.flow-testing-widget__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: rem(84px);
  padding: rem(18px) rem(25px);
  background-color: $gray-1;
}
