.flow-testing-widget__send-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: $purple-2;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  background-color: transparent;
  cursor: pointer;
}
