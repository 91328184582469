.table__column {
  margin-left: 0;
  margin-right: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: $black-0;
  &:first-of-type {
    margin-left: 0;
    margin-right: 0;
  }
}
