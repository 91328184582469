.settings-menu__nav {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  margin: 0 rem(-26px);
  padding: 0 rem(26px);
  overflow-y: overlay;
  @include scrollbar;
}
