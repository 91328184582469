.operator-panel {
  padding: 0 rem(16px);
  background-color: $gray-0;
  display: flex;
  flex-direction: column;
}

@import "__header/operator-panel__header";
@import "__title/operator-panel__title";
@import "__note/operator-panel__note";
@import "__body/operator-panel__body";
@import "__inputs/operator-panel__inputs";
@import "__footer/operator-panel__footer";
