.language-selector__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  border: none;
  border-radius: em(8px, 12px);
  box-shadow: none;
  font-weight: 600;
  font-size: rem(12px);
  line-height: 1.4;
  color: $purple-3;
  background-color: $gray-1;
  padding-top: em(4px, 12px);
  padding-right: em(8px, 12px);
  padding-bottom: em(4px, 12px);
  padding-left: em(8px, 12px);
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  text-transform: capitalize;
  cursor: pointer;
}
