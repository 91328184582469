.error__title {
  font-weight: 700;
  font-size: rem(28px);
  line-height: 1.4;
  letter-spacing: -0.01em;
  color: $gray-5;
  margin-top: rem(20px);
  margin-bottom: rem(36px);
  & > span {
    color: $purple-2;
  }
  &#{&}--is-500 {
    margin-bottom: rem(8px);
  }
}
