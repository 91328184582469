.message__text {
  @extend %body-text-md-w-regular;
  cursor: text;
  user-select: text;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  color: $gray-5;
  .emoji {
    display: inline-block;
    height: rem(14px);
    width: rem(14px);
    vertical-align: bottom;
  }
}
