.channel-avatar {
  $this: &;
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(40px);
  height: rem(40px);
  padding: rem(11px) rem(11px) rem(10px) rem(10px);
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  color: $white-0;
  &#{&}--is-instagram {
    background-color: $social-insta;
    &#{$this}--is-deleting {
      background-color: rgba($social-insta, 0.5);
    }
  }
  &#{&}--is-messenger {
    background-color: $social-f_vk;
    &#{$this}--is-deleting {
      background-color: rgba($social-f_vk, 0.5);
    }
  }
  &#{&}--is-telegram {
    background-color: $social-teleg;
    &#{$this}--is-deleting {
      background-color: rgba($social-teleg, 0.5);
    }
  }
  &#{&}--is-vk {
    background-color: $social-f_vk;
    &#{$this}--is-deleting {
      background-color: rgba($social-f_vk, 0.5);
    }
  }
  &#{&}--is-whatsapp {
    background-color: $social-whats;
    &#{$this}--is-deleting {
      background-color: rgba($social-whats, 0.5);
    }
  }
  &#{&}--is-sms {
    background-color: $social-sms;
    &#{$this}--is-deleting {
      background-color: rgba($social-sms, 0.5);
    }
  }
  &#{&}--is-email {
    background-color: $social-email;
    &#{$this}--is-deleting {
      background-color: rgba($social-email, 0.5);
    }
  }
  &#{&}--has-badge {
    position: relative;
    overflow: visible;
    background-color: $gray-3;
    &::after,
    &::before {
      content: "";
      position: absolute;
      top: calc(0px - (20px / 2));
      right: calc(0px - (20px / 2));
      width: rem(20px);
      height: rem(20px);
    }
    &::before {
      background-color: $white-0;
      box-shadow: $shadow-1;
      border-radius: 9999px;
    }
    &::after {
      mask-image: url("/assets/image/svg/arrow/arrows-rotate.svg");
      mask-size: auto;
      mask-position: center;
      mask-repeat: no-repeat;
      background-color: $gray-4;
    }
  }
}
