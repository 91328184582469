.level__item {
  --level-item-flex-shrink: 1;
  --level-item-flex-grow: 1;
  display: flex;
  align-items: center;
  flex-grow: var(--level-item-flex-grow);
  flex-shrink: var(--level-item-flex-shrink);
  flex-basis: 0;
  &#{&}--is-basis-auto {
    flex-basis: auto;
  }
  &#{&}--is-basis-0 {
    flex-basis: 0;
  }
  &#{&}--is-clipped {
    overflow: hidden;
  }
  @for $i from 0 through 5 {
    &#{&}--is-flex-shrink-#{$i} {
      --level-item-flex-shrink: #{$i};
    }
    &#{&}--is-flex-grow-#{$i} {
      --level-item-flex-grow: #{$i};
    }
  }
}
