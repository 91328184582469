.ai-bot-creation {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  padding-top: rem(31px);
  padding-right: rem(40px);
  padding-left: rem(40px);
  width: 100%;
  .level {
    .level__left {
      overflow-x: scroll;
      @include scrollbar;
    }
  }
}

@import "__container/ai-bot-creation__container";
@import "__title/ai-bot-creation__title";
@import "__header/ai-bot-creation__header";
@import "__body/ai-bot-creation__body";
@import "__badges/ai-bot-creation__badges";
@import "__badge/ai-bot-creation__badge";
@import "__required/ai-bot-creation__required";
