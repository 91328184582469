.channels-dropdown__item {
  @at-root {
    a#{&},
    button#{&},
    label#{&} {
      @extend %body-text-md-w-semi-bold;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $black-0;
      border-radius: 4px;
      padding: rem(10px) rem(20px);
      background-color: $white-0;
      user-select: none;
      cursor: pointer;
      transition: background-color 100ms;
      &:hover,
      &#{&}--is-selected {
        background-color: $gray-0;
      }
      &:not(:last-child) {
        margin-bottom: rem(4px);
      }
    }
  }
  &#{&}--is-selected {
    background-color: $gray-0;
  }
}
