@charset "UTF-8";

.bots-dropdown {
  width: 100%;
  position: relative;
}

@import "__button/bots-dropdown__button";
@import "__item-help/bots-dropdown__item-help";
@import "__item/bots-dropdown__item";
@import "__body/bots-dropdown__body";
@import "__footer/bots-dropdown__footer";
@import "__content/bots-dropdown__content";
@import "__menu/bots-dropdown__menu";
