.images-lightbox__button {
  width: 40px;
  height: 35px;
  cursor: pointer;
  border: none;
  opacity: 0.7;
  vertical-align: middle;
  mask: url("../../../../image/svg/download-20x20.svg") no-repeat center;
  background-color: $white-0;
  &:hover {
    opacity: 1;
  }
}
