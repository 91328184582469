@charset "UTF-8";

.agenda {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  overflow: hidden;
}

@import "__title/agenda__title";
@import "__body/agenda__body";
