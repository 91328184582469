@charset "UTF-8";

.chat-room-input {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background-color: $white-0;
  border: 1px solid $purple-2;
  border-radius: 8px;
  padding: rem(30px - 1px) rem(20px - 1px) rem(30px - 1px) rem(20px - 1px);
}

@import "__button/chat-room-input__button";
@import "__left/chat-room-input__left";
@import "__right/chat-room-input__right";
