.chat-room__content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  margin: 0 auto;
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: 840px;
  &#{&}--is-centered {
    justify-content: center;
  }
}
