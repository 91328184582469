@charset "UTF-8";

.custom-field {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: rem(8px);
  align-items: center;
  justify-content: space-between;
  text-align: left;
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: $gray-2;
  border-radius: 6px;
  box-shadow: none;
  font-weight: 600;
  font-size: rem(12px);
  line-height: 1.4;
  color: $gray-5;
  background-color: $gray-0;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
  height: rem(48px);
  padding-top: rem(7px - 1px);
  padding-right: rem(6.5px - 1px);
  padding-bottom: rem(7px - 1px);
  padding-left: rem(17px - 1px);
  span:not([class]) {
    @extend %collapsable-text;
  }
  &#{&}--is-dragging {
    opacity: 0;
  }
}

@import "__type/custom-field__type";
