@charset "UTF-8";

.calendar-event-content {
  display: block;
}

@import "__close-button/calendar-event-content__close-button";
@import "__action-button/calendar-event-content__action-button";
@import "__actions/calendar-event-content__actions";
@import "__tooltip/calendar-event-content__tooltip";
@import "__datepicker-wrapper/calendar-event-content__datepicker-wrapper";
@import "__date/calendar-event-content__date";
@import "__input/calendar-event-content__input";
@import "__title/calendar-event-content__title";
@import "__textfield/calendar-event-content__textfield";
@import "__description/calendar-event-content__description";
@import "__body/calendar-event-content__body";
