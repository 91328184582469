.bot-card__status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  user-select: none;
  font-weight: 500;
  font-size: rem(11px);
  line-height: 1.5;
  color: $black-0;
  border-radius: 6px;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  padding: rem(4px) rem(8px);
  background-color: $white-0;
  &#{&}--is-deleting {
    color: $red-0;
    background-color: $red-1;
  }
}
