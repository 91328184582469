.bot-preset {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white-0;
  border-radius: rem(6px);
  padding-bottom: rem(40px);

  width: rem(328px);
  min-height: rem(327px);
  &:not(:last-child) {
    margin-right: rem(8px);
  }
  &#{&}--is-empty {
    justify-content: center;
    background-color: #ffffff80;
  }
}

@import "__title/bot-preset__title";
@import "__description/bot-preset__description";
