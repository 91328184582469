.chat-room-container {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: 1fr rem(380px);
  grid-auto-columns: auto;
  grid-auto-rows: auto;
  @include between($widescreen, $ultrahd) {
    grid-template-columns: 1fr rem(360px);
  }
}
