.bot-builder-exit {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .button {
    transition: background-color 100ms, border-radius 100ms;
  }
  &:hover {
    .button {
      background-color: $gray-1;
      border-color: $gray-1;
      border-radius: 9999px;
    }
  }
}
