@charset "UTF-8";

.node-scenario-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $purple-2;
  padding: rem(21px) rem(16px);
  height: rem(80px);
  border-radius: 6px;
  color: rgba($white-0, 1);
  position: relative;
  transition: color 100ms;
  overflow: hidden;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: repeat;
    background-size: auto;
    background-position: center;
    background-image: url("/assets/image/non-svg/white-dots.png");
    background-color: rgba($purple-3, 0);
    transition: background-color 100ms;
  }
  .puzzle-piece-shadow {
    transition: stroke-opacity 200ms;
  }
  &:hover {
    &::before {
      background-color: rgba($purple-3, 0.25);
    }
  }
  &:not(#{&}--is-empty):hover {
    .puzzle-piece-shadow {
      stroke-opacity: 0.25;
    }
  }
  &#{&}--is-empty {
    color: rgba($white-0, 0.5);
  }
  & > * {
    z-index: 1;
  }
}

@import "__text/node-scenario-block__text";
@import "__link/node-scenario-block__link";
