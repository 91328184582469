.main-menu__link {
  display: block;
  padding: em(10px);
  border-radius: 8px;
  color: $gray-5;
  &#{&}--is-disabled {
    pointer-events: none;
  }
  &:hover,
  &:not(.main-menu__link--is-with-label) {
    &#{&}--is-active {
      background-color: #f0f0f0;
    }
  }
  &#{&}--is-active {
    background-color: #f0f0f0;
    .main-menu__label {
      color: $purple-2;
    }
  }
  &#{&}--is-with-label {
    padding: em(10px) 0;
    width: 100%;
    border-radius: 0;
  }
  &#{&}--is-logo {
    color: $purple-3;
  }
}
