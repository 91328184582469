.bot-app-actions__title {
  @extend %title-md-w-semi-bold;
  color: $black-0;
  margin-bottom: rem(30px);
  text-align: center;
  &#{&}--is-fields {
    padding: rem(30px) 0;
    border-bottom: 1px solid $gray-2;
  }
}
