@charset "UTF-8";

.navbar {
  display: flex;
  align-items: stretch;
  height: var(--navbar-height);
  background-color: var(--navbar-bc);
  padding: rem(16px);
  transition: background-color 200ms;
  &:hover,
  &:focus-within {
    background-color: var(--navbar-active-bc);
  }
}

@import "__tooltip/navbar__tooltip";
@import "__item/navbar__item";
@import "__start/navbar__start";
@import "__center/navbar__center";
@import "__end/navbar__end";
@import "__menu/navbar__menu";
