%card {
  --card-border-bottom-width: 1px;
  --card-border-bottom-style: solid;
  --card-border-bottom-color: #{$gray-2};
  --card-py: #{rem(20px)};
  --card-px: #{rem(20px)};
  --card-h: #{rem(80px)};
  --card-background-color: #{$white-0};
  --card-color: #{$gray-3};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--card-h);
  padding: var(--card-py) var(--card-px)
    calc(var(--card-py) - var(--card-border-bottom-width));
  border-bottom-width: var(--card-border-bottom-width);
  border-bottom-style: var(--card-border-bottom-style);
  border-bottom-color: var(--card-border-bottom-color);
  background-color: var(--card-background-color);
  color: var(--card-color);
  user-select: none;
  cursor: pointer;
  transition: background-color 100ms;
}

%card-left-side {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  overflow: hidden;
}

%card-right-side {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  overflow: hidden;
}

%card-item {
  --card-item-flex-shrink: 1;
  --card-item-flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: var(--card-item-flex-grow);
  flex-shrink: var(--card-item-flex-shrink);
  flex-basis: 0;
  overflow: hidden;
}
