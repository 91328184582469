@charset "UTF-8";

.node-panel {
  --node-panel-body-px: #{rem(16px)};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  width: rem(442px);
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: $default-z;
  background-color: $white-0;
  box-shadow: 0px 2px 2px rgba(2, 3, 3, 0.03), 0px 3px 1px rgba(2, 3, 3, 0.02),
    0px 1px 5px rgba(2, 3, 3, 0.04);
  overflow: hidden;
}

@import "__title/node-panel__title";
@import "__header/node-panel__header";
@import "__body/node-panel__body";
