.node-tooltip {
  &#{&}--is-light {
    @extend %tooltip-light-theme;
    --tooltip-content-py: 7.5px;
    --tooltip-content-px: 13px;
    --tooltip-border-radius: 8px;
  }
  &#{&}--is-empty {
    @extend %tooltip-empty-theme;
  }
}
