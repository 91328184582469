.edge-foreignobject {
  $this: &;
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms;
  @at-root {
    .react-flow__edge.selected,
    .react-flow__edge:hover,
    .react-flow__edge:focus,
    .react-flow__edge:focus-visible {
      #{$this} {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
