.bot-type__badge {
  border-radius: 8px;
  background: $purple-3;
  padding: rem(4px) rem(8px);
  color: rgba(255, 255, 255, 0.7);
  font-size: rem(11px);
  font-weight: 400;
  line-height: 1.5;
  position: absolute;
  right: rem(10px);
  &#{&}--is-not-ai {
    background-color: #8f93f4;
    color: rgba(255, 255, 255, 0.7);
  }
}
