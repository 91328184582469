.ai-bot-creation__container {
  --ai-bot--px: #{rem(40px)};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  margin: 0 auto;
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: calc(800px + (var(--ai-bot--px) * 2));
}
