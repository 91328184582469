.calendar-event-participant-dropdown__input {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: $gray-2;
  border-radius: em(8px, 13px);
  font-weight: 600;
  font-size: rem(13px);
  line-height: 1.4;
  letter-spacing: -0.01em;
  color: $gray-5;
  background-color: $white-0;
  height: em(48px, 13px);
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
  max-width: 100%;
  padding-top: em(15px - 1px, 13px);
  padding-left: em(12px - 1px, 13px);
  padding-bottom: em(15px - 1px, 13px);
  padding-right: em(12px - 1px, 13px);
  transition: background-color 100ms;
  &::placeholder {
    color: $gray-5;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &:not(#{&}--has-errors) {
    &:hover,
    &:focus {
      border-color: $gray-4;
    }
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &#{&}--has-errors {
    border-color: $red-0;
  }
}
