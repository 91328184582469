%tooltip-light-theme {
  --tooltip-content-py: 10px;
  --tooltip-content-px: 16px;
  --tooltip-border-radius: 4px;
  --tooltip-font-weight: 400;
  --tooltip-font-size: 11px;
  --tooltip-line-height: 1.5;
  --tooltip-color: #{$black-0};
  font-weight: var(--tooltip-font-weight);
  font-size: var(--tooltip-font-size);
  line-height: var(--tooltip-line-height);
  color: var(--tooltip-color);
  border-radius: var(--tooltip-border-radius);
  background-color: $white-0;
  box-shadow: $shadow-2;
  .tippy-content {
    padding: var(--tooltip-content-py) var(--tooltip-content-px);
  }
  .tippy-arrow {
    display: inline-flex;
    align-items: center;
    width: 13px;
    height: 13px;
  }
  &[data-placement^="top"] > .tippy-arrow::before {
    border-top-color: $white-0;
    bottom: -4px;
    border-width: 5px 5px 0;
  }
  &[data-placement^="bottom"] > .tippy-arrow::before {
    border-bottom-color: $white-0;
    top: -4px;
    border-width: 0 5px 5px;
  }
  &[data-placement^="left"] > .tippy-arrow::before {
    border-left-color: $white-0;
    right: -4px;
    border-width: 5px 0 5px 5px;
  }
  &[data-placement^="right"] > .tippy-arrow::before {
    border-right-color: $white-0;
    left: -4px;
    border-width: 5px 5px 5px 0;
  }
}

%tooltip-dark-theme {
  --tooltip-content-py: 10px;
  --tooltip-content-px: 16px;
  --tooltip-border-radius: 4px;
  --tooltip-font-weight: 400;
  --tooltip-font-size: 11px;
  --tooltip-line-height: 1.5;
  --tooltip-color: #{$white-0};
  font-weight: var(--tooltip-font-weight);
  font-size: var(--tooltip-font-size);
  line-height: var(--tooltip-line-height);
  color: var(--tooltip-color);
  border-radius: var(--tooltip-border-radius);
  background-color: $black-0;
  box-shadow: none;
  .tippy-content {
    padding: var(--tooltip-content-py) var(--tooltip-content-px);
  }
  .tippy-arrow {
    display: inline-flex;
    align-items: center;
    width: 13px;
    height: 13px;
  }
  &[data-placement^="top"] > .tippy-arrow::before {
    border-top-color: $black-0;
    bottom: -4px;
    border-width: 5px 5px 0;
  }
  &[data-placement^="bottom"] > .tippy-arrow::before {
    border-bottom-color: $black-0;
    top: -4px;
    border-width: 0 5px 5px;
  }
  &[data-placement^="left"] > .tippy-arrow::before {
    border-left-color: $black-0;
    right: -4px;
    border-width: 5px 0 5px 5px;
  }
  &[data-placement^="right"] > .tippy-arrow::before {
    border-right-color: $black-0;
    left: -4px;
    border-width: 5px 5px 5px 0;
  }
}

%tooltip-empty-theme {
  --tooltip-font-weight: 600;
  --tooltip-font-size: 13px;
  --tooltip-line-height: 1.5;
  --tooltip-color: #{$gray-4};
  font-weight: var(--tooltip-font-weight);
  font-size: var(--tooltip-font-size);
  line-height: var(--tooltip-line-height);
  color: var(--tooltip-color);
  background-color: transparent;
  box-shadow: none;
  .tippy-content {
    padding: 0;
  }
  .tippy-arrow {
    display: none;
  }
}
