.ai-setup-block__textarea {
  border-radius: 8px;
  border: 1px solid $gray-2;
  background: $white-0;
  font-weight: 400;
  font-size: 13px;
  color: $gray-5;
  resize: none;
  padding: rem(16px);
  min-height: rem(100px);
  width: 100%;
  &#{&}--is-4 {
    min-height: rem(400px);
  }
  &#{&}--is-3 {
    min-height: rem(300px);
  }
  &#{&}--is-2 {
    min-height: rem(200px);
  }
  &::placeholder {
    color: $gray-4;
  }
  @include scrollbar;
}
