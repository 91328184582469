.calendar-event-content__date {
  $this: &;
  height: em(48px, 13px);
  padding-top: em(calc((48px - 21px) / 2) - 1px, 13px);
  padding-right: em(12px - 1px, 13px);
  padding-bottom: em(calc((48px - 21px) / 2) - 1px, 13px);
  padding-left: em(12px - 1px, 13px);
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: em(8px, 13px);
  background-color: transparent;
  font-weight: 600;
  font-size: rem(13px);
  line-height: 1.4;
  letter-spacing: -0.01em;
  cursor: default;
  text-transform: capitalize;
  .icon {
    width: em(21px, 13px);
    height: em(21px, 13px);
    color: $gray-3;
  }
  .icon {
    &:first-child:last-child {
      margin-left: em(calc((21px - 16px) / 2) * -1, 13px);
      margin-right: em(calc((21px - 16px) / 2) * -1, 13px);
    }
    &:first-child:not(:last-child) {
      margin-left: em(calc((21px - 16px) / 2) * -1, 13px);
      margin-right: em(10px - calc((21px - 16px) / 2), 13px);
    }
    &:last-child:not(:first-child) {
      margin-left: em(10px - calc((21px - 16px) / 2), 13px);
      margin-right: em(calc((21px - 16px) / 2) * -1, 13px);
    }
  }
  &#{&}--has-px-0 {
    padding-right: 0;
    padding-left: 0;
  }
  &#{&}--is-clickable {
    cursor: pointer;
    background-color: $gray-0;
    border-color: $gray-0;
    &:hover {
      background-color: $gray-1;
    }
    &:focus,
    &:active,
    &#{$this}--is-active {
      background-color: $white-0;
      border-color: $gray-3;
    }
    &#{$this}--has-errors {
      border-color: $red-0;
    }
  }
}
