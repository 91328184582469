@charset "UTF-8";

.breadcrumb {
  user-select: none;
  white-space: nowrap;
}

@import "__input/breadcrumb__input";
@import "__link/breadcrumb__link";
@import "__item/breadcrumb__item";
@import "__list/breadcrumb__list";
