.flow-testing-widget__body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  padding: rem(21px) rem(25px) rem(25px) rem(25px);
  background-color: $gray-0;
  .messages {
    margin-left: rem(-25px);
    margin-right: rem(-25px);
    padding-left: rem(25px);
    padding-right: rem(25px);
  }
}
