.client-card__tooltip {
  & > div {
    & > div {
      display: flex;
      flex-direction: column;
      & > div {
        display: flex;
        flex-direction: row;
        & > div {
          color: $white-0;
        }
      }
    }
  }
}
