.channel-card__bot {
  $this: &;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  font-size: rem(12px);
  line-height: 1.4;
  color: $gray-4;
  padding-top: rem(11.5px);
  padding-right: rem(20px);
  padding-bottom: rem(11.5px);
  padding-left: rem(20px);
  overflow: hidden;
  b {
    @extend %collapsable-text;
    font-weight: inherit;
    color: $gray-5;
  }
  &#{&}--is-connected {
    color: $purple-2;
  }
}
