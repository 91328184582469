@charset "UTF-8";

.flows {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  position: relative;
  overflow: hidden;
}

@import "__button/flows__button";
@import "__list/flows__list";
@import "__body/flows__body";
