.client-card__item {
  @extend %card-item;
  max-width: 100%;
  &#{&}--is-basis-auto {
    flex-basis: auto;
  }
  &#{&}--is-basis-0 {
    flex-basis: 0px;
  }
  @for $i from 0 through 5 {
    &#{&}--is-flex-shrink-#{$i} {
      --card-item-flex-shrink: #{$i};
    }
    &#{&}--is-flex-grow-#{$i} {
      --card-item-flex-grow: #{$i};
    }
  }
}
