.snippets-bar {
  display: grid;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  grid-template-columns: rem(291px) 1fr;
  gap: rem(80px);
  &:not(.snippets-bar--is-absolute) {
    @include between($widescreen, $ultrahd) {
      gap: rem(26px);
      padding-right: rem(54px);
    }
  }
  &#{&}--is-absolute {
    position: absolute;
    left: 0;
    right: 0;
    bottom: rem(0px + 16px);
    background-color: $white-0;
    width: 100%;
    height: 55%;
    border-radius: rem(8px);
    gap: 0;
    grid-template-columns: rem(291px) 1fr;
    z-index: $default-z * 2;
    box-shadow: $shadow-2;
    margin: 0 auto;
    @include between($widescreen, $ultrahd) {
      max-width: calc(100% - #{rem(40px)});
    }
  }
}
