.toast-content__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  user-select: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: em(6px, 12px);
  background-color: $gray-5;
  box-shadow: none;
  font-weight: 600;
  font-size: rem(12px);
  line-height: em(17px, 12px);
  color: $white-0;
  height: em(36px, 12px);
  padding-top: em(calc((36px - 18px) / 2) - 1px, 12px);
  padding-right: em(10px - 1px, 12px);
  padding-bottom: em(calc((36px - 18px) / 2) - 1px, 12px);
  padding-left: em(10px - 1px, 12px);
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 100ms;
  span:not([class]) {
    @extend %collapsable-text;
  }
  .icon {
    width: em(18px, 12px);
    height: em(18px, 12px);
    &:first-child:last-child {
      margin-left: em(((18px - 14px) * 0.5) * -1, 12px);
      margin-right: em(((18px - 14px) * 0.5) * -1, 12px);
    }
    &:first-child:not(:last-child) {
      margin-left: em(((18px - 14px) * 0.5) * -1, 12px);
      margin-right: em(8px - ((18px - 14px) * 0.5), 12px);
    }
    &:last-child:not(:first-child) {
      margin-left: em(8px - ((18px - 14px) * 0.5), 12px);
      margin-right: em(calc((21px - 14px) / 2) * -1, 12px);
    }
  }

  &#{&}--is-disabled,
  &:disabled {
    cursor: not-allowed;
  }
  &#{&}--no-interactivity {
    cursor: default;
    pointer-events: none;
  }
  &:not(:disabled):not(#{&}--is-disabled):not(#{&}--no-interactivity) {
    &:hover {
      background-color: #434343;
    }
  }
}
