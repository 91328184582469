.calendar-event-participant-dropdown__control {
  box-sizing: border-box;
  clear: both;
  font-size: rem(13px);
  position: relative;
  text-align: inherit;
  .icon {
    height: em(48px, 13px);
    position: absolute;
    top: 0;
    width: em(36, 13px);
    z-index: 1;
    left: 0;
  }
  .calendar-event-participant-dropdown__input {
    z-index: 0;
    padding-left: em(34px, 13px);
  }
}
