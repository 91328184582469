.client-panel__avatar-wrapper {
  display: block;
  width: rem(40px);
  height: rem(40px);
  margin: 0 auto;
  position: relative;
  overflow: visible;
  .icon {
    position: absolute;
    top: calc(0px - (22px / 2));
    left: calc(0px - (22px / 2));
    width: rem(22px);
    height: rem(22px);
  }
}
