.pagination {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: rem(-4px);
  margin-right: rem(-4px);
}

@import "__item/pagination__item";
@import "__link/pagination__link";
@import "__next/pagination__next";
@import "__previous/pagination__previous";
