@charset "UTF-8";

.radio {
  $indicator: ".radio__indicator";
  // display: inline-flex;
  // align-items: center;
  // justify-content: center;
  position: relative;
  cursor: pointer;
  input {
    display: none;
    &:checked ~ #{$indicator} {
      border-color: $gray-5;
      &::after {
        display: block;
      }
    }
  }
}

@import "__indicator/radio__indicator";
