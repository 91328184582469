.bots-dropdown__item {
  $this: &;
  @at-root {
    a#{&} {
      @extend %body-text-md-w-semi-bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $gray-5;
      padding: rem(13px) rem(16px);
      background-color: $white-0;
      border-radius: 6px;
      .icon {
        color: $gray-3;
      }
      &:not(:last-child) {
        margin-bottom: rem(10px);
      }
      &:hover,
      &#{$this}--is-connected {
        color: $black-0;
        background-color: $gray-1;
        .bots-dropdown__item-help {
          opacity: 1;
        }
      }
      &#{$this}--is-connected {
        cursor: default;
        pointer-events: none;
        .icon {
          color: $purple-3;
        }
      }
    }
    button#{&} {
      @extend %body-text-md-w-semi-bold;
      display: flex;
      align-items: center;
      justify-content: center;
      height: rem(48px);
      color: $gray-5;
      background-color: $white-0;
      border-radius: 6px;
      border-width: 1px;
      border-style: solid;
      border-color: $gray-2;
      padding: rem(15px - 1px);
      span:not([class]) {
        @extend %collapsable-text;
      }
      &:hover {
        border-color: $red-0;
        .icon {
          color: $red-0;
        }
      }
    }
  }
}
