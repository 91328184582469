@charset "UTF-8";

.level {
  --level-gap: #{rem(16px)};
  $this: &;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  &#{&}--is-v-centered {
    align-items: center;
  }
  &#{&}--is-v-start {
    align-items: flex-start;
  }
  .level__left:not(:last-child) {
    margin-right: calc(var(--level-gap) / 2);
  }
  .level__center:not(:first-child:last-child) {
    margin-left: calc(var(--level-gap) / 2);
    margin-right: calc(var(--level-gap) / 2);
  }
  .level__right:not(:first-child) {
    margin-left: calc(var(--level-gap) / 2);
  }
  .level__group:not(:last-child),
  .level__item:not(:last-child) {
    margin-right: var(--level-gap);
  }
  &:not(:last-child) {
    margin-bottom: rem(24px);
  }
  @for $i from 0 through 20 {
    &#{$this}--has-gap-#{$i} {
      --level-gap: #{$i * 0.125rem};
    }
  }
}

@import "__item/level__item";
@import "__group/level__group";
@import "__left/level__left";
@import "__center/level__center";
@import "__right/level__right";
