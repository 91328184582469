.comparison-bar__text {
  font-weight: 500;
  font-size: rem(11px);
  line-height: 1.5;
  color: $gray-5;
  &#{&}--is-email {
    .icon {
      color: $social-email;
    }
  }
}
