@charset "UTF-8";

.source-handle {
  $this: &;
  $reactflow-handle: ".react-flow__handle";
  background-color: $gray-4;
  border: none;
  &#{$reactflow-handle}-top {
    min-width: 14px;
    min-height: 3px;
    width: rem(14px);
    height: rem(3px);
    border-radius: 2px 2px 0px 0px;
    top: -3px;
  }
  &#{$reactflow-handle}-right {
    min-width: 3px;
    min-height: 14px;
    width: rem(3px);
    height: rem(14px);
    border-radius: 0px 2px 2px 0px;
    right: -3px;
  }
  &#{$reactflow-handle}-bottom {
    min-width: 14px;
    min-height: 3px;
    width: rem(14px);
    height: rem(3px);
    border-radius: 0px 0px 2px 2px;
    bottom: -3px;
  }
  &#{$reactflow-handle}-left {
    min-width: 3px;
    min-height: 14px;
    width: rem(3px);
    height: rem(14px);
    border-radius: 2px 0px 0px 2px;
    left: -3px;
  }
  &#{&}--has-button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 44px;
    min-height: 44px;
    width: rem(44px);
    height: rem(44px);
    background-color: transparent;
    border: none;
    border-radius: 9999px;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      background-color: rgba(148, 123, 253, 0.1);
      border-radius: 9999px;
      z-index: -1;
      pointer-events: none;
      transition: all 200ms;
    }
    &::after {
      content: "";
      position: absolute;
      width: rem(34px);
      height: rem(34px);
      background-color: rgba(142, 142, 142, 0.2);
      border-radius: 9999px;
      z-index: -1;
      pointer-events: none;
      transition: all 200ms;
    }
    &#{$reactflow-handle}-top {
      top: calc(-44px / 2);
    }
    &#{$reactflow-handle}-right {
      right: calc(-44px / 2);
    }
    &#{$reactflow-handle}-bottom {
      bottom: calc(-44px / 2);
    }
    &#{$reactflow-handle}-left {
      left: calc(-44px / 2);
    }
    &#{$this}--is-active {
      .source-handle__button {
        &::after {
          background-color: inherit;
        }
        .icon {
          transform: rotate(45deg);
        }
      }
    }
    &:hover:not(#{$this}--is-active) {
      &::before {
        width: 100%;
        height: 100%;
      }
      &::after {
        background-color: rgba(148, 123, 253, 0.2);
      }
      .source-handle__button {
        background-color: $purple-3;
        &::after {
          background-color: inherit;
        }
      }
      & ~ .node--is-start .node__content {
        background-image: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0.1) 0%,
          rgba(255, 255, 255, 0) 39.79%,
          rgba(255, 255, 255, 0) 39.79%
        );
        box-shadow: 0px 1px 1px rgba(2, 3, 3, 0.03),
          0px 2px 1px rgba(2, 3, 3, 0.02), 0px 1px 3px rgba(2, 3, 3, 0.04);
      }
    }
  }
}

@import "__button/source-handle__button";
