.level__center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  &#{&}--is-clipped {
    overflow: hidden;
  }
  .level__group,
  .level__item {
    justify-content: center;
  }
}
