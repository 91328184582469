.settings-menu__link {
  @extend %body-text-lg-w-semi-bold;
  display: block;
  padding: em(8px);
  border-radius: 8px;
  color: $gray-5;
  &:hover,
  &#{&}--is-active {
    color: $black-0;
    background-color: $gray-1;
  }
}
