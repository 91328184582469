@charset "UTF-8";

.message-gallery {
  width: rem(368px);
  height: auto;
  padding: rem(2px);
  &:not(:last-child) {
    margin-bottom: rem(8px);
  }
}

@import "__image/message-gallery__image";
@import "__controls/message-gallery__controls";
