.template-item__favorite-icon {
  cursor: pointer;
  & > svg {
    & > path {
      color: transparent;
      stroke: #999999;
    }

    &:hover {
      & > path {
        color: #999999 !important;
      }
    }
  }
  &#{&}--is-hidden {
    display: none;
  }
  &#{&}--is-active {
    & > svg {
      & > path {
        color: #b4a7eb;
        stroke: #b4a7eb !important;
      }
      &:hover {
        & > path {
          color: #b4a7eb !important;
        }
      }
    }
  }
}
