@charset "UTF-8";

.chats {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  background-color: $white-0;
}

@import "__title/chats__title";
