.node-settings__textfield {
  @extend %body-text-sm-w-regular;
  width: 100%;
  height: rem(153px);
  padding: rem(14px) rem(17px) rem(36px) rem(14px);
  border: 1px solid $gray-2;
  border-radius: 8px;
  background-color: $gray-0;
  color: $gray-5;
  &#{&}--is-error {
    border-color: $red-0;
  }
  textarea {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    resize: none;
    background-color: transparent;
    &::placeholder {
      color: $gray-4;
    }
  }
}
