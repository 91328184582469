.sms-hints__limit {
  padding: rem(4px) rem(8px);
  color: $gray-4;
  font-size: rem(11px);
  font-weight: 500;
  line-height: 150%;
  &#{&}--is-not-valid {
    color: $red-0;
  }
}
