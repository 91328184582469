.chat-card__time-badge {
  display: block;
  font-weight: 300;
  font-size: rem(11px);
  line-height: 1.5;
  color: $gray-4;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: $gray-2;
  border-radius: 20px;
  user-select: none;
  margin-top: auto;
  padding-top: rem(2px - 1px);
  padding-right: rem(5px - 1px);
  padding-bottom: rem(2px - 1px);
  padding-left: rem(5px - 1px);
  overflow: hidden;
  .icon {
    height: rem(17px);
  }
}
