.upload-files__list {
  margin-right: calc(var(--upload-files-offset-x) * -1);
  margin-left: calc(var(--upload-files-offset-x) * -1);
  padding-right: var(--upload-files-offset-x);
  padding-left: var(--upload-files-offset-x);
  overflow-y: overlay;
  @include scrollbar;
  &:not(:last-child) {
    margin-bottom: rem(32px);
  }
}
