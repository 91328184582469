.source-handle__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  pointer-events: none;
  width: rem(24px);
  height: rem(24px);
  background-color: $gray-5;
  border-radius: 9999px;
  color: $white-0;
  position: relative;
  transition: background-color 200ms;
  &::after {
    content: "";
    position: absolute;
    left: calc(100% - 3px);
    right: auto;
    background-color: rgba(0, 0, 0, 0);
    mask-size: auto;
    mask-position: center;
    mask-repeat: no-repeat;
    width: rem(54px);
    height: rem(4px);
    mask-image: url("/assets/image/svg/line-dashed.svg");
    transition: background-color 100ms;
  }
  .icon {
    transition: transform 200ms;
  }
}
