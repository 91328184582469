@charset "UTF-8";

.flows-panel {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  width: var(--flows-panel-width);
  height: 100%;
  background-color: $white-0;
  box-shadow: $shadow-4;
  position: absolute;
  left: 0;
  top: 0;
  z-index: $default-z;
}

@import "__title/flows-panel__title";
@import "__header/flows-panel__header";
@import "__body/flows-panel__body";
