.template-panel {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: rem(380px);
  display: flex;
  flex-direction: column;
  background-color: $white-0;
  z-index: $default-z * 2;
  &--is-settings {
    background-color: $gray-0;
  }
  @include between($widescreen, $ultrahd) {
    width: rem(360px);
  }
}

@import "__header/template-panel__header";
@import "__title/template-panel__title";
@import "__close-button/template-panel__close-button";
@import "__body/template-panel__body";
@import "__textarea/template-panel__textarea";
@import "__footer/template-panel__footer";
