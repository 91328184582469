.calendar-event-content__input {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  appearance: none;
  border: none;
  outline: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $gray-2;
  font-weight: 600;
  font-size: rem(18px);
  line-height: 1.4;
  letter-spacing: -0.01em;
  color: $gray-5;
  background-color: transparent;
  height: em(29px, 18px);
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
  max-width: 100%;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: em(4px - 1px, 18px);
  padding-right: 0;
  transition: background-color 100ms;
  &::placeholder {
    color: $gray-3;
  }
  &#{&}--has-errors {
    border-bottom-color: $red-0;
  }
}
