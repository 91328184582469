.ai-setup-block__description {
  color: $gray-5;
  font-size: rem(13px);
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: -0.13px;
  white-space: normal;
  &:not(:last-child) {
    margin-bottom: rem(12px);
  }
  &:last-child {
    margin-top: rem(12px);
  }
}
