.message-gallery__image {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
  position: relative;
  margin: 2px;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  img {
    border-radius: 8px;
  }
  &:hover {
    .message-gallery__controls,
    &::before {
      opacity: 1;
    }
  }
  .message-gallery__controls,
  &::before {
    opacity: 0;
  }
  &::before {
    content: "";
    transition: opacity 0.2s;
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }
  img {
    border-radius: 8px;
  }
}
