@charset "UTF-8";

.settings {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  background-color: $white-0;
}

@import "__text/settings__text";
@import "__title/settings__title";
