.bot-type {
  padding: rem(5px);
  border-radius: rem(12px);
  width: 100%;
  &:not(:last-child) {
    margin-right: rem(40px);
  }
}

@import "__container/bot-type__container";
@import "__header/bot-type__header";
@import "__body/bot-type__body";
@import "__badge/bot-type__badge";
@import "__info/bot-type__info";
@import "__title/bot-type__title";
@import "__subtitle/bot-type__subtitle";
@import "__info-block/bot-type__info-block";
@import "__info-block-title/bot-type__info-block-title";
@import "__advantages/bot-type__advantages";
@import "__button/bot-type__button";
