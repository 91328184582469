.templates-dropdown__trigger {
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  &#{&}--is-hidden {
    display: none;
  }
  &#{&}--is-category {
    border-radius: 9999px;
    width: rem(32px);
    height: rem(32px);
    &:hover {
      background-color: white;
    }
  }
  &#{&}--menu-open {
    border-radius: 9999px;
    width: rem(32px);
    height: rem(32px);
    background-color: white;
  }
}
