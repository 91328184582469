.chart-title {
  font-weight: 600;
  font-size: rem(15px);
  line-height: 1.2;
  color: $gray-5;
  &#{&}--is-error {
    color: $red-0;
  }
  &#{&}--is-linechart {
    margin-left: rem(42px);
    margin-bottom: rem(-24px);
  }
  &#{&}--is-empty {
    color: $gray-3;
  }
  &#{&}--is-chats {
    margin-top: rem(24px);
    margin-bottom: rem(24px);
    margin-left: rem(32px);
  }
  &#{&}--is-heatmap {
    margin-left: rem(20px);
  }
}
