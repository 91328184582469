.node-tabs__link {
  --node-tabs-link-px: #{rem(16px)};
  @extend %body-text-lg-w-regular;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  padding: rem(14px) var(--node-tabs-link-px);
  color: $gray-5;
  transition: color 100ms, background-color 100ms;
  &:hover {
    color: $black-0;
  }
  @for $i from 0 through 30 {
    &#{&}--has-px-#{$i} {
      ----node-tabs-link-px: #{$i * 0.125rem};
    }
  }
}
