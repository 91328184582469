@charset "UTF-8";

.bots-tabs {
  --tabs-link-color: #{$gray-4};
  &#{&}--is-grow-1 {
    flex-grow: 1;
  }
}

@import "__link/bots-tabs__link";
