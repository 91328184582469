@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
    border-radius: 4px;

    &-track {
      background-color: transparent;
      border-radius: 4px;
    }

    &-thumb {
      background-color: $gray-2;
      border-radius: 4px;
    }
  }
}
