.select__option {
  $this: ".select__option";

  font-weight: 600;
  font-size: rem(12px);
  line-height: 1.4;
  color: $gray-5;
  padding: rem(15.5px) rem(20px);
  border-radius: 6px;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: rem(4px);
  }

  &:not(.select__option--is-go-back):active {
    background-color: $gray-1;
  }
  &#{$this}--is-focused,
  &#{$this}--is-selected {
    background-color: $gray-1;
  }
  &#{$this}--is-flow {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: $gray-0;
    .icon:last-child {
      margin-left: auto;
    }
  }
  &#{$this}--is-node {
    &:hover {
      background-color: $gray-1;
    }
  }
  &#{$this}--is-flow-title {
    text-align: center;
    pointer-events: none;
  }
  &#{$this}--is-go-back {
    display: flex;
    align-items: center;
    padding: rem(15.5px) rem(20px) rem(15.5px) 0;
    color: $gray-4;
    & > .icon-text {
      cursor: pointer;
    }
  }
  & > .icon-text {
    & > .icon--is-20x20:last-child {
      margin-left: auto;
    }
  }
}
