.info-placeholder__body {
  --p: #{rem(24px)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: var(--p);
  flex-grow: 1;
  flex-shrink: 0;
  border-radius: rem(16px);
  @for $i from 0 through 20 {
    &#{&}--has-p-#{$i} {
      --p: #{$i * 0.25rem};
    }
  }
  &#{&}--is-gray-0 {
    background-color: $gray-0;
  }
}
