.block {
  --block-mb: #{rem(16px)};
  --block-mt: 0;
  --block-px: 0;
  padding-left: var(--block-px);
  padding-right: var(--block-px);
  &:not(:last-child) {
    margin-bottom: var(--block-mb);
    margin-top: var(--block-mt);
  }
  @for $i from 0 through 30 {
    &#{&}--has-px-#{$i} {
      --block-px: #{$i * 0.125rem};
    }
  }
  @for $i from 0 through 30 {
    &#{&}--has-mb-#{$i} {
      --block-mb: #{$i * 0.125rem};
    }
  }
  @for $i from 0 through 30 {
    &#{&}--has-mt-#{$i} {
      --block-mt: #{$i * 0.125rem};
    }
  }
  &#{&}--has-mt-auto {
    margin-top: auto;
  }
  &#{&}--is-self-centered {
    align-self: center;
  }
}
