.select__option {
  $this: ".select__option";
  @extend %body-text-sm-w-medium;
  color: $gray-5;
  padding-top: 12px;
  padding-right: 15px;
  padding-left: 12px;
  &:last-child {
    padding-bottom: 12px;
  }
  &:not(:last-child) {
    padding-bottom: calc(12px - 1px);
    border-bottom: 1px solid $gray-2;
  }
  &:active {
    background-color: $gray-1;
  }
  &#{$this}--is-focused,
  &#{$this}--is-selected {
    background-color: $gray-1;
  }
}
