@charset "UTF-8";

.calendar-event-participant-card {
  @extend %card;
  --card-border-bottom-color: transparent;
  --card-py: #{rem(12px)};
  --card-px: #{rem(12px)};
  --card-h: #{rem(48px)};
  --card-background-color: #{$gray-0};
  --card-color: #{$flowkit-purple};
  flex-direction: row;
  border-radius: 8px;
  &#{&}--is-loading {
    cursor: default;
  }
  &:not(#{&}--is-loading) {
    &:hover {
      background-color: $gray-1;
      .icon:not([data-static]) {
        color: $gray-5;
      }
    }
  }
}

@import "__info/calendar-event-participant-card__info";
@import "__name/calendar-event-participant-card__name";
@import "__item/calendar-event-participant-card__item";
@import "__right/calendar-event-participant-card__right";
@import "__left/calendar-event-participant-card__left";
