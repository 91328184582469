@charset "UTF-8";

.flow {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($white-0, 0.25);
    z-index: $default-z;
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms;
  }
  &#{&}--is-loading {
    &::after {
      opacity: 1;
      visibility: visible;
    }
  }
}

@import "__addon/flow__addon";
@import "__addons/flow__addons";
