.flow-testing-widget__circle {
  width: rem(30px);
  height: rem(30px);
  border-radius: 9999px;
  background-color: $purple-1;
  color: $black-0;
  overflow: hidden;
  .icon {
    width: rem(16px);
    height: rem(16px);
    border-radius: 9999px;
    background-color: $white-0;
  }
}
