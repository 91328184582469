.action-dropdown__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  border-width: 0px;
  border-style: solid;
  border-color: transparent;
  border-radius: em(20px);
  box-shadow: none;
  font-size: rem(16px);
  color: $black-0;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  height: em(30px);
  padding-top: em(8px);
  padding-right: em(8px);
  padding-bottom: em(8px);
  padding-left: em(8px);
  background-color: transparent;
  cursor: pointer;
  transition: background-color 100ms;
  &:not(:disabled) {
    &:hover,
    &#{&}--is-active {
      background-color: var(--action-dropdown-button-active-bc);
    }
  }
}
