.checkbox__indicator {
  width: rem(14px);
  height: rem(14px);
  background-color: $white-0;
  border-width: 1px;
  border-style: solid;
  border-color: $gray-3;
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    border-color: $gray-5;
  }
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    background-image: url("/assets/image/svg/indicator.svg");
  }
}
