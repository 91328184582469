.node-button__index {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: rem(16px);
  height: rem(16px);
  padding-left: rem(5px);
  padding-right: rem(5px);
  border-radius: 20px;
  background-color: $gray-1;
  font-weight: 500;
  font-size: rem(11px);
  line-height: rem(16px);
  color: $gray-4;
  &:not(:last-child) {
    margin-right: rem(16px);
  }
}
