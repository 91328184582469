.clients {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding-top: rem(28px);
  padding-left: rem(48px);
}

@import "__title/clients__title";
@import "__tooltip-title/client-card__tooltip-title";
@import "__tooltip/client-card__tooltip";
@import "__header/clients__header";
@import "__actions/clients__actions";
@import "__body/clients__body";
@import "__footer/clients__footer";
