.flow__addon {
  --flow-addon-size: #{em(38px, 14px)};
  $this: &;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  user-select: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: em(6px, 14px);
  box-shadow: $shadow-3;
  font-weight: 500;
  font-size: rem(14px);
  line-height: 1.5;
  color: $gray-5;
  background-color: $white-0;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  cursor: pointer;
  height: var(--flow-addon-size);
  padding-top: em(((38px - 21px) * 0.5) - 1px, 14px);
  padding-right: em(14px - 1px, 14px);
  padding-bottom: em(((38px - 21px) * 0.5) - 1px, 14px);
  padding-left: em(14px - 1px, 14px);
  .icon {
    width: em(21px, 14px);
    height: em(21px, 14px);
    &:first-child:last-child {
      margin-left: em(calc((21px - 14px) / 2) * -1, 14px);
      margin-right: em(calc((21px - 14px) / 2) * -1, 14px);
    }
    &:first-child:not(:last-child) {
      margin-left: em(calc((21px - 14px) / 2) * -1, 14px);
      margin-right: em(12px - calc((21px - 14px) / 2), 14px);
    }
    &:last-child:not(:first-child) {
      margin-left: em(12px - calc((21px - 14px) / 2), 14px);
      margin-right: em(calc((21px - 14px) / 2) * -1, 14px);
    }
  }
  &#{&}--is-square {
    width: var(--flow-addon-size);
  }
  @at-root {
    button#{&} {
      &:hover {
        background-color: $gray-0;
      }
      &:active {
        background-color: $gray-2;
      }
    }
  }
}
