.bot-presets {
  display: flex;
  & > .bot-presets__loader {
    display: flex;
    & > div {
      &:not(:last-child) {
        margin-right: rem(8px);
      }
    }
  }
}
