.calendar-toolbar__button {
  $this: &;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: $gray-2;
  background-color: transparent;
  border-radius: em(6px, 12px);
  box-shadow: none;
  font-weight: 500;
  font-size: rem(12px);
  line-height: 1.4;
  color: $gray-5;
  height: em(36px, 12px);
  padding-top: em(calc((36px - 17px) / 2) - 1px, 12px);
  padding-right: em(12px - 1px, 12px);
  padding-bottom: em(calc((36px - 17px) / 2) - 1px, 12px);
  padding-left: em(12px - 1px, 12px);
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 100ms;
  .icon {
    width: em(17px, 12px);
    height: em(17px, 12px);
    transition: transform 200ms;
    &:first-child:last-child {
      margin-left: em(((17px - 11px) * 0.5) * -1, 12px);
      margin-right: em(((17px - 11px) * 0.5) * -1, 12px);
    }
    &:first-child:not(:last-child) {
      margin-left: em(((17px - 11px) * 0.5) * -1, 12px);
      margin-right: em(8px - ((17px - 11px) * 0.5), 12px);
    }
    &:last-child:not(:first-child) {
      margin-left: em(8px - ((17px - 11px) * 0.5), 12px);
      margin-right: em(((17px - 11px) * 0.5) * -1, 12px);
    }
  }
  &:not(:disabled):not(#{$this}--is-disabled) {
    &:hover,
    &:active,
    &#{$this}--is-active {
      border-color: $gray-5;
    }
    &#{$this}--is-active {
      background-color: $white-0;
      .icon {
        transform: rotate(-180deg);
      }
    }
  }
  &#{&}--is-disabled,
  &:disabled {
    cursor: not-allowed;
  }
}
