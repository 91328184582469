@charset "UTF-8";

.teams-settings {
  display: flex;
  flex-direction: column;
  padding: rem(31px) rem(82px) 0;
  @include between($widescreen, $ultrahd) {
    padding: rem(31px) rem(40px) 0;
  }
}

@import "__tab-panel/teams-settings__tab-panel";
@import "__header/teams-settings__header";
@import "__body/teams-settings__body";
