.attachment__size {
  font-weight: 400;
  font-size: rem(11px);
  line-height: 1.5;
  color: $gray-4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @at-root {
    .attachment--has-error & {
      color: $red-0;
    }
  }
}
