.flow-start-icon {
  position: absolute;
  right: calc(100% + 18.37px);
  left: auto;
  top: 0;
  bottom: 50%;
  top: auto;
  width: rem(103px);
  height: rem(84px);
  pointer-events: none;
}
