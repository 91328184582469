.chat-card__selector {
  $this: &;
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(30px);
  height: rem(30px);
  background-color: transparent;
  border-radius: 20px;
  position: relative;
  &:not(#{&}--is-enabled) {
    pointer-events: none;
  }
  &#{&}--is-enabled {
    .checkbox {
      display: contents;
    }
    svg,
    .checkbox {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      transition: opacity 200ms;
    }
    svg {
      visibility: visible;
      opacity: 1;
    }
    .checkbox {
      visibility: hidden;
      opacity: 0;
    }
    @at-root {
      .chat-card:hover &,
      &#{$this}--is-active {
        background-color: $white-0;
        cursor: pointer;
        svg {
          visibility: hidden;
          opacity: 0;
        }
        .checkbox {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
