.flow-testing-widget__input {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: $gray-4;
  border-radius: em(8px, 14px);
  font-weight: 400;
  font-size: rem(14px);
  line-height: 1.4;
  letter-spacing: -0.01em;
  color: $black-0;
  background-color: $white-0;
  height: em(48px, 14px);
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
  max-width: 100%;
  padding-top: em(14.5px - 1px, 14px);
  padding-left: em(16px - 1px, 14px);
  padding-bottom: em(14.5px - 1px, 14px);
  padding-right: em(16px - 1px, 14px);
  transition: background-color 100ms;
  &::placeholder {
    color: $gray-3;
  }
  &:disabled {
    cursor: not-allowed;
  }
}
