@charset "UTF-8";

.flow-tooltip {
  @extend %tooltip-dark-theme;
  b {
    font-weight: 600;
  }
  &#{&}--has-radius-6 {
    --tooltip-border-radius: 6px;
  }
  &#{&}--has-radius-12 {
    --tooltip-border-radius: 12px;
  }
}

@import "__badge/flow-tooltip__badge";
@import "__container/flow-tooltip__container";
