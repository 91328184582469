@charset "UTF-8";

.tabs {
  --tabs-border-bottom-width: 2px;
  --tabs-border-bottom-color: #{$gray-2};
  --tabs-link-color: #{$gray-5};
  --tabs-link-hover-border-bottom-color: #{$black-0};
  --tabs-link-hover-color: #{$black-0};
  --tabs-link-active-border-bottom-color: #{$black-0};
  --tabs-link-active-color: #{$black-0};
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: space-between;
  white-space: nowrap;
  flex-shrink: 0;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  overflow-x: overlay;
  @include scrollbar;
}

@import "__link/tabs__link";
@import "__item/tabs__item";
@import "__list/tabs__list";
